import React, { Component } from 'react';

export default class ImageCat extends Component {

  render() {
    return (
      <div className="ErrorImage">
<svg width="239" height="200" viewBox="0 0 239 200" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M88.352 27.532l-7.698-11.985a2.355 2.355 0 0 0-3.895-.108 83.606 83.606 0 0 0-9.095 16.465c-.484 1.165-.921 2.391-1.39 3.643 9.056.601 22.078-8.015 22.078-8.015z"
            fill="#FFB66B" />
          <path d="M72.767 29.13l4.386-8.623a.907.907 0 0 1 1.568-.077l3.649 5.697a.904.904 0 0 1-.577 1.39c-2.69.518-5.085 1.495-7.837 2.84a.903.903 0 0 1-1.189-1.227z"
                fill="#EC7F45" />
          <path d="M106.515 6.952c2.136 0 3.868-1.557 3.868-3.476 0-1.92-1.732-3.476-3.868-3.476-2.137 0-3.869 1.556-3.869 3.476s1.732 3.476 3.869 3.476z" fill="#528ED6" />
          <path
            d="M89.186 28.48c5.064-.6 10.183-.695 15.219-1.498 4.938-.789 12.136 4.255 17.116 3.633 4.017-.501 5.508-7.484 9.463-8.182 0-.31-.038-.62-.069-.928-1.213-10.99-10.551-20.522-26.017-19.767-12.327.59-27.535 14.497-28.004 26.937-.028.747.438.243.625.952 3.865.285 7.813-.684 11.667-1.147z"
            fill="#528ED6" />
          <path
            d="M217.316 109.369a4.019 4.019 0 0 1-.243.299.348.348 0 0 1-.598-.052 3.281 3.281 0 0 1-.348-1.996 11.392 11.392 0 0 1-1.807-4.445 8.476 8.476 0 0 1-.514-.695c-1.237-.737-2.558-1.366-3.823-2.02a.377.377 0 0 1-.198-.384.37.37 0 0 1 .049-.144 19.282 19.282 0 0 0-4.981-.47c-12.914.348-19.114 6.852-20.761 21.394-1.613 14.38 9.925 37.035 1.796 40.726-4.115 1.866-15.521-2.085-15.521-2.085l-9.23 12.346c7.646 3.249 36.144 10.681 44.912-1.533 5.56-7.751 5.029-14.251 2.242-25.214-1.523-5.981-5.766-13.225 3.993-19.415a16.306 16.306 0 0 0 3.392-2.868.344.344 0 0 1-.155-.356.341.341 0 0 1 .05-.127c.129-.195.265-.386.4-.577.695-1.147 1.043-2.478 1.439-3.757a.307.307 0 0 1 .108-.15 7.62 7.62 0 0 1 .507-1.8.347.347 0 0 1 .348-.219c.066-.261.142-.522.218-.789.077-.268.16-.539.24-.824 0-.097.025-.191.035-.288-.452-1.544-.99-3.049-1.55-4.557z"
            fill="#FFB570" />
          <path
            d="M204.759 98.712c-6.972-.267-11.872 2.948-15.275 7.018 10.26 1.161 19.73 12.276 22.82 19.958 11.726-7.456 7.586-26.416-7.545-26.976zm2.892 31.283c-6.649-7.115-17.631-11.721-23.376-10.445a9.42 9.42 0 0 0-.163 1.317 32.561 32.561 0 0 0 0 6.503c.348 2.878.768 5.711 1.164 7.748 7.452-1.846 18.468 1.39 21.012 2.642-.069-1.005-.813-4.29 1.363-7.765zm-.928 39.352c3.722-5.617 4.233-10.695 3.086-17.455-5.651-4.866-17.724-5.315-21.86-4.171.713 3.74 1.123 7.066.744 9.579 6.624.963 14.489 8.078 18.03 12.047zm-29.729 6.694c4.699.821 10.079 1.352 15.073.887a37.695 37.695 0 0 0-6.159-15.343c-2.203.876-4.288.449-7.6 0 .869 2.569-.369 11.822-1.314 14.456z"
            fill="#EC7F45" />
          <path d="M125.204 75.205c6.53 0 11.823-5.294 11.823-11.824s-5.293-11.825-11.823-11.825-11.823 5.294-11.823 11.825c0 6.53 5.293 11.824 11.823 11.824z" fill="#fff"
                stroke="#413B4E" strokeWidth="2.182" strokeMiterlimit="10" />
          <path
            d="M155.975 94.85a57.083 57.083 0 0 0 6.701-17.03 60.3 60.3 0 0 0 1.39-14.32 63.566 63.566 0 0 0-2.196-15.197 70.642 70.642 0 0 0-5.884-14.817c-3.983-7.501-12.383-18.144-20.463-26.069a3.021 3.021 0 0 0-2.578-.826 3.017 3.017 0 0 0-2.201 1.577c-3.798 7.3-7.107 15.175-7.107 15.175-11.642-1.13-23.219-.16-38.49 4.536-6.843 2.78-10.492 2.044-17.498 4.025A83.671 83.671 0 0 0 63.677 44a78.73 78.73 0 0 0-1.415 30.284c.415 2.822.978 5.62 1.686 8.384 1.265 4.94 5.018 11.626 7.785 15.8-22.789 42.753 2.884 79.492 2.884 79.492h85.553a10.187 10.187 0 0 0 9.689-7.004c4.456-13.59 10.465-44.945-13.884-76.105z"
            fill="#FFB570" />
          <path
            d="M161.862 48.285l-17.513 2.781a1.043 1.043 0 0 1-.98-1.679l12.609-15.909a70.563 70.563 0 0 1 5.884 14.807zm-91.915 8.718l-8.129-2.086c.369-3.675.995-7.32 1.874-10.907l7.388 11.408a1.043 1.043 0 0 1-1.133 1.585zm92.728 20.799L148.079 67.35a1.046 1.046 0 0 1-.407-1.103 1.041 1.041 0 0 1 .879-.78l15.514-1.986c.171 4.46-.375 9.27-1.39 14.32zm-92.764-.897l-5.964 5.763a81.563 81.563 0 0 1-1.685-8.383l7.034.834a1.042 1.042 0 0 1 .615 1.786zm58.996-54.462l4.459-7.473a1.036 1.036 0 0 1 .847-.507 1.046 1.046 0 0 1 .89.427l5.561 7.574a1.044 1.044 0 0 1-.424 1.59 1.042 1.042 0 0 1-.619.061 25.547 25.547 0 0 0-9.602-.104 1.043 1.043 0 0 1-1.112-1.568z"
            fill="#EC7F45" />
          <path opacity=".6" d="M125.204 82.157c9.906 0 17.936-8.031 17.936-17.938 0-9.907-8.03-17.939-17.936-17.939s-17.936 8.032-17.936 17.939 8.03 17.938 17.936 17.938z"
                fill="#FCEAC9" />
          <path d="M125.205 75.591c6.281 0 11.372-5.091 11.372-11.372s-5.091-11.373-11.372-11.373c-6.28 0-11.371 5.092-11.371 11.373 0 6.28 5.091 11.372 11.371 11.372z" fill="#fff"
                stroke="#303745" strokeWidth="2.909" strokeMiterlimit="10" />
          <path d="M123.659 75.73a8.394 8.394 0 0 0 8.393-8.394 8.394 8.394 0 1 0-8.393 8.394z" fill="#303745" />
          <path d="M128.514 63.968a2.232 2.232 0 1 0 0-4.463 2.232 2.232 0 0 0 0 4.463z" fill="#fff" />
          <path d="M84.932 80.363c6.28 0 11.371-5.092 11.371-11.373 0-6.28-5.09-11.372-11.371-11.372-6.28 0-11.371 5.091-11.371 11.372s5.09 11.373 11.37 11.373z" fill="#fff"
                stroke="#303745" strokeWidth="2.909" strokeMiterlimit="10" />
          <path d="M83.776 80.502a8.393 8.393 0 0 0 8.393-8.394 8.393 8.393 0 1 0-16.786 0 8.394 8.394 0 0 0 8.393 8.394z" fill="#303745" />
          <path d="M88.37 68.74a2.231 2.231 0 1 0 0-4.462 2.231 2.231 0 0 0 0 4.463z" fill="#fff" />
          <path d="M100.992 79.005l3.152 1.73 3.434-3.093m-3.433 3.093a21.026 21.026 0 0 0 1.195 6.674" stroke="#B55648" strokeWidth="2.909" strokeMiterlimit="10"
                strokeLinecap="round" />
          <path
            d="M104.773 88.403a12.789 12.789 0 0 1-4.57-.96.7.7 0 0 1-.45-.628.696.696 0 0 1 .94-.675c2.721 1.026 6.739 1.912 10.204-2.186a.696.696 0 1 1 1.063.9c-2.353 2.781-4.969 3.55-7.187 3.55z"
            fill="#B55648" />
          <path opacity=".4"
                d="M156.938 130.245c-.236 6.256-19.114 24.678-31.16 20.545l-.24-.069c.675-3.201 1.874-7.299 3.219-13.361-11.678 7.24-10.055-2.725-6.635 8.915 4.734 26.416-47.665 49.763-45.944-34.101.024-1.188-11.533 4.119-12.051 5.826-.587 1.915 7.85 50.941 7.502 53.033a53.254 53.254 0 0 0 3.461 6.927h48.287c-.386-5.561.591-10.462 5.056-13.142-2.929-4.577-3.76-7.344-3.409-10.997.459 1.255.862 2.416.17 2.489 11.997 4.518 31.501-14.23 32.509-25.721l-.765-.344z"
                fill="#EC7F45" />
          <path
            d="M88.03 27.028v5.739a1.043 1.043 0 0 0 1.779.736l9-8.985 1.916 8.798a1.04 1.04 0 0 0 1.8.472l9.679-10.83 2.189 7.817a1.047 1.047 0 0 0 1.295.72c.177-.052.337-.149.464-.282l7.482-7.883s-14.158-2.916-35.605 3.698z"
            fill="#EF8848" />
          <path d="M123.623 23.288s-36.818-4.13-49.198 5.68c-10.825 8.57-2.255 13.367 4.258 11.066 7.082-2.502 15.343-2.523 22.788-1.119 12.695 2.398 19.875-7.4 22.152-15.627z"
                fill="#D68BFF" opacity=".3" />
          <path
            d="M75.994 49.968a19.152 19.152 0 0 1 2.961-2.732c.554-.42 1.135-.802 1.738-1.147.631-.363 1.29-.675 1.97-.935a1.598 1.598 0 0 1 1.178 2.972c-.199.077-.411.115-.625.111-.592-.02-1.184.004-1.772.073-.618.078-1.23.194-1.832.348a18.352 18.352 0 0 0-3.618 1.31zm50.645-6.778a15.521 15.521 0 0 0-2.106-.253c-.694-.04-1.39-.04-2.085 0a14.82 14.82 0 0 0-2.019.236c-.645.115-1.281.275-1.905.476a1.59 1.59 0 0 1-2.054-1.034 1.594 1.594 0 0 1 1.123-2.007c.185-.058.38-.08.573-.066.777.04 1.549.136 2.312.285.741.139 1.472.326 2.189.56.702.225 1.39.49 2.061.795.66.29 1.299.627 1.911 1.008z"
            fill="#B55648" />
          <path
            d="M135.095 147.026a5.74 5.74 0 0 1-2.116-.407l-25.242-10.021a5.717 5.717 0 0 1-1.925-1.198 5.733 5.733 0 0 1-1.399-6.296 5.736 5.736 0 0 1 7.561-3.169l25.234 10.024a5.735 5.735 0 0 1 3.506 6.405 5.743 5.743 0 0 1-5.619 4.662z"
            fill="#FC5B4F" />
          <path d="M106.405 132.007a2.1 2.1 0 0 1-.771-.15l-11.194-4.456a2.086 2.086 0 1 1 1.546-3.875l11.191 4.456a2.088 2.088 0 0 1 .56 3.548 2.088 2.088 0 0 1-1.332.477z"
                fill="#fff" />
          <path
            d="M101.912 127.498l-.563 1.342c-.104.257-.834.195-1.623-.135l-8.317-3.824c-.907-.413-1.501-.959-1.39-1.258l.233-.556c.129-.299.931-.257 1.863.097l8.546 3.257c.82.348 1.359.824 1.251 1.077z"
            fill="#fff" />
          <path d="M124.766 150.665c-.191 5.613 1.109 10.138 3.994 14.153" stroke="#EF8848" strokeWidth="2.909" strokeMiterlimit="10" strokeLinecap="round" />
          <path
            d="M130.38 125.188c3.635.173 13.651 9.978 14.919 19.401-8.883 7.001-22.617 11.543-24.327-.858-.414-3.024 2.61-8.033 5.123-10.977.889-1.042-5.627-3.475-4.988-4.518 2.079-3.379 9.273-3.048 9.273-3.048z"
            fill="#FCEAC9" />
          <path
            d="M138.221 109.696c-.855 8.429-2.708 10.521-7.035 15.078-.785.827-8.34.101-10.078 3.476-.49.959 6.106 3.413 4.987 4.518-11.222 11.102-2.86 25.179 13.665 15.537 13.971-8.158 17.453-18.074 17.453-18.074m-35.574 13.319a6.437 6.437 0 0 0 3.913-1.352m-1.616 7c.695.08 3.127-.532 4.344-1.575"
            stroke="#EF8848" strokeWidth="2.909" strokeMiterlimit="10" strokeLinecap="round" />
          <path
            d="M237.715 179.166c-1.845-4.519-13.498-8.203-30.802-10.118-.028.049-.605.9-.664.987 24.06 2.597 29.701 7.57 30.5 9.527.42 1.043.198 2.086-.664 3.128-4.431 5.384-25.572 10.129-53.26 5.673 5.213-.247 9.895-.772 11.267-1.363 1.887-.809 2.106-1.72 1.96-2.339-.57-2.374-7.847-3.524-18.103-2.86-3.823.243-5.793 1.126-5.891 2.617-.072 1.14 1.043 2.238 2.579 3.034h-.281c-7.869-.177-16.275-1.025-18.948-5.961-1.922-3.559-1.96-8.654-2.002-14.049-.066-8.776-.135-17.851-8.299-21.751a.514.514 0 0 0-.695.247.53.53 0 0 0-.021.398.52.52 0 0 0 .268.297c7.573 3.618 7.646 12.36 7.705 20.816.041 5.53.08 10.751 2.127 14.536 2.926 5.408 11.673 6.326 19.844 6.51 1.088.024 2.221.028 3.374.017a140.2 140.2 0 0 0 26.643 2.656c4.004.01 8.006-.207 11.986-.65 10.148-1.164 17.839-3.823 20.571-7.164 1.091-1.345 1.376-2.794.806-4.188zm-64.617 5.321c.041-.643 1.355-1.414 4.917-1.644a86.501 86.501 0 0 1 5.46-.187c8.129 0 11.368 1.439 11.563 2.252.055.233-.268.671-1.356 1.137-1.682.723-9.13 1.355-15.879 1.425-2.683-.539-4.764-2.065-4.705-2.983z"
            fill="#497FBF" />
          <path d="M172.953 160.866s-1.39 8.026-4.08 12.888" stroke="#EF8848" strokeWidth="2.909" strokeMiterlimit="10" strokeLinecap="round" />
          <path
            d="M123.62 23.208c-10.253-2.885-34.434-6.013-47.96 4.088-10.798 8.053-5.909 13.66 1.946 9.881 6.02-2.892 15.639-3.1 23.003-1.842 12.63 2.158 20.428-5.825 23.011-12.127z"
            fill="#60A4F7" />
          <path
            d="M144.228 166.789c-.087-.073-8.668-7.108-16.741-1.738-6.742 4.487-5.057 11.943-4.806 12.909h1.39a.687.687 0 0 0-.021-.299c-.087-.295-2.085-7.254 4.202-11.446 7.201-4.796 14.76 1.391 15.076 1.641a.7.7 0 0 0 .509.158.698.698 0 0 0 .391-1.225z"
            fill="#EF8848" />
          <path opacity=".4"
                d="M87.514 119.727c1.043-4.373-4.8-5.454-3.666-9.413.695-2.433 5.022-4.824 3.152-8.126 3.607 2.28 1.845 5.714 1.87 8.822 0 2.433 4.066 3.924 3.263 7.57-.386 1.759-1.623 2.391-3.938 3.854-.695.435-.556 1.957-1.303.522-.549-1.053.386-2.249.622-3.229z"
                fill="#fff" />
        </svg>

      </div>
    );
  }
}
