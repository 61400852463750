import { Page } from '@happysanta/router';

export const ROOT_MAIN = 'root_main';
export const ROOT_FATAL_ERROR = 'root_fatal_error';

export const VIEW_ONBOARDING = 'view_onboarding'; 
export const VIEW_MAIN = 'view_main'; 
export const VIEW_TRAINING = 'view_training'; 
export const VIEW_CATALOG = 'view_catalog'; 
export const VIEW_PROFILE = 'view_profile';
export const VIEW_PAYWALL = 'view_paywall'; 
export const VIEW_TESTING = 'view_testing'; 
export const VIEW_CHALLENGES = 'view_challenges'; 

export const PANEL_MAIN = 'panel_main'; 
export const PANEL_ONBOARDING = 'panel_onboarding_1';  
export const PANEL_ONBOARDING_2 = 'panel_onboarding_2';  
export const PANEL_ONBOARDING_3 = 'panel_onboarding_3';  
export const PANEL_ONBOARDING_4 = 'panel_onboarding_4';  
export const PANEL_ONBOARDING_5 = 'panel_onboarding_5';   
export const PANEL_ONBOARDING_FINISH = 'panel_onboarding_finish';  
export const PANEL_TRAINING_DAILY = 'panel_training_daily'; 
export const PANEL_TRAINING_MAIN = 'panel_training_main'; 
export const PANEL_TRAINING_CATALOG = 'panel_training_catalog'; 
export const PANEL_TRAINING_CATALOG_SINGLE = 'panel_training_catalog_single'; 
export const PANEL_TRAINING_COMPLETE = 'panel_training_complete'; 
export const PANEL_TRAINING_SHARING = 'panel_training_sharing'; 
export const PANEL_CATALOG = 'panel_catalog'; 
export const PANEL_CATALOG_ARTICLE = 'panel_catalog_article'; 
export const PANEL_CATALOG_FAQ = 'panel_catalog_faq';  
export const PANEL_CATALOG_FAQLIST = 'panel_catalog_faqlist';  
export const PANEL_PROFILE = 'panel_profile';
export const PANEL_PROFILE_SETTINGS = 'panel_profile_settings';
export const PANEL_PROFILE_PROGRESS = 'panel_profile_progress';
export const PANEL_PAYWALL = 'panel_paywall'; 
export const PANEL_TESTING = 'panel_testing'; 
export const PANEL_TESTING_START = 'panel_testing_start'; 
export const PANEL_TESTING_FINISH = 'panel_testing_finish'; 
export const PANEL_CHALLENGES_START = 'panel_challenges_start'; 
export const PANEL_CHALLENGES_OPEN = 'panel_challenges_open'; 
export const PANEL_CHALLENGES_WAITING = 'PANEL_CHALLENGES_WAITING'; 

export const PAGE_MAIN = '';
export const PAGE_ONBOARDING = '/onboarding/';
export const PAGE_ONBOARDING_2 = '/onboarding/2';
export const PAGE_ONBOARDING_3 = '/onboarding/3';
export const PAGE_ONBOARDING_4 = '/onboarding/4';
export const PAGE_ONBOARDING_5 = '/onboarding/5';
export const PAGE_ONBOARDING_FINISH = '/onboarding/finish';
export const PAGE_TRAINING = '/training';
export const PAGE_TRAINING_MAIN = '/training/:id([0-9]+)';
export const PAGE_TRAINING_COMPLETE = '/training/complete';
export const PAGE_TRAINING_CATALOG = '/training/catalog';
export const PAGE_TRAINING_CATALOG_SINGLE = '/training/catalog-single';
export const PAGE_TRAINING_SHARING = '/training/sharing';
export const PAGE_CATALOG = '/catalog';
export const PAGE_CATALOG_ARTICLE = '/catalog/article/:id([0-9]+)';
export const PAGE_CATALOG_FAQLIST = '/catalog/faq';
export const PAGE_CATALOG_FAQ = '/catalog/:id([0-9]+)'; 
export const PAGE_ERROR = '/error';
export const PAGE_PROFILE = '/profile';
export const PAGE_PROFILE_SETTINGS = '/profile/settings';
export const PAGE_PROFILE_PROGRESS = '/profile/progress';
export const PAGE_PAYWALL = '/paywall/';
export const PAGE_TESTING = '/testing/:testId([0-9]+)/:slideId([0-9a-z]+)'; 
export const PAGE_TESTING_START = '/testing/:testId([0-9]+)';  
export const PAGE_TESTING_FINISH = '/testing/finish';  
export const PAGE_CHALLENGES = '/challenge/:id([0-9]+)';  
export const PAGE_CHALLENGES_WAITING = '/challenge/:id([0-9]+)/waiting';  

export const MODAL_PAYWALL = 'modal_paywall'
export const MODAL_TRAINING_EXAMPLE = 'modal_training_example'
export const MODAL_TRAINING_EXIT = 'modal_training_exit'
export const MODAL_TRAINING_LEVEL = 'modal_training_level'
export const MODAL_TRAINING_LEVEL_SUCCESS = 'modal_training_level_success'
export const MODAL_CATALOG_TRENER = 'modal_catalog_trener'
export const MODAL_PROFILE_TEXT = 'modal_profile_text'
export const MODAL_PROFILE_SETTINGS = 'modal_profile_settings';
export const MODAL_SUBINFO = 'modal_subinfo';
export const MODAL_INTEST = 'modal_intest';
export const MODAL_PERMISSION = 'modal_permission';

// challenge module
export const MODAL_FRIENDS_INVITE = 'modal_friends_invite';
export const POPOUT_STRIKE_INFO = 'popout_strike_info';
export const MODAL_CHALLENGE_INFO = 'modal_challenge_info';
export const MODAL_FRIENDS_ALL = 'modal_friends_all';
export const MODAL_CHALLENGE_TASKS = 'modal_challenge_tasks';
export const MODAL_CHALLENGE_SIMPLE = 'modal_challenge_simple';
export const MODAL_CHAT_CREATE = 'modal_chat_create';
export const MODAL_SEND_REACTION = 'modal_send_reaction';
export const POPOUT_CHAT_OPEN = 'popout_chat_open';
export const POPOUT_CHALLENGE_LEAVE = 'popout_challenge_leave';
export const POPOUT_CHALLENGE_SIMPLE = 'popout_challenge_simple';

export const PAGE_NAMES = {
  [PAGE_MAIN]: 'main',
  [PAGE_ONBOARDING]: 'onboarding', 
  [PAGE_TRAINING]: 'training', 
  [PAGE_CATALOG]: 'catalog', 
  [PAGE_PROFILE]: 'profile', 
  [PAGE_PAYWALL]: 'paywall', 
  [PAGE_TESTING]: 'testing', 
  [PAGE_ERROR]: 'error',
  [MODAL_PAYWALL]:  'modal_paywall',
  [MODAL_TRAINING_EXAMPLE]:  'modal_training_example',
  [MODAL_TRAINING_EXIT]:  'modal_training_exit',
  [MODAL_TRAINING_LEVEL]:  'modal_training_level',
  [MODAL_TRAINING_LEVEL_SUCCESS]:  'modal_training_level_success',
  [MODAL_CATALOG_TRENER]:  'modal_catalog_trener',
  [MODAL_PERMISSION]: 'modal_permission',
  [MODAL_PROFILE_TEXT]:  'modal_profile_text',
  [MODAL_PROFILE_SETTINGS]:  'modal_profile_settings',
  [MODAL_SUBINFO]:  'modal_subinfo',
  [MODAL_INTEST]:  'modal_intest',
  [MODAL_FRIENDS_INVITE]:  'modal_friends_invite',
  [POPOUT_STRIKE_INFO]:  'popout_strike_info',
  [MODAL_CHALLENGE_INFO]:  'modal_challenge_info',
  [MODAL_FRIENDS_ALL]:  'modal_friends_all',
  [MODAL_CHALLENGE_TASKS]:  'modal_challenge_tasks',
  [MODAL_CHAT_CREATE]:  'modal_chat_create',
  [MODAL_SEND_REACTION]:  'modal_send_reaction',
  [MODAL_CHALLENGE_SIMPLE]:  'modal_challenge_simple',
  [POPOUT_CHAT_OPEN]:  'popout_chat_open',
  [POPOUT_CHALLENGE_LEAVE]:  'popout_challenge_leave',
  [POPOUT_CHALLENGE_SIMPLE]:  'popout_challenge_simple',

};

export const routes = {
  [PAGE_MAIN]: new Page(PANEL_TRAINING_DAILY, VIEW_TRAINING),
  [PAGE_ONBOARDING]: new Page(PANEL_ONBOARDING, VIEW_ONBOARDING), 
  [PAGE_ONBOARDING_2]: new Page(PANEL_ONBOARDING_2, VIEW_ONBOARDING), 
  [PAGE_ONBOARDING_3]: new Page(PANEL_ONBOARDING_3, VIEW_ONBOARDING), 
  [PAGE_ONBOARDING_4]: new Page(PANEL_ONBOARDING_4, VIEW_ONBOARDING), 
  [PAGE_ONBOARDING_5]: new Page(PANEL_ONBOARDING_5, VIEW_ONBOARDING), 
  [PAGE_ONBOARDING_FINISH]: new Page(PANEL_ONBOARDING_FINISH, VIEW_ONBOARDING), 

  [PAGE_TRAINING]: new Page(PANEL_TRAINING_DAILY, VIEW_TRAINING), 
  [PAGE_TRAINING_MAIN]: new Page(PANEL_TRAINING_MAIN, VIEW_TRAINING), 
  [PAGE_TRAINING_CATALOG]: new Page(PANEL_TRAINING_CATALOG, VIEW_TRAINING), 
  [PAGE_TRAINING_CATALOG_SINGLE]: new Page(PANEL_TRAINING_CATALOG_SINGLE, VIEW_TRAINING), 
  [PAGE_TRAINING_COMPLETE]: new Page(PANEL_TRAINING_COMPLETE, VIEW_TRAINING), 
  [PAGE_TRAINING_SHARING]: new Page(PANEL_TRAINING_SHARING, VIEW_TRAINING),  

  [PAGE_CATALOG]: new Page(PANEL_CATALOG, VIEW_CATALOG), 
  [PAGE_CATALOG_ARTICLE]: new Page(PANEL_CATALOG_ARTICLE, VIEW_CATALOG), 
  [PAGE_CATALOG_FAQ]: new Page(PANEL_CATALOG_FAQ, VIEW_CATALOG), 
  [PAGE_CATALOG_FAQLIST]: new Page(PANEL_CATALOG_FAQLIST, VIEW_CATALOG), 

  [PAGE_PROFILE]: new Page(PANEL_PROFILE, VIEW_PROFILE), 
  [PAGE_PROFILE_SETTINGS]: new Page(PANEL_PROFILE_SETTINGS, VIEW_PROFILE), 
  [PAGE_PROFILE_PROGRESS]: new Page(PANEL_PROFILE_PROGRESS, VIEW_PROFILE), 

  [PAGE_PAYWALL]: new Page(PANEL_PAYWALL, VIEW_PAYWALL), 

  [PAGE_TESTING]: new Page(PANEL_TESTING, VIEW_TESTING), 
  [PAGE_TESTING_START]: new Page(PANEL_TESTING_START, VIEW_TESTING), 
  [PAGE_TESTING_FINISH]: new Page(PANEL_TESTING_FINISH, VIEW_TESTING), 

  [PAGE_CHALLENGES]: new Page(PANEL_CHALLENGES_OPEN, VIEW_CHALLENGES), 
  [PAGE_CHALLENGES_WAITING]: new Page(PANEL_CHALLENGES_WAITING, VIEW_CHALLENGES), 
  
};

export const pagesCanNotOpenByLink = [
//   PAGE_ONBOARDING,
];

export const modalsCanNotOpenByLink = [
//   MODAL_TERMS 
];

export const popupsCanNotOpenByLink = [
//   POPUP_WIDGET_ACTION_SHEET
];
