import React, { useEffect, useState } from 'react';
import { Panel, PanelHeader, PanelHeaderBack, Div, Title, Text } from '@vkontakte/vkui';
import TrainingCatalogItemPreview from '../../../components/TrainingCatalogItemPreview/TrainingCatalogItemPreview';
import { MODAL_PAYWALL, PAGE_TRAINING, PAGE_TRAINING_MAIN } from '../../../routing/routes';
import Skeleton from 'react-loading-skeleton';
import { initHash } from '../../../routing/router';
import { useRouter } from '@happysanta/router';



const TrainingCatalogSingle = ({ id, setPanel, catalog , catalogkey, goBack, nextPage, openPaywallModal, amplitudeEvent, isPremium, fetchData }) => {
    const router = useRouter();
    const [trainingList, setTrainigList] = useState(null);
    useEffect(() => {  
        if (catalog && catalog.length ) {
            let key = (catalogkey ? catalogkey : (!initHash && localStorage.getItem('catalogkey')))
            if (!key && initHash) { 
                let key = initHash.replace('#', '') 
                let arr = catalog.find(item => item.slug === key)  
                arr ?  setTrainigList(arr) : router.replacePage(PAGE_TRAINING);
            } else {
                let arr = catalog.find(item => item.id === +key)
                setTrainigList(arr)
            }
        } else {
            fetchData()
        }

    }, [catalog])

    return (
        <Panel id={id}>
            <PanelHeader separator={false} left={<PanelHeaderBack onClick={() => router.replacePage(PAGE_TRAINING)} />} >Каталог</PanelHeader>
            <Div style={{ paddingBottom: 2 }}>
                <Title className="titleFlexBox" level="3" weight="semibold" style={{ marginBottom: 5 }} > {trainingList  ? trainingList.title : <Skeleton width={150} />}   </Title> 
                   {trainingList ?
                    (trainingList.description ? 
                        <Text className="text-grey" style={{ marginBottom: 10 }}>
                            <div dangerouslySetInnerHTML={{ __html: trainingList.description }}></div>
                        </Text> : '')
                    : <>
                        <Skeleton count={3} />
                        <Skeleton width={120} />
                    </>}
            </Div>
            {(trainingList && trainingList.workouts) ?
                trainingList.workouts.map((item, index) => {
                    return <TrainingCatalogItemPreview
                        amplitudeEvent={amplitudeEvent}
                        key={index} data={item}
                        setPanel={setPanel}
                        clickEvent={trainingList.is_free ? nextPage : () => openPaywallModal(MODAL_PAYWALL, { nextPageId: item.id })}
                        isFree={isPremium || trainingList.is_free}
                        panelToFull={PAGE_TRAINING_MAIN} ></TrainingCatalogItemPreview>
                })
                :
                <>
                    < TrainingCatalogItemPreview key={1} data={null} panelToFull={null} clickEvent={null} isFree={true} />
                    < TrainingCatalogItemPreview key={2} data={null} panelToFull={null} clickEvent={null} isFree={true} />
                    < TrainingCatalogItemPreview key={3} data={null} panelToFull={null} clickEvent={null} isFree={true} />
                    < TrainingCatalogItemPreview key={4} data={null} panelToFull={null} clickEvent={null} isFree={true} />
                </>
            }


        </Panel>
    );
}
export default TrainingCatalogSingle;
