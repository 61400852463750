import React, { useEffect, useState } from 'react';
import { Panel, PanelHeader, Text, Title, Div, PanelHeaderBack } from '@vkontakte/vkui'; 
import CatalogService from '../../services/CatalogService';
import { useLocation, withRouter } from '@happysanta/router';
import { PAGE_CATALOG } from '../../routing/routes'; 
import Skeleton from 'react-loading-skeleton'; 
import getErrorStatus from '../../components/tools/errorStatus';


const catalogService = new CatalogService();
const ArticlePanel = ({ setPanel, articleId, id, goBack, nextPage, history, setErrorView, amplitudeEvent }) => {
    const location = useLocation();
    const [dataItem, setDataItem] = useState(null);
    useEffect(() => {
        if (location.getParams().id) {
            amplitudeEvent("advice_article_open", { "article_id": location.getParams().id })
            try {  
                 catalogService.getArticle(location.getParams().id).then(data => {
                    if (data === 'offline') return setErrorView(data)
                    setDataItem(data.data)
                 }).catch(error =>{
                    setErrorView(getErrorStatus(error));
                 }) 
            } catch (error) {
                setErrorView(getErrorStatus(error));
            }
        } else nextPage(PAGE_CATALOG)
    }, [])
    return (
        <Panel id={id}>
            <PanelHeader separator={false} left={<PanelHeaderBack onClick={goBack} />} >Статьи</PanelHeader>
            {dataItem ?
                <img src={dataItem.preview} width="100%"></img>
                : <Skeleton height={211} />
            }
            <Div>
                <Title level="1" weight="semibold" style={{ marginBottom: 12 }}>
                    {dataItem && dataItem.title ? dataItem.title : <Skeleton count={3} />}
                </Title>
                <Text className="text-grey">
                    {dataItem && dataItem.text ?
                        <div className="articleWrap" dangerouslySetInnerHTML={{ __html: dataItem.text }}></div>
                        : <Skeleton count={10} />
                    }
                </Text>
            </Div>
        </Panel >
    )
}


export default withRouter(ArticlePanel);