import axiosApi from './axiosApi';
import { apiBaseUri } from '../environment';


export default class ChallengesService {

    getMain(gender) {
        return axiosApi.get(`${apiBaseUri}?gender=${gender}`)
    }

    getFriendPlayer(id, data) {
        return axiosApi.get(`${apiBaseUri}/sharedChallenge/${id}/friends/players?`+data)
    }
    getFriendNonPlayer(id, vk_token) {
        return axiosApi.get(`${apiBaseUri}/sharedChallenge/${id}/friends/non-players`, { vk_token })
    }
    acceptChallenge(id) {
        return axiosApi.post(`${apiBaseUri}/sharedChallenge/${id}/acceptInvitation`)
    }
    inviteFriends(id, data) {
        return axiosApi.post(`${apiBaseUri}/sharedChallenge/${id}/inviteFriends`, data)
    }
    joinChallenge(id){
        return axiosApi.post(`${apiBaseUri}/sharedChallenge/${id}/join`)
    }
} 