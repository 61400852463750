import { Caption, Panel, Title, View, Div, Text, Group, CardScroll, Card, Avatar, Button, CardGrid, Subhead, SimpleCell, IconButton, ModalRoot, ScreenSpinner } from '@vkontakte/vkui';
import React, { useState, useEffect, useRef } from 'react';
import { MODAL_FRIENDS_ALL, MODAL_FRIENDS_INVITE, PAGE_CHALLENGES, PANEL_CHALLENGES_WAITING, PANEL_CHALLENGES_OPEN, POPOUT_CHALLENGE_SIMPLE, MODAL_CHALLENGE_TASKS, MODAL_CHALLENGE_SIMPLE, PAGE_CHALLENGES_CLOSED, PAGE_CHALLENGES_WAITING, PANEL_CHALLENGES_START } from '../../routing/routes';
import './Challenges.css';
import mockHeader from '../../img/mock/challenge-header.png'
import otherFriendImg from '../../img/other-friends.png'
import { Icon28ChevronBack, Icon28Notifications, Icon28DoorArrowRightOutline, Icon24HelpOutline, Icon16Lock, Icon32Discussions } from '@vkontakte/icons';
import Timer from '../../components/Timer/Timer';
import ChallengeWaiting from './ChallengeWaiting';
import { useLocation, useRouter } from '@happysanta/router';
import ChallengeSimplePopout from './modals/ChallengeSimplePopout';
import ChallengesService from '../../services/ChallengesService';
import ChallengeOpen from './ChallengeOpen';
import InviteFriendsModal from './modals/InviteFriendsModal';
import { BridgePlus } from '@happysanta/bridge-plus';
import { isLocalDev } from '../../environment';
import AllFriendsModal from './modals/AllFriendsModal';
import ChallengeTasksModal from './modals/ChallengeTasksModal';
import ChallengeSimpleModal from './modals/ChallengeSimpleModal';
import ChallengeStart from './ChallengeStart';
const challengesService = new ChallengesService();
const Challenges = ({ viewId, activePanel, onSwipeBack, goBack, history, bufferActivePanel, amplitudeEvent, setErrorView, changeMainNav, vkData, challengesData }) => {
    const router = useRouter();
    const location = useLocation();
    const baseTargetRef = useRef(null);
    const [modalData, setModalData] = useState(null)
    const [currentChallengeData, setCurrentChallengeData] = useState(null)
    const [friendsAll, setFriendsAll] = useState([])
    const [selectedFriends, setSelectedFriends] = useState([])
    const mockFriends = [
        { first_name: 'Паша', last_name: 'Павлов', photo_50: "", id: 1 },
        { first_name: 'Маша', last_name: 'Павлов5', photo_50: "", id: 2 },
        { first_name: 'Андрей', last_name: 'Павлов2', photo_50: "", id: 3 },
        { first_name: 'Сергей', last_name: 'Павлов2', photo_50: "", id: 4 },
        { first_name: 'Дима', last_name: 'Павлов3', photo_50: "", id: 5 },
    ]
    const mockTasks = [
        { image: '', title: 'Задание 1', descr: "День 1", closed: true, id: 1 },
        { image: '', title: 'Задание 2', descr: "День 1", closed: true, id: 2 },
        { image: '', title: 'Задание 3', descr: "День 1", closed: true, id: 3 },
        { image: '', title: 'Задание 4', descr: "День 1", closed: true, id: 4 },
    ]
    const sameData = {
        title: 'Перед прохождением челленджа рекомендуем проконсультироваться с врачом',
        content: 'Этот челлендж не подходит людям с проблемами со здоровьем и тем, у кого диастаз и больные колени. ',
        type: 'alert',
        button: 'Продолжить',
    }


    useEffect(() => {
        const challengeId = location.getParams().id;
        if (challengeId) {
            let challenge;
            Object.keys(challengesData).map((key, index) => {
                if (!challenge) challenge = challengesData[key].find(item => item.challenge_id === challengeId)
            });
            console.log(challenge)
            setCurrentChallengeData(challenge)
        }
        // fetchChallengesData()
        getAllfriends()
        // getFriendPlayer();
    }, [])

    const fetchChallengesData = async () => {
        try {
            challengesService.getMain('man').then(data => {
                // console.log(data)
                // setChallengesData(data.data.shared_challenges)
            })
        } catch (error) {
            console.log('fetchChallengesData--error ', error)

        }
    }



    const getFriendPlayer = () => {
        const params = {
            "vk_token": vkData.vkToken,
            "user_id": vkData.id,
            "limit": 10,
            "offset": 0
        }
        challengesService.getFriendPlayer(
            vkData.id,
            Object.keys(params).map(key => key + '=' + params[key]).join('&')  
        ).then(data => {
            console.log(data)
        })
    }

    const inviteFriendsToChallenge = () => {
        challengesService.inviteFriends(
            currentChallengeData.challenge_id,
            {
                "inviter_token": vkData.vkToken,
                "invited_vk_ids": [getIdArray(selectedFriends)]
            }
        ).then(data => {
            console.log(data)
        })
    }

    const joinChallenge = () => {
        // challengesService.joinChallenge(currentChallengeData.challenge_id)
        setCurrentChallengeData(currentChallengeData => ({
            ...currentChallengeData,
            challengeType: 'waiting',
        }))

    }


    const getIdArray = (array) => {
        let newArray = [];
        array.map(item => {
            newArray.push(item.id)
        })
        return newArray;
    }

    const openModal = (modal, data) => {
        modal ? router.pushModal(modal) : router.popPage()
    }

    const openPopout = (popout, data) => {
        console.log(data.modalData)
        popout ? router.pushPopup(popout) : router.popPage();
        if (data && data.modalData) setModalData(data.modalData)

    }
    const getAllfriends = async () => {
        if (isLocalDev) setFriendsAll(mockFriends)
        try {
            const friends = await BridgePlus.api('friends.get', { 'order': 'hints', 'fields': "photo_50" }, "friends");
            setFriendsAll(friends.response.items)
            console.log(friends.response.items)
        }
        catch (error) {
            console.log(error)
        }

    }
    const nextPage = (nextPage, data) => {
        console.log(location.getParams().id)
        router.pushPage(nextPage, { id: '37' })
    }

    const selectFriend = (friend) => {
        if (friend) {
            let friendsArray = selectedFriends;
            if (friendsArray.length && friendsArray.find(item => item.id === +friend.id)) {
                friendsArray = friendsArray.filter(function (obj) {
                    return obj.id !== +friend.id;
                });
            } else friendsArray.push(friend)
            setSelectedFriends([...friendsArray])
        } else setSelectedFriends([...[]])
    }

    const getPanelProps = (panelId) => {
        return {
            id: panelId,
            changeMainNav: changeMainNav,
            goBack: goBack,
            openPopout: openPopout,
            openModal: openModal,
            selectFriend: selectFriend,
            challengeTasks: mockTasks,
            selectedFriends: selectedFriends,
            mockFriends: friendsAll,
            challengeData: currentChallengeData,
            nextPage: nextPage,
            setErrorView: setErrorView,
            history: history,
            // openPopout: openPopout,  
            amplitudeEvent: amplitudeEvent,
        };
    };

    const getModalProps = (modalId) => {
        return {
            id: modalId,
            closeEvent: () => router.popPage(),
            openModal: openModal,
            selectFriend: selectFriend,
            selectedFriends: selectedFriends,
            amplitudeEvent: amplitudeEvent,
        };
    };

    const modal = (
        <ModalRoot
            activeModal={location.getModalId()}
            onClose={() => { router.pushModal(null); }}
        >
            <InviteFriendsModal
                {...getModalProps(MODAL_FRIENDS_INVITE)}
                settlingHeight={100}
                inviteFriendsToChallenge={inviteFriendsToChallenge}
                nextEvent={() => {
                    router.popPage();
                    joinChallenge()
                    setTimeout(() => {
                        nextPage(PAGE_CHALLENGES_WAITING)
                    }, 200);
                }}
                dynamicContentHeight
                friendsData={friendsAll} />
            <AllFriendsModal
                {...getModalProps(MODAL_FRIENDS_ALL)}
                settlingHeight={100}
                buttonEvent={() => {
                    setTimeout(() => {
                        nextPage(PAGE_CHALLENGES_WAITING)
                    }, 200);
                }}
                dynamicContentHeight
                friendsData={mockFriends} />
            <ChallengeTasksModal
                {...getModalProps(MODAL_CHALLENGE_TASKS)}
                settlingHeight={100}
                dynamicContentHeight
                challengeTasks={mockTasks} />
            <ChallengeSimpleModal
                {...getModalProps(MODAL_CHALLENGE_SIMPLE)}
                settlingHeight={90}
                title={"Описание челленджа"}
                content={"Текст описания челленджа"}
                dynamicContentHeight />

        </ModalRoot>
    )

    const popout = (() => {
        if (location.getPopupId() === POPOUT_CHALLENGE_SIMPLE) {
            return (<ChallengeSimplePopout
                data={modalData}
                closeEvent={() => router.popPage()}
                buttonEvent={() => {
                    router.popPage(); setTimeout(() => {
                        openModal(MODAL_FRIENDS_INVITE)
                    }, 100);
                }}
                toggleRef={baseTargetRef.current} />)
        }
    })();


    return (
        <View
            modal={modal}
            popout={popout}
            history={history}
            id={viewId}
            activePanel={activePanel ? activePanel : (bufferActivePanel ? bufferActivePanel : PANEL_CHALLENGES_WAITING)}
            // activePanel={PANEL_CHALLENGES_START}
            onSwipeBack={onSwipeBack}>
            <ChallengeOpen {...getPanelProps(PANEL_CHALLENGES_OPEN)} key={currentChallengeData} />
            <ChallengeWaiting {...getPanelProps(PANEL_CHALLENGES_WAITING)} key={currentChallengeData} />
            <ChallengeStart {...getPanelProps(PANEL_CHALLENGES_START)} key={currentChallengeData} />
        </View >
    )

}

export default Challenges;
