import React, { } from 'react';
import {
    Div, Text,
    ModalPage,
    ModalPageHeader,
    PanelHeaderButton,
    Title,
    Button,
} from '@vkontakte/vkui';
import { Icon24Dismiss } from '@vkontakte/icons';
import NotifyPermissionIcon from './NotifyPermissionIcon'

const NotifyPermissionModal = ({ title, content, id, closeEvent, clickEvent }) => {
    return (
        <ModalPage
            id={id}
            onClose={closeEvent}
            dynamicContentHeight={true}
            header={
                <ModalPageHeader
                    right={<PanelHeaderButton onClick={closeEvent}><Icon24Dismiss /></PanelHeaderButton>}
                >
                </ModalPageHeader>
            }
        >
            <Div style={{ backgroundColor: 'inherit', paddingTop: 4, position: 'relative', textAlign: 'center' }}>
                <NotifyPermissionIcon />
                <Title level="2" weight='semibold' style={{marginTop: '7vh'}} >В тренировках часто <br/> не хватает мотивации? </Title>
                <Text className="greyText" style={{marginBottom: '17vh', marginTop: 11}}>
                    Мы исправим это благодаря боту. <br/>   Разрешите присылать нам уведомления на следующем окне
                </Text>
                <Button size="l" stretched onClick={clickEvent} >Сейчас разрешу</Button>
            </Div>
        </ModalPage>
    );
}


export default NotifyPermissionModal;