import React, { useState, useRef } from 'react';
// import Progress from 'react-circle-progress-bar'
import {
    View, Div, Text, Panel,
    ModalRoot, ModalPage, PanelHeaderButton, ModalPageHeader,
} from '@vkontakte/vkui';
import './PaywallPanel.css';
import {
    Icon24Dismiss
} from '@vkontakte/icons';
import { withRouter } from '@happysanta/router';
import { PAGE_TRAINING, PANEL_PAYWALL, VIEW_PAYWALL, VIEW_TRAINING } from '../../routing/routes';
import Paywall from '../../components/Paywall/Paywall';
import FadeHeader from '../../components/FadeHeader/FadeHeader';
import { BridgePlus } from '@happysanta/bridge-plus';
const PaywallPanel = ({ setHomePanel, backPanel, viewId, history, backPage, changeMainNav, amplitudeEvent, setPremium }) => {
    const [activePopout, setActivePopout] = useState(null);
    const [activeModal, setActiveModal] = useState(null);
    const info = `После подтверждения покупки оплата происходит автоматически с карты, привязанной к вашей учётной записи.
    <br /><br />
    Подписка продлевается автоматически. Отменить её можно за 24 часа до окончания оплаченного периода или раньше. После отмены подписки её действие сохранится до конца оплаченного периода. Возврат платежа за текущую подписку невозможен.
    <br /><br />
    Удаление приложения не прекращает действие подписки.
    <br /><br />
    Периоды подписки и их стоимость:
    <li>1 месяц - 379 рублей;</li>
    <li>3 месяца - 649 рублей;</li>
    <li>12 месяцев - 1 390 рублей;</li>
    <li>3 месяца с бесплатным периодом 30 дней - 649 рублей;</li>
    <li>12 месяцев с бесплатным периодом 30 дней - 1 390 рублей.</li>
    <br /> `
    + (BridgePlus.getStartParams().platform.indexOf('android') !== -1) ?
    `Вы можете управлять подпиской или отключить автопродление в настройках своего профиля Google.` :
    `Вы можете управлять подпиской или отключить автопродление в настройках своей учётной записи Apple ID.`;

    const modal = (
        <ModalRoot
            activeModal={activeModal}
            onClose={() => setActiveModal(null)}
        >
            <ModalPage
                settlingHeight={90}
                id="modalInfo"
                onClose={() => setActiveModal(null)}
                header={
                    <ModalPageHeader
                        right={<PanelHeaderButton onClick={() => setActiveModal(null)}><Icon24Dismiss /></PanelHeaderButton>}
                    >
                     Подписка
                    </ModalPageHeader>
                }
            >
                <Div style={{ backgroundColor: 'inherit', paddingTop: 14, position: 'relative' }}>
                    <Text className="textGrey scrollWrap h-70">
                        <div className="" dangerouslySetInnerHTML={{ __html: info }}></div>
                    </Text>
                </Div>
            </ModalPage>
        </ModalRoot>
    )


    return (
        <View
            activePanel={PANEL_PAYWALL}
            id={viewId}
            history={history}
            popout={activePopout}
            modal={modal} >
            <Panel id={PANEL_PAYWALL}>
                <FadeHeader/>
                <Paywall
                    isPage={true}
                    source={"after onboarding"}
                    successEvent={() => setPremium(true)}
                    clickEvent={() => setActiveModal('modalInfo')}
                    closeEvent={() => { amplitudeEvent("paywall_button", { "value_choose": "close", "source": "after onboarding" }); changeMainNav({ page: PAGE_TRAINING, view: VIEW_TRAINING }) }} amplitudeEvent={amplitudeEvent} />
            </Panel>
        </View>
    )
}



export default withRouter(PaywallPanel);
