import { Caption, Panel, Title, View, Div, Text, Group, CardScroll, Card, Avatar, Button, CardGrid, Subhead, SimpleCell, IconButton, Separator, FixedLayout, UsersStack } from '@vkontakte/vkui';
import React, { useState, useEffect, useRef } from 'react';
import './Challenges.css';
import mockHeader from '../../img/mock/challenge-header.png'
import { Icon28ChevronBack, Icon28Notifications, Icon28DoorArrowRightOutline, Icon24HelpOutline, Icon16Lock, Icon32Discussions, Icon28CancelCircleOutline, Icon20UserOutline } from '@vkontakte/icons';
import ChallengeHeader from '../../components/ChallengeHeader/ChallengeHeader';
import { MODAL_FRIENDS_INVITE, PAGE_TRAINING, POPOUT_CHALLENGE_SIMPLE, VIEW_TRAINING } from '../../routing/routes';
import Skeleton from 'react-loading-skeleton';
import datePipe from '../../components/pipes/datePipe';
import ChallengesService from '../../services/ChallengesService'; 
const ChallengeOpen = ({ id, openPopout, openModal, changeMainNav, challengeTasks, challengeData }) => {
    const modalData = {
        title: 'Что такое страйк',
        content: 'Это кол-во заданий, которые вы можете пропустить и не выбыть из челленджа',
        button: 'Понятно',
        // buttonEvent: () => openPopout(null),
    }

    const sameData = {
        title: 'Перед прохождением челленджа рекомендуем проконсультироваться с врачом',
        content: 'Этот челлендж не подходит людям с проблемами со здоровьем и тем, у кого диастаз и больные колени. ',
        type: 'alert',
        button: 'Продолжить',
    }
 

    return (
        <Panel id={id} className="safety-padding-bottom">
            <ChallengeHeader challengeData={challengeData} type={"small"} exitEvent={() => changeMainNav({ view: VIEW_TRAINING, page: PAGE_TRAINING })} />

            <Group className="customGroup challenge--content  p-b-70" separator="hide" style={{ paddingTop: 0 }}>
                <Div style={{ paddingBottom: 0 }}>
                    {challengeData ? <Text className='greyText'> С {datePipe(challengeData.starts_at)}</Text> : ''}
                    <Title weight="semibold" level="1" style={{ marginTop: 7, marginBottom: 5 }}>{challengeData ? challengeData.title : <Skeleton />}</Title>
                    {challengeData ?
                        <div className="dangerous--content-box" dangerouslySetInnerHTML={{ __html: challengeData.description }}></div>
                        : <Skeleton count={3} />
                    }
                    {/* info strike */}
                    {/* <Card size="s" style={{ position: 'relative', marginBottom: 8 }}>
                        <Text weight="regular" level="1" className="greyText flexBox flexBox--centered flexBox--start" style={{ marginTop: 0, padding: '6px 8px', letterSpacing: '0.4px' }}>
                            <Icon28CancelCircleOutline width={20} height={28} fill="#99A2AD" style={{ marginRight: 10 }} />
                            3 допустимых страйка</Text>
                        <Icon24HelpOutline fill="#99A2AD" className="helpIcon float" width={20} style={{ top: 8 }} onClick={() => openPopout(POPOUT_CHALLENGE_SIMPLE, { 'modalData': modalData })} />
                    </Card> */}
                    {
                        challengeData ?
                            <Card size="s" style={{ position: 'relative', display: 'inline-flex', marginTop: 20 }}>
                                <Text weight="regular" level="1" className="flexBox flexBox--centered flex-center " style={{ marginTop: 0, padding: '6px 8px', letterSpacing: '0.4px', color: '#6D7885' }}>
                                    {
                                        challengeData.user ?
                                            <>
                                                <span className='flexBox flexBox--centered'>
                                                    <Icon20UserOutline width={20} height={28} fill="#99A2AD" style={{ marginRight: 8 }} />
                                                    123 425 участников
                                                </span> <span style={{ padding: '0 2px' }}>·</span> <span className='flexBox flexBox--centered'>
                                                    10 друзей
                                                    <UsersStack style={{ marginLeft: 4 }} photos={['', '', '']}></UsersStack>
                                                </span>
                                            </>
                                            : "Станьте первым участником!"
                                    }
                                </Text>
                            </Card>
                            : ''
                    }

                </Div>

                <Separator style={{ margin: '16px 0 12px' }} />

                <Card className="customCard no-border" style={{ background: 'transparent', marginTop: 12 }}>
                    <Title level="3" weight='semibold' style={{ marginBottom: 16, paddingLeft: 16, paddingRight: 16 }}>Задания челленджа  </Title>
                    {challengeData && challengeData['tasks'] ?
                        challengeData['tasks'].map((task, index) => {
                            return (
                                <SimpleCell
                                    key={index}
                                    style={{ marginBottom: 7 }}
                                    before={<Avatar size={50} style={{ borderRadius: 12 }} src={task.preview} className="smallRadius" />}
                                    after={<Icon16Lock fill="#B8C1CC" width={24} height={20} />}>
                                    <div><Subhead weight="regular" className="greyText">День {task.available_day}</Subhead>
                                        <Text style={{ fontSize: 16, textOverflow: 'ellipsis', overflow: 'hidden'}}>{task.title}</Text></div>
                                </SimpleCell>
                            )
                        }) : ''
                    }
                </Card>
            </Group>

            <FixedLayout vertical="bottom" style={{ background: 'white' }} className="testingFixedLayout">
                <Div>
                    <Button stretched size="l" style={{ marginBottom: 10 }} onClick={() => openPopout(POPOUT_CHALLENGE_SIMPLE, { modalData: sameData })}  >
                        Принять участие
                    </Button>
                </Div>
            </FixedLayout>
        </Panel>
    )

}

export default ChallengeOpen;
