import { PAGE_CATALOG, PAGE_CATALOG_ARTICLE, PAGE_TRAINING, VIEW_CATALOG, VIEW_TRAINING } from "../../routing/routes";

const parse_query_string = (query) => {
    var vars = query.split("&");
    var query_string = {};
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1]);
        // If first entry with this name
        if (typeof query_string[key] === "undefined") {
            query_string[key] = decodeURIComponent(value);
            // If second entry with this name
        } else if (typeof query_string[key] === "string") {
            var arr = [query_string[key], decodeURIComponent(value)];
            query_string[key] = arr;
            // If third or later entry with this name
        } else {
            query_string[key].push(decodeURIComponent(value));
        }
    }
    return query_string;
}


const paramsLinkParseAndRedirect = (link, changeMainNav) => {
    console.log('paramsLinkParseAndRedirect', link)
    const paramsArray = parse_query_string(link.indexOf('?') !== -1 ? link.substring(1) : link)
    if (paramsArray) {
        if (paramsArray['article'])
        setTimeout(() => {
            return changeMainNav({page: PAGE_CATALOG, view: VIEW_CATALOG, pageId: paramsArray['article'], pushPage: PAGE_CATALOG_ARTICLE})
        }, 200);
        else changeMainNav({page: PAGE_TRAINING, view: VIEW_TRAINING}) 
    }  else changeMainNav({page: PAGE_TRAINING, view: VIEW_TRAINING}) 
}

export default paramsLinkParseAndRedirect;