import React, { Suspense } from "react";
import bridge from "@vkontakte/vk-bridge";
import App from "./App";
import amplitude from 'amplitude-js';
import "@vkontakte/vkui/dist/vkui.css";
import { amplitude_key, app_id } from './environment';
import { AdaptivityProvider, ConfigProvider } from '@vkontakte/vkui';
import { RouterContext, Router } from '@happysanta/router';
import AppLoading from "./components/AppLoading/AppLoading";
import mount from "./components/tools/mount";
import {
  routes
} from './routing/routes';
import { appInit, COLOR_SCHEMES, enableSystemSwipeBack } from "./components/modules/AppModule";
import { isMVK } from "./components/tools/helpers";



amplitude.getInstance().init(amplitude_key);
appInit(); 
const router = new Router(routes);
router.start();
bridge.send("VKWebAppInit"); 
// Subscribes to event, sended by client
// bridge.subscribe(e  => console.log(e));
if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  document.body.setAttribute('scheme', COLOR_SCHEMES.SPACE_GRAY);
  window._appColorScheme = COLOR_SCHEMES.SPACE_GRAY; 
}  

if (!isMVK() && app_id !== 7902415) document.body.classList.add("mode--internal");



mount(
  <RouterContext.Provider value={router}>
    <ConfigProvider
      platform="ios"
      webviewType={isMVK() ? 'vkapps' : 'internal'}
      isWebView={true} scheme={window._appColorScheme}>
      <AdaptivityProvider>
        <Suspense fallback={<AppLoading />}>
          <App />
        </Suspense>
      </AdaptivityProvider>
    </ConfigProvider>
  </RouterContext.Provider>
)

// ReactDOM.render(
//   <RouterContext.Provider value={router}>
//     <ConfigProvider isWebView={true} scheme={window._appColorScheme}>
//       <AdaptivityProvider>
//         <Suspense fallback={<AppLoading />}>
//           <App />
//         </Suspense>
//       </AdaptivityProvider>
//     </ConfigProvider>
//   </RouterContext.Provider>, document.getElementById('root'))

if (app_id !== 7942588) {
  import("./eruda").then(({ default: eruda }) => { }); //runtime download
}

// serviceWorker.unregister();
