import axios from 'axios'
import { app_id, version } from '../environment';

// Create instance
let axiosApi = axios.create();

// Set the AUTH token for any request
axiosApi.interceptors.request.use(function (config) { 
    config.headers.common['Content-Type'] = 'application/json';
    config.headers.common['Accept'] = 'application/json'; 
    config.headers.common['App-ID'] = app_id; 
    config.headers.common['Version'] = version;  
    return config;
});

axiosApi.interceptors.response.use(function(response) {
        return response
    }, function(error){
        if (!error.response || error.status === 0) {
            return 'offline' 
        } else {
            return error.response 
        }
    }
  );
  
export default axiosApi;