import { useRouter } from '@happysanta/router';
import { Group } from '@vkontakte/vkui';
import React, { } from 'react';
import { PAGE_CATALOG, PAGE_CATALOG_ARTICLE, PAGE_TESTING, PAGE_TRAINING, VIEW_CATALOG, VIEW_TESTING } from '../../../routing/routes';
import './AdvBanner.css';
const AdvBanner = ({ data, changeMainNav, startTesting, amplitudeEvent }) => {
    const router = useRouter();
    const parseAndRedirect = (link) => {
        if (link.indexOf('inapp://') !== -1) {
            if (link.indexOf('/article/') !== -1) {
                const linkID = getIdFromLink(link, '/article/');
                changeMainNav({ page: PAGE_CATALOG, view: VIEW_CATALOG })
                setTimeout(() => {
                    router.replacePage(PAGE_CATALOG_ARTICLE, { id: linkID })
                }, 100)
            }
            else if (link.indexOf('/testing/') !== -1) {
                const linkID = getIdFromLink(link, '/testing/');
                startTesting(linkID)
                // router.pushPage(PAGE_TESTING, { testId: linkID, slideId: 'start' })
                // changeMainNav({  view: VIEW_TESTING })
            }
            else if (link.indexOf('/faq/') !== -1) {
            } else return null
        } else return window.location = link
        // changeMainNav({page: PAGE_CATALOG, view: VIEW_CATALOG, slug: 'Catalog'})
    }

    const getIdFromLink = (link, PREFIX) => {
        return link.slice(link.indexOf(PREFIX) + PREFIX.length)
    }

    return (
        <Group className="customGroup advBanner" onClick={() => { amplitudeEvent("banner_open", { "sourse": PAGE_TRAINING, "banner_id": data.id}); parseAndRedirect(data.link) }} separator="hide" style={{ backgroundImage: `url(${data.image})`, backgroundSize: 'cover', backgroundPosition: 'top left' }}>
            {/* <a className="adv-inner"  href={data.link}></a> */}
        </Group>
    )
}


export default AdvBanner;