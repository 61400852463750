import { BridgePlus } from "@happysanta/bridge-plus";
export const PLATFORMS = {
  ANDROID: 'mobile_android',
  IOS: 'mobile_iphone',
  MVK: 'mobile_web',
  DESKTOP: 'desktop_web',
  ANDROID_MESSENGER: 'mobile_android_messenger',
  IOS_MESSENGER: 'mobile_iphone_messenger',
};
export function isMVK() {
  return BridgePlus.getStartParams().platform === PLATFORMS.MVK;
}
export function isMobile() {
  return BridgePlus.getStartParams().isMobile();
}