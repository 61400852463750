import React, { Component } from 'react';

export default class NotifyPermissionIcon extends Component {

  render() {
    return (
      <div className="NotifyPermissionIcon" style={{textAlign: 'center'}}>
      <svg width="190" height="190" viewBox="0 0 190 190" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39.3359 50.4688C33.0956 50.4688 28.2031 55.3612 28.2031 61.6016C28.2031 67.8419 33.0956 72.7344 39.3359 72.7344C45.5763 72.7344 50.4688 67.8419 50.4688 61.6016C50.4688 55.3612 45.5763 50.4688 39.3359 50.4688ZM72.7344 61.6016C72.7344 55.3612 67.8419 50.4688 61.6016 50.4688C55.3612 50.4688 50.4688 55.3612 50.4688 61.6016C50.4688 67.8419 55.3612 72.7344 61.6016 72.7344C67.8419 72.7344 72.7344 67.8419 72.7344 61.6016ZM83.8672 72.7344C90.1075 72.7344 95 67.8419 95 61.6016C95 55.3612 90.1075 50.4688 83.8672 50.4688C77.6269 50.4688 72.7344 55.3612 72.7344 61.6016C72.7344 67.8419 77.6269 72.7344 83.8672 72.7344ZM186.923 179.454L186.912 179.449C179.944 175.165 177.628 165.765 181.803 158.785C187.239 149.327 190 139.102 190 128.398C190 94.636 162.156 67.168 128.398 67.168C123.452 67.168 118.766 67.7116 114.472 68.777C112.45 69.2825 110.873 70.8863 110.395 72.9192C106.111 91.3904 91.4015 106.1 72.9195 110.4C70.8863 110.873 69.2884 112.444 68.7774 114.472C67.7116 118.755 67.168 123.441 67.168 128.398C67.168 162.161 94.6412 190 128.398 190H184.434C187.01 190 189.261 188.222 189.848 185.711C190.446 183.2 189.228 180.607 186.923 179.454Z" fill="url(#paint0_linear_5609_298543)"/>
<path d="M61.6016 0C27.8688 0 0 27.8688 0 61.6016C0 72.2887 2.7832 82.7535 8.12658 91.7711C12.2457 99.0078 9.9082 108.248 2.7832 112.479C0.556641 113.703 -0.444941 116.263 0.223027 118.713C0.890996 121.162 3.00623 122.832 5.56641 122.832H61.6016C66.5 122.832 71.2871 122.275 75.4062 121.273C98.0062 116.041 116.041 98.0055 121.273 75.5176C122.276 71.2871 122.832 66.6113 122.832 61.6016C122.832 27.8688 95.3344 0 61.6016 0V0ZM39.3359 67.168C36.2184 67.168 33.7695 64.7184 33.7695 61.6016C33.7695 58.484 36.2184 56.0352 39.3359 56.0352C42.4535 56.0352 44.9023 58.484 44.9023 61.6016C44.9023 64.7184 42.4535 67.168 39.3359 67.168ZM61.6016 67.168C58.484 67.168 56.0352 64.7184 56.0352 61.6016C56.0352 58.484 58.484 56.0352 61.6016 56.0352C64.7191 56.0352 67.168 58.484 67.168 61.6016C67.168 64.7184 64.7191 67.168 61.6016 67.168ZM83.8672 67.168C80.7496 67.168 78.3008 64.7184 78.3008 61.6016C78.3008 58.484 80.7496 56.0352 83.8672 56.0352C86.9847 56.0352 89.4336 58.484 89.4336 61.6016C89.4336 64.7184 86.9847 67.168 83.8672 67.168ZM106.133 122.832C103.059 122.832 100.566 125.324 100.566 128.398C100.566 131.473 103.059 133.965 106.133 133.965C109.207 133.965 111.699 131.473 111.699 128.398C111.699 125.324 109.207 122.832 106.133 122.832ZM128.398 122.832C125.324 122.832 122.832 125.324 122.832 128.398C122.832 131.473 125.324 133.965 128.398 133.965C131.473 133.965 133.965 131.473 133.965 128.398C133.965 125.324 131.473 122.832 128.398 122.832ZM150.664 122.832C147.59 122.832 145.098 125.324 145.098 128.398C145.098 131.473 147.59 133.965 150.664 133.965C153.738 133.965 156.23 131.473 156.23 128.398C156.23 125.324 153.738 122.832 150.664 122.832Z" fill="url(#paint1_linear_5609_298543)"/>
<path d="M61.6016 0C27.8688 0 0 27.8688 0 61.6016C0 72.2887 2.7832 82.7535 8.12658 91.7711C12.2457 99.0078 9.9082 108.248 2.7832 112.479C0.556641 113.703 -0.444941 116.263 0.223027 118.713C0.890996 121.162 3.00623 122.832 5.56641 122.832H61.6016C66.5 122.832 71.2871 122.275 75.4062 121.273C98.0062 116.041 116.041 98.0055 121.273 75.5176C122.276 71.2871 122.832 66.6113 122.832 61.6016C122.832 27.8688 95.3344 0 61.6016 0V0ZM39.3359 67.168C36.2184 67.168 33.7695 64.7184 33.7695 61.6016C33.7695 58.484 36.2184 56.0352 39.3359 56.0352C42.4535 56.0352 44.9023 58.484 44.9023 61.6016C44.9023 64.7184 42.4535 67.168 39.3359 67.168ZM61.6016 67.168C58.484 67.168 56.0352 64.7184 56.0352 61.6016C56.0352 58.484 58.484 56.0352 61.6016 56.0352C64.7191 56.0352 67.168 58.484 67.168 61.6016C67.168 64.7184 64.7191 67.168 61.6016 67.168ZM83.8672 67.168C80.7496 67.168 78.3008 64.7184 78.3008 61.6016C78.3008 58.484 80.7496 56.0352 83.8672 56.0352C86.9847 56.0352 89.4336 58.484 89.4336 61.6016C89.4336 64.7184 86.9847 67.168 83.8672 67.168ZM106.133 122.832C103.059 122.832 100.566 125.324 100.566 128.398C100.566 131.473 103.059 133.965 106.133 133.965C109.207 133.965 111.699 131.473 111.699 128.398C111.699 125.324 109.207 122.832 106.133 122.832ZM128.398 122.832C125.324 122.832 122.832 125.324 122.832 128.398C122.832 131.473 125.324 133.965 128.398 133.965C131.473 133.965 133.965 131.473 133.965 128.398C133.965 125.324 131.473 122.832 128.398 122.832ZM150.664 122.832C147.59 122.832 145.098 125.324 145.098 128.398C145.098 131.473 147.59 133.965 150.664 133.965C153.738 133.965 156.23 131.473 156.23 128.398C156.23 125.324 153.738 122.832 150.664 122.832Z" fill="url(#paint2_linear_5609_298543)"/>
<defs>
<linearGradient id="paint0_linear_5609_298543" x1="109.102" y1="190" x2="109.102" y2="50.4688" gradientUnits="userSpaceOnUse">
<stop stopColor="#885EED"/>
<stop offset="1" stopColor="#8195FF"/>
</linearGradient>
<linearGradient id="paint1_linear_5609_298543" x1="78.1152" y1="133.965" x2="78.1152" y2="0" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFBEF9"/>
<stop offset="1" stopColor="#FFF1FF"/>
</linearGradient>
<linearGradient id="paint2_linear_5609_298543" x1="78.116" y1="133.965" x2="78.116" y2="-0.000231564" gradientUnits="userSpaceOnUse">
<stop stopColor="#ADDCFF"/>
<stop offset="0.5028" stopColor="#EAF6FF"/>
<stop offset="1" stopColor="#EAF6FF"/>
</linearGradient>
</defs>
</svg>

      </div>
    );
  }
}
