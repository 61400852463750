import React from 'react';
import './TrainingDescription.css';
import { Div, MiniInfoCell, Text, Counter, Caption, useAppearance } from '@vkontakte/vkui';
import { Icon12Clock } from '@vkontakte/icons'; 
import Skeleton from 'react-loading-skeleton';
import defaultTimePipe from '../pipes/defaultTimePipe';
import { Icon16GridOfFour } from '@vkontakte/icons';
import LevelSvg from '../svgImages/LevelSvg';
import DumbbellsSvg from '../svgImages/DumbellSvg';

const TrainingDescription = ({ content, contentTitle, showDescription = true }) => {
    const appearance = useAppearance();
    return (
        <div className="TrainingDescriptionWrap">
            {(showDescription) && (
                <Text
                    style={{ marginTop: 4 }}
                    weight="regular"
                    className="custom"
                > {content ? <div dangerouslySetInnerHTML={{ __html: content.description }}></div> : <Skeleton count={3} />}
                </Text>
            )}
            <MiniInfoCell
                before={content && content.task.duration && <Icon12Clock fill={appearance === 'light' ? "#99A2AD" : "#909499"} width={15} />}
                style={{ paddingLeft: 15, maxHeight: 33 }}
                textWrap="full"
                className="custom"
                mode="base"
                textLevel="primary"
            >
                {content && content.task.duration ? defaultTimePipe(content.task.duration) : <Skeleton width={120} style={{ marginLeft: -23 }} />}
            </MiniInfoCell>

            <MiniInfoCell
                style={{ paddingLeft: 15, maxHeight: 33 }}
                before={content && content.task.complexity && <LevelSvg  appearance={appearance === 'light' ? "#99A2AD" : "#909499"}/>}
                textWrap="full"
                className="custom"
                textLevel="primary"
            > {content && content.task.complexity ?
                {
                    hard: `Сложный уровень`,
                    medium: `Средний уровень`,
                    easy: `Лёгкий уровень`
                }[content.task.complexity]
                : <Skeleton width={210} style={{ marginLeft: -23 }} />
                }
            </MiniInfoCell>

            <MiniInfoCell
                before={content && content.task.parameter && <Icon16GridOfFour width={13} />}
                style={{ paddingLeft: 15, maxHeight: 33 }}
                textWrap="full"
                className="custom"
                textLevel="primary"
            > {content && content.task.parameter ?
                {
                    cardio: "Кардио",
                    dumbbells: "С гантелями",
                    mobility: "На мобильность",
                    strength: "С собственным весом",
                    stretch: "Растяжка",
                }[content.task.parameter]
                : <Skeleton width={210} style={{ marginLeft: -23 }} />
                }
            </MiniInfoCell>

            {content ?
                ((content.task.equipment && content.task.equipment.length) ? (
                    <MiniInfoCell
                        style={{ paddingLeft: 15, maxHeight: 33, textTransform: 'capitalize' }}
                        before={ <DumbbellsSvg  appearance={appearance === 'light' ? "#99A2AD" : "#909499"} />}
                        textWrap="full"
                        className="custom"
                        mode="base"
                        textLevel="primary"
                    > {content.task.equipment.join(', ')}
                    </MiniInfoCell>
                ) : '') : ''
            }

            <Div style={{ marginTop: 20, paddingTop: 0, paddingBottom: 6 }}>
                {(content && content.task.score) ? (
                    <>
                        <Caption className="borderTop" level="1" weight="semibold" style={{ color: (appearance === 'light' ? "#818C99" : "#76787A"), marginBottom: 13, letterSpacing: 0.3, paddingTop: 15 }}>В ЭТОЙ ТРЕНИРОВКЕ РАЗВИВАЕМ НАВЫКИ:</Caption>
                        <div className="flexBox counterWrap" style={{ marginTop: 12 }}>
                            {content.task.score.mobility > 0 ? (<Counter className="customCounter" style={{ backgroundColor: '#59C7FF' }}>Мобильность +{content.task.score.mobility} мин</Counter>) : ''}
                            {content.task.score.flexibility > 0 ? (<Counter className="customCounter" style={{ backgroundColor: '#FF6D96' }}>Гибкость +{content.task.score.flexibility} мин</Counter>) : ''}
                            {content.task.score.cardio > 0 ? (<Counter className="customCounter" style={{ backgroundColor: '#AB4BF5' }}>Кардио +{content.task.score.cardio} мин</Counter>) : ''}
                            {content.task.score.strength > 0 ? (<Counter className="customCounter" style={{ backgroundColor: '#84C658' }}>Сила +{content.task.score.strength} мин</Counter>) : ''}
                        </div>
                    </>
                ) : <Skeleton />}
            </Div>
        </div>

    )
}



export default TrainingDescription;