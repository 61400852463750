import React, { useState, useEffect } from 'react';
// import Progress from 'react-circle-progress-bar'
import { Panel, PanelHeader, Div, FixedLayout, Button, Title, Headline, View, FormLayout, Radio, Gallery, Checkbox, PanelHeaderBack } from '@vkontakte/vkui';
import bigLogoIcon from '../../img/bigLogoIconColor.svg';
import './Onboarding.css';
import onBoardingBackground from '../../img/onBoardingBackground.jpg';
import bridge from '@vkontakte/vk-bridge';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Icon28ChevronBack } from '@vkontakte/icons';
import ProfileService from '../../services/ProfileService';
import { PAGE_ONBOARDING, PAGE_ONBOARDING_2, PAGE_ONBOARDING_3, PAGE_ONBOARDING_4, PAGE_ONBOARDING_5, PAGE_ONBOARDING_FINISH, PAGE_PAYWALL, PAGE_TRAINING, PANEL_ONBOARDING, PANEL_ONBOARDING_2, PANEL_ONBOARDING_3, PANEL_ONBOARDING_4, PANEL_ONBOARDING_5, PANEL_ONBOARDING_FINISH, PANEL_TRAINING_CATALOG_SINGLE, VIEW_ONBOARDING, VIEW_PAYWALL, VIEW_TRAINING } from '../../routing/routes';
import { withRouter, useLocation, useRouter } from '@happysanta/router';
import { BridgePlus } from '@happysanta/bridge-plus';
import FadeHeader from '../../components/FadeHeader/FadeHeader';
import { disableSystemSwipeBack, enableSystemSwipeBack } from '../../components/modules/AppModule';
import { initHash, initSearch } from '../../routing/router';
import paramsLinkParseAndRedirect from '../../components/tools/paramsLinkParseAndRedirect';


const profileSevice = new ProfileService();
const Onboarding = ({ viewId, onSwipeBack, activePanel, history, changeMainNav, amplitudeEvent, closeApp, isPremium }) => {
    const router = useRouter();
    const location = useLocation();
    const [answerArray, setAnswerArray] = useState({ "workout_parameter": {} });
    // const [activePanel, setActivePanel] = useState(location.getPanelId());
    const [slideIndexAge, setSlideIndexAge] = useState(75);
    const [procent, setProcent] = useState(0);
    const onboarding_id = 1;
    const ageArray = []; for (let i = 1920; i <= 2020; i++)  ageArray.push(i);
    useEffect(() => {
        router.replacePage(PAGE_ONBOARDING)
    }, [])
    const setPanelAnswer = (type) => {
        disableSystemSwipeBack()
        let answer = {};
        if (type && type !== 'finish') {
            answer[type] = (type === "birthyear" ? (slideIndexAge + 1920).toString() : (type === 'start' ? true : answerArray[type]));
            const value_answer = answer.workout_parameter ? Object.keys(answer.workout_parameter).join(', ') : Object.values(answer).toString()
            amplitudeEvent("onboarding_next_button_touch", {
                "screen_name": type,
                "onboarding_id": onboarding_id,
                "value_answer": value_answer
            });
            if (type === 'workout_parameter') {
                finishTimerCountdown();
                profileSevice.updateUserWorkoutParams(answerArray[type]).then(e => {
                    profileSevice.finishOnboarding()
                });
            }
            else if (type !== 'start') {
                profileSevice.updateUserData(answer);
            }
        } else {
            amplitudeEvent('onboarding_finished', {
                "onboarding_id": onboarding_id,
                "workout_length": answerArray['workout_duration_type'],
                "workout_emphasis": Object.keys(answerArray['workout_parameter']).join(', '),
            })
            amplitudeEvent('onboarding_next_button_touch', {
                "onboarding_id": onboarding_id,
                "screen_name": "finish",
                "value_answer": true,
            })
        }


    }

    const historyBack = () => {
        // const localHistory = location.getViewHistory(VIEW_ONBOARDING);
        // localHistory.pop();
        // const activePanel = localHistory[localHistory.length - 1];
        // if (activePanel === PANEL_ONBOARDING) {
        //     bridge.send('VKWebAppEnableSwipeBack');
        // }
        router.popPage()
    }


    const finishTimerCountdown = () => {
        const timerId = setInterval(() => setProcent(procent => procent + 1), 167);
        setTimeout(
            () => { clearInterval(timerId); setProcent(6) }, 1000) //to 6
        setTimeout(
            () => {
                const timerId = setInterval(() => setProcent(procent => procent + 1), 71);
                setTimeout(() => { clearInterval(timerId); setProcent(13) }, 500)
            }, 1500) // to 13
        setTimeout(
            () => {
                const timerId = setInterval(() => setProcent(procent => procent + 1), 60);
                setTimeout(() => { clearInterval(timerId); setProcent(37) }, 1440)
            }, 3000) // to 37
        setTimeout(
            () => {
                const timerId = setInterval(() => setProcent(procent => procent + 1), 60);
                setTimeout(() => { clearInterval(timerId); setProcent(55) }, 1000)
            }, 4800) // to 55
        setTimeout(
            () => {
                const timerId = setInterval(() => setProcent(procent => procent + 1), 50);
                setTimeout(() => { clearInterval(timerId); setProcent(100) }, 2200)
            }, 6500) // to 100
    }


    const setAnswer = (e) => {
        const answer = e.currentTarget;
        let newAnswer = answerArray;
        if (e.currentTarget.name === 'workout_parameter') {
            newAnswer["workout_parameter"][answer.value] ?
                delete newAnswer["workout_parameter"][answer.value]
                : newAnswer["workout_parameter"][answer.value] = "easy"
        } else newAnswer[answer.name] = answer.value;
        setAnswerArray({ ...newAnswer })
    }


    return (

        <>
            <View
                activePanel={activePanel ? activePanel : PANEL_ONBOARDING}
                id={viewId}
                history={history}
                onSwipeBack={onSwipeBack}
                className="view-backgroundImage"
                style={{ backgroundImage: `url(${(onBoardingBackground)})` }}>
                <Panel id={PANEL_ONBOARDING} >
                    <PanelHeader separator={false} left={<PanelHeaderBack onClick={() => closeApp()} />}  >
                        <FadeHeader />
                    </PanelHeader>
                    <Div >
                        <div className="vertical-center">
                            <div className="vertical-center-content text-centered">
                                <embed type="image/svg+xml" src={bigLogoIcon} alt="Time icon" />
                                <Title level="1" weight="semibold" style={{ marginBottom: 14, marginTop: 52, fontSize: 30 }}>VK Тренировки  </Title>
                                <Headline weight="regular" style={{ color: '#818C99' }}>Индивидуальная программа тренировок из 1 300 упражнений. Заполните небольшую анкету, чтобы начать заниматься.</Headline>
                            </div>

                        </div>
                    </Div>
                    <FixedLayout vertical="bottom">
                        <Div>
                            <Button stretched size="l" style={{ marginBottom: 10 }} onClick={() => { setPanelAnswer("start"); router.pushPage(PAGE_ONBOARDING_2) }}    >
                                Начать
                            </Button>
                        </Div>
                    </FixedLayout>
                </Panel>
                <Panel id={PANEL_ONBOARDING_2}>
                    <PanelHeader separator={false} left={<Icon28ChevronBack fill="00000" onClick={historyBack} style={{ marginLeft: 8 }} />}>
                        <FadeHeader />
                    </PanelHeader>
                    <Div style={{ paddingTop: 0 }}>
                        <div className="loadbar"><span className="active"></span><span></span><span></span><span></span><span></span> </div>
                        <div className="vertical-center align-top">
                            <div className="vertical-center-content ">
                                <Title level="1" weight="semibold" className=" text-centered" style={{ marginBottom: 28, marginTop: 15 }}>Ваш пол</Title>
                                <FormLayout style={{ width: '100%' }}>
                                    <Radio name="gender" className="custom-radio" value="man"
                                        defaultChecked={answerArray["gender"] === "man" ? true : false} onChange={setAnswer}> Мужской</Radio>
                                    <Radio name="gender" className="custom-radio" value="woman"
                                        defaultChecked={answerArray["gender"] === "woman" ? true : false} onChange={setAnswer}> Женский</Radio>
                                </FormLayout>
                            </div>

                        </div>
                    </Div>
                    {answerArray["gender"] ? (
                        <FixedLayout vertical="bottom">
                            <Div>
                                <Button stretched size="l" style={{ marginBottom: 10 }} onClick={() => { setPanelAnswer("gender"); router.pushPage(PAGE_ONBOARDING_3) }} >
                                    Продолжить
                                </Button>
                            </Div>
                        </FixedLayout>
                    ) : ''
                    }
                </Panel>
                <Panel id={PANEL_ONBOARDING_3}>
                    <PanelHeader separator={false} left={<Icon28ChevronBack fill="00000" onClick={historyBack} style={{ marginLeft: 8 }} />}>
                        <FadeHeader />
                    </PanelHeader>
                    <Div style={{ paddingTop: 0 }}>
                        <div className="loadbar"><span className="active"></span><span className="active"></span><span></span><span></span><span></span> </div>
                        <div className="vertical-center align-top">
                            <div className="vertical-center-content ">
                                <Title level="1" weight="semibold" className=" text-centered" style={{ marginBottom: 32, marginTop: 25 }}>Год вашего рождения</Title>

                                <Gallery className="custom-gallery"
                                    data-vkui-swipe-back={false}
                                    slideWidth="33%"
                                    align="center"
                                    slideIndex={slideIndexAge}
                                    onChange={slideIndexAge => { bridge.send("VKWebAppTapticImpactOccurred", { "style": "medium" }); setSlideIndexAge(slideIndexAge) }}
                                    style={{ height: 110 }}
                                >
                                    {ageArray.map((value, index) => {
                                        return <div key={index} className={slideIndexAge === index ? 'active' : ''}>{value}</div>
                                    })

                                    }
                                </Gallery>
                            </div>

                        </div>
                    </Div>
                    <FixedLayout vertical="bottom">
                        <Div>
                            <Button stretched size="l" style={{ marginBottom: 10 }} onClick={() => { setPanelAnswer("birthyear"); router.pushPage(PAGE_ONBOARDING_4) }}    >
                                Продолжить
                            </Button>
                        </Div>
                    </FixedLayout>
                </Panel>
                <Panel id={PANEL_ONBOARDING_4}>
                    <PanelHeader separator={false} left={<Icon28ChevronBack fill="00000" onClick={historyBack} style={{ marginLeft: 8 }} />}>
                        <FadeHeader />
                    </PanelHeader>
                    <Div style={{ paddingTop: 0 }}>
                        <div className="loadbar"><span className="active"></span><span className="active"></span><span className="active"></span><span></span><span></span> </div>
                        <div className="vertical-center align-top">
                            <div className="vertical-center-content ">
                                <Title level="1" weight="semibold" className=" text-centered" style={{ marginBottom: 28, marginTop: 15 }}>Какая длительность тренировок вам подходит?</Title>
                                <FormLayout style={{ width: '100%' }}>
                                    <Radio name="workout_duration_type" className="custom-radio" value="short" onChange={setAnswer}
                                        defaultChecked={answerArray["workout_duration_type"] === "short" ? true : false} > 20–30 минут</Radio>
                                    <Radio name="workout_duration_type" className="custom-radio" value="long" onChange={setAnswer}
                                        defaultChecked={answerArray["workout_duration_type"] === "long" ? true : false} > 30–45 минут</Radio>
                                    <Radio name="workout_duration_type" className="custom-radio" value="any" onChange={setAnswer}
                                        defaultChecked={answerArray["workout_duration_type"] === "any" ? true : false} > Любые</Radio>
                                </FormLayout>
                            </div>

                        </div>
                    </Div>
                    {(answerArray["workout_duration_type"] || answerArray["workout_duration_type"] === "") ? (
                        <FixedLayout vertical="bottom">
                            <Div>
                                <Button stretched size="l" style={{ marginBottom: 10 }} onClick={() => { setPanelAnswer("workout_duration_type"); router.pushPage(PAGE_ONBOARDING_5) }}    >
                                    Продолжить
                                </Button>
                            </Div>
                        </FixedLayout>
                    ) : ''
                    }
                </Panel>
                <Panel id={PANEL_ONBOARDING_5}>
                    <PanelHeader separator={false} left={<Icon28ChevronBack fill="00000" onClick={historyBack} style={{ marginLeft: 8 }} />} >
                        <FadeHeader />
                    </PanelHeader>
                    <Div style={{ paddingTop: 0 }}>
                        <div className="loadbar"><span className="active"></span><span className="active"></span><span className="active"></span><span className="active"></span><span ></span> </div>

                        <div className="vertical-center-content ">
                            <div className="vertical-center align-top">
                                <Title level="1" weight="semibold" className=" text-centered" style={{ marginBottom: 32, marginTop: 0 }}>Какие тренировки вы хотите выполнять?<br />
                                    <small style={{ color: '#76787A', fontSize: 16, fontWeight: 400, display: 'inline-block', lineHeight: '120%' }}>Можно выбрать любое количество направлений</small>
                                </Title>
                                <FormLayout style={{ width: '100%' }}>
                                    <Checkbox name="workout_parameter" className="custom-radio radio-big" value="strength" onClick={setAnswer}
                                        defaultChecked={answerArray["workout_parameter"]["strength"]}
                                    >Тренировки с собственным весом
                                        <span>Можно выполнять где и когда угодно. Задействуют все группы мышц.</span>
                                    </Checkbox>
                                    <Checkbox name="workout_parameter" className="custom-radio radio-big" value="cardio" onClick={setAnswer}
                                        defaultChecked={answerArray["workout_parameter"]["cardio"]}
                                    >Кардио
                                        <span>Аэробные тренировки, улучшающие работу сердца и сосудов.</span>
                                    </Checkbox>
                                    <Checkbox name="workout_parameter" className="custom-radio radio-big" value="dumbbells" onClick={setAnswer}
                                        defaultChecked={answerArray["workout_parameter"]["dumbbells"]}
                                    >Тренировки с гантелями
                                        <span>Силовые тренировки. Можно использовать бутылки с водой вместо гантелей.</span>
                                    </Checkbox>
                                    <Checkbox name="workout_parameter" className="custom-radio radio-big" value="mobility" onClick={setAnswer}
                                        defaultChecked={answerArray["workout_parameter"]["mobility"]}
                                    >Мобилити
                                        <span>Улучшают подвижность суставов и эластичность связок.</span>
                                    </Checkbox>
                                    <Checkbox name="workout_parameter" className="custom-radio radio-big" value="stretch" onClick={setAnswer}
                                        defaultChecked={answerArray["workout_parameter"]["stretch"]}
                                    >Растяжка
                                        <span>Рекомендуем чередовать с силовыми тренировками. Бонусы: гибкость, здоровая спина и лёгкость движений.</span>
                                    </Checkbox>
                                </FormLayout>
                            </div>

                        </div>
                    </Div>
                    {Object.keys(answerArray["workout_parameter"]).length ? (
                        <FixedLayout vertical="bottom">
                            <Div>
                                <Button stretched size="l" style={{ marginBottom: 10 }} onClick={() => { setPanelAnswer("workout_parameter"); router.pushPage(PAGE_ONBOARDING_FINISH) }}   >
                                    Продолжить
                                </Button>
                            </Div>
                        </FixedLayout>
                    ) : ''
                    }
                </Panel>
                <Panel id={PANEL_ONBOARDING_FINISH}>
                    <PanelHeader separator={false}  >

                    </PanelHeader>
                    <div className="vertical-center h-100">
                        <div className="vertical-center-content text-centered">
                            <div className="vertical-center align-top">
                                <div className="progressBarWrapper" style={{ margin: '1vh auto 0', width: 200, height: 200 }}>
                                    <CircularProgressbar
                                        value={procent}
                                        text={`${procent}%`}
                                        strokeWidth={4}
                                        styles={buildStyles({
                                            textColor: "#9062FF",
                                            pathColor: "#9062FF",
                                            trailColor: "#e0e0e0"
                                        })}
                                    />
                                </div>
                                <Title level="1" weight="semibold" style={{ marginBottom: 20, marginTop: 27 }}>Составляем программу <br /> тренировок для вас</Title>

                                <div className="textAnimated">
                                    <p className={(procent > 1) ? (procent <= 13 ? "active" : (procent > 37 ? "animate--Down" : "animate--Up")) : null}>
                                        Сортируем базу тренировок</p>
                                    <p className={(procent > 13) ? (procent <= 37 ? "active" : (procent > 55 ? "animate--Down" : "animate--Up")) : null}>
                                        Выбираем нужные направления</p>
                                    <p className={(procent > 37) ? (procent <= 55 ? "active" : (procent >= 100 ? "animate--Down" : "animate--Up")) : null}>
                                        Наполняем каталог тренировок</p>
                                    <p className={(procent > 55) ? (procent < 100 ? "active" : 'animate--Up') : null}>
                                        Рассчитываем индивидуальный план</p>
                                    <p className={(procent === 100) ? "active" : null}> Готово!</p>
                                </div>

                                {
                                    (procent === 100) ? (

                                        <FixedLayout vertical="bottom"  >
                                            <Div>
                                                {/* <Button stretched size="l" data-to="Paywall" onClick={() => { setPanelAnswer("finish"); changeMainNav({ page: PAGE_TRAINING, view: isPremium ? VIEW_TRAINING : VIEW_PAYWALL }) }} data-page={PAGE_PAYWALL} data-back={VIEW_TRAINING} data-backpage={PAGE_TRAINING} data-to={VIEW_PAYWALL} >
                                                    Поехали
                                                </Button> */}
                                                <Button
                                                    stretched
                                                    size="l"
                                                    data-to="Paywall"
                                                    onClick={() => {
                                                        setPanelAnswer("finish");
                                                        initSearch ?
                                                            paramsLinkParseAndRedirect(initSearch, changeMainNav)
                                                            : changeMainNav({
                                                                page: PAGE_TRAINING,
                                                                view: VIEW_TRAINING,
                                                                panel: initHash ? PANEL_TRAINING_CATALOG_SINGLE : null
                                                            })
                                                    }} >
                                                    Поехали
                                                </Button>
                                            </Div>
                                        </FixedLayout>
                                    ) : ''
                                }
                            </div>
                        </div>
                    </div>
                </Panel>
            </View>
        </>
    )
}

export default withRouter(Onboarding);
