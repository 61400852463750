import React, { } from 'react';
import './TrainingCatalogItemPreview.css';
import { SimpleCell } from '@vkontakte/vkui';
import { Icon16Lock } from '@vkontakte/icons';
import defaultTimePipe from '../pipes/defaultTimePipe';
import Skeleton from 'react-loading-skeleton';

const compls = {
    hard: 'Сложный уровень',
    medium: 'Средний уровень',
    easy: 'Легкий уровень',
}

const TrainingCatalogItemPreview = ({ data, setPanel, clickEvent, panelToFull, isFree }) => (
    <SimpleCell
        key={data ? data.id : null}
        className={"exerciseExample " + (!isFree && "closed")}
        multiline
        after={!isFree && <div className="lockWrap">  <Icon16Lock fill="white" width="14" /> </div>}
        onClick={() => { clickEvent ? clickEvent(panelToFull, { "trainingId": data.id }) : null }}
        data-to="trainingMainPanel"
        data-training_id={data ? data.id : null}
        description={data ? [defaultTimePipe(data.duration), `\n`, compls[data.complexity]] : [<Skeleton width={60} />, `\n`, <Skeleton width={80} />]}
        before={data ? <div className="trainingCatalogItemPreview" style={{ backgroundImage: `url(${data.preview})`, marginRight: 12 }} ></div> : <Skeleton width={142} height={80} style={{ marginRight: 12 }} />}>
        {data ? data.title : <Skeleton width={120} />}
    </SimpleCell>
)


export default TrainingCatalogItemPreview;
