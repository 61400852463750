import React from 'react';
import { ModalPage, withModalRootContext } from '@vkontakte/vkui';  
import Paywall from '../Paywall/Paywall';
import { MODAL_PAYWALL } from '../../routing/routes';

const PaywallModal = ({ activeModal, closeEvent, clickEvent, amplitudeEvent, source, successEvent }) => {  
    return (
        <ModalPage
            id={MODAL_PAYWALL}
            settlingHeight={100}
            dynamicContentHeight={true}
            onClose={closeEvent}
        >
            <Paywall clickEvent={clickEvent} source={source} closeEvent={closeEvent} amplitudeEvent={amplitudeEvent} successEvent={successEvent} />

        </ModalPage>
    );
}


export default withModalRootContext(PaywallModal);