import React from 'react';
import './SystemPanelError.css';
import { Headline, Title, Button, Div, FixedLayout, Panel, Text} from '@vkontakte/vkui';
import { PAGE_TRAINING, VIEW_TRAINING } from '../../routing/routes';
// import ImageOffline from './errorOffline';
import ImageCat from './errorCat';
import { Offline, Online } from '../tools/networkCheck';
import ImageVk from './errorVk';

const SystemPanelError = ({ setHomePanel, changeMainNav, error, goBack, fetchData, closeApp }) => {
    return (
        <Panel id="panelError">
            <Offline>
                <Div style={{ textAlign: 'center' }} className="flexVerticalCenter">
                    <Title level="2" weight="bold">Нет доступа к сети </Title>
                    <Headline weight="regular" className="textGrey" style={{ margin: '8px 0 24px' }}>Проверьте подключение к интернету и попробуйте снова</Headline>
                </Div>
                <FixedLayout vertical="bottom" style={{ background: 'transparent' }}>
                    <Div  >
                        <Button stretched size="m" onClick={() => changeMainNav({ page: PAGE_TRAINING, view: VIEW_TRAINING, error: true })} data-to="Training">Попробовать еще</Button>
                    </Div>
                </FixedLayout>
            </Offline>

            <Online>
                <Div style={{ textAlign: 'center' }} className="flexVerticalCenter">
                    {(error && error.errorStatus === "Network Error") ?
                        ''
                        :
                        (error.errorStatus === "not_permissions" ? <ImageVk /> : <ImageCat />)

                    }
                    <Title level="2" weight="bold">
                        {error && error.errorStatus === "Network Error" ? 'Нет доступа к сети' :
                            (error.errorStatus === "not_permissions" ? 'Отказ в доступах' : 'Что-то сломалось')
                        }
                    </Title>
                    <Headline weight="regular" className="textGrey" style={{ margin: '8px 0 24px' }}>
                        {error && error.errorStatus === "Network Error" ? 'Проверьте подключение к интернету и попробуйте снова' :
                            (error.errorStatus === "not_permissions" ? 'Приложению нужны доступы к основной информации' : 'Мы работаем над решением проблемы')}</Headline>
                    <Text>{error && error.errorText ? error.errorText : ''}</Text>
                </Div>
                <FixedLayout vertical="bottom" style={{ background: 'transparent' }}>
                    <Div  >
                        {error && error.errorStatus !== 'not_permissions' ?
                            <Button stretched size="m" onClick={() => changeMainNav({ page: PAGE_TRAINING, view: VIEW_TRAINING, error: true })} data-to="Training">
                                {error.errorStatus === "Network Error" ? 'Попробовать еще' : 'Повторить'}</Button>
                            :
                            <>
                                <Button stretched size="m" mode="destructive" onClick={() => closeApp()} style={{ marginBottom: 10 }}>Выход</Button>
                                <Button stretched size="m" onClick={() => { changeMainNav({ page: PAGE_TRAINING, view: VIEW_TRAINING, error: true }) }} data-to="Training">Дать разрешения</Button>

                            </>
                        }
                    </Div>
                </FixedLayout>
            </Online>
        </Panel>
    )
}


export default SystemPanelError;