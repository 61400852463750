import axiosApi from './axiosApi';
import { apiBaseUri } from '../environment';  
 

export default class CatalogService  { 
	

	getArticle(id) { 
		return  axiosApi.get(`${apiBaseUri}/article`+(id ? '/'+id : ''))
	}
	getFaq() { 
		return  axiosApi.get(`${apiBaseUri}/faq`)
	}
	getAdvice() { 
		return  axiosApi.get(`${apiBaseUri}/user/advice`)
	}
 
}
