import React  from 'react';
import './TrainingPreview.css';
import { Button, Div, Title } from '@vkontakte/vkui';  
import { Icon28SyncOutline } from '@vkontakte/icons'; 
import defaultTimePipe from '../pipes/defaultTimePipe' 
import { PAGE_PAYWALL, PAGE_TRAINING, PAGE_TRAINING_MAIN, VIEW_PAYWALL, VIEW_TRAINING } from '../../routing/routes';
import Skeleton from 'react-loading-skeleton';




const TrainingPreview = ({data, showButton, fullView = true, sharingFrame = false, buttonEvent, optionEvent }) => {
    return (
        <Div className={fullView && "fullView"} style={{ paddingTop: 0 }}>
            <div id="bigPreview" className={sharingFrame ? "trainingImageWrap sharingFrame" : "trainingImageWrap"} >
                {data ? (
                    <>
                        <div className="trainingImage" style={{ backgroundImage: `url(${data.preview})` }}>
                            <div className="trainingImageShadow"></div>
                        </div>
                        <div className="float flexBox counterBox">
                            {(data.task.duration > 0 && (fullView || sharingFrame)) && (
                                <span className="previewCounter"> {defaultTimePipe(data.task.duration )} 
                                </span>
                            )}
                            {data.calories > 0 && (
                                <span className="previewCounter">{data.calories} Ккал</span>
                            )}
                        </div>
                        <div className="trainingWrapBottom">
                            <Title level="1" weight="semibold" style={{ color: 'white' }}>{data.title}</Title>
                            {showButton && (
                                <div className="flexBox" style={{marginTop: 12}}>
                                    <Button mode="overlay_primary" className="previewBtn" style={{padding:'0 5px', flexGrow: 1, marginRight: 12, letterSpacing: 0.4, maxWidth: 'calc(100% - 58px)' }} onClick={() => buttonEvent(PAGE_TRAINING_MAIN, { trainingId: data.id })} data-to="trainingMainPanel" size="l" >Начать тренировку дня</Button>
                                    <Button mode="overlay_primary" className="previewBtn changeWorkout" onClick={optionEvent} data-backpage={PAGE_TRAINING} data-to={VIEW_PAYWALL} data-page={PAGE_PAYWALL} data-back={VIEW_TRAINING} size="l" style={{ paddingLeft: 0, paddingRight: 0, width: 46 }}> <Icon28SyncOutline width={30} fill={'black'} /> </Button>
                                </div>
                            )}
                        </div>
                    </>
                ) :
                    <Skeleton height={'100%'} />
                }
            </div>
        </Div>
    )

}


export default TrainingPreview;