 
import React, { Component } from 'react';

const  LevelSvg = (appearance) => {

    return (
      <div className="LevelSvg">
        <svg width="12" height="11" viewBox="0 0 12 11" fill={appearance ? appearance : 'null'} height="10" style={{ marginLeft: 2 }} xmlns="http://www.w3.org/2000/svg">
          <rect y="6" width="3" height="5" rx="1.5" fill="#99A2AD" />
          <rect x="4.5" y="3" width="3" height="8" rx="1.5" fill="#99A2AD" />
          <rect x="9" width="3" height="11" rx="1.5" fill="#99A2AD" />
        </svg> 
      </div>
    );
  
}

export default LevelSvg