import React, { } from 'react';
import { Div, Title, Group, CardScroll } from '@vkontakte/vkui';
import TrainingCard from '../TrainingCard/TrainingCard';
import './TrainingCardsBlock.css';
import { Icon16ChevronOutline } from '@vkontakte/icons';
import Skeleton from 'react-loading-skeleton';
import { MODAL_PAYWALL } from '../../routing/routes';


const TrainingCardsBlock = ({ title, setPanel, panelToSingle = null, panelToFull = null, id, list, keycatalog, groupId, clickEvent, isFree = true, optionalEvent, source, skeleton = false, amplitudeEvent }) => (
    <Group className="customGroup" separator="hide" >
        <Div style={{ paddingTop: 0 }}>
            {!title ? <Title className="titleFlexBox" style={{ marginBottom: 0 }} level="3"> <Skeleton width={200} /> </Title> :
                <Title className="titleFlexBox" level="3" weight="semibold" style={{ marginBottom: 0 }}
                    onClick={
                        () => {
                            (groupId && amplitudeEvent("workout_group", { "group_id": groupId }));
                            (panelToFull && clickEvent(panelToFull, keycatalog && { keycatalog: keycatalog }));
                        }
                    }
                    data-to={panelToFull}
                    data-catalogkey={keycatalog} > {title}
                    {panelToFull && (
                        <>
                            <Icon16ChevronOutline fill="#B8C1CC" />
                        </>
                    )}
                </Title>
            }
        </Div>
        <Group >
            <CardScroll size="m" className="customCardScroll">
                {!skeleton ?
                    list.map((item, index) => {
                        return <TrainingCard source={source} key={index} isFree={isFree} clickEvent={panelToSingle && (isFree ? clickEvent : () => optionalEvent(MODAL_PAYWALL ,{nextPageId: item.id}))} panelToSingle={panelToSingle} data={item} />
                    })
                    :
                    <>
                        <TrainingCard skeleton={true} />
                        <TrainingCard skeleton={true} />
                        <TrainingCard skeleton={true} />
                    </>
                }
            </CardScroll>
        </Group>
    </Group>
)


export default TrainingCardsBlock;