
import React, { Component } from 'react';

const DumbbellsSvg = (appearance) => {

  return (
    <div className="DumbbellsSvg">
      <svg width="13" height="7" style={{ marginLeft: 2 }} viewBox="0 0 13 8" fill={appearance ? appearance : 'none'} xmlns="http://www.w3.org/2000/svg">
        <rect y="1" width="2" height="6" rx="1" fill="#99A2AD" />
        <rect x="2" width="2" height="8" rx="1" fill="#99A2AD" />
        <rect x="10" y="3" width="2" height="8" transform="rotate(90 10 3)" fill="#99A2AD" />
        <rect x="11" y="1" width="2" height="6" rx="1" fill="#99A2AD" />
        <rect x="9" width="2" height="8" rx="1" fill="#99A2AD" />
      </svg>

    </div>
  );

}

export default DumbbellsSvg