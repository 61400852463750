import React from 'react';
import { Panel, PanelHeaderBack, PanelHeader, FixedLayout, Div, Title, Group, Text } from '@vkontakte/vkui';
import { withRouter } from '@happysanta/router';
import level1 from '../../img/levels/level1.svg';
import level2 from '../../img/levels/level2.svg';
import level3 from '../../img/levels/level3.svg';
import level4 from '../../img/levels/level4.svg';
import level5 from '../../img/levels/level5.svg';
import level6 from '../../img/levels/level6.svg';
import level7 from '../../img/levels/level7.svg';
import level8 from '../../img/levels/level8.svg';
import level9 from '../../img/levels/level9.svg';
import level10 from '../../img/levels/level10.svg';
import level11 from '../../img/levels/level11.svg';
import level12 from '../../img/levels/level12.svg';
import timePipe from '../../components/pipes/timePipe';

const ProgressPanel = ({ vkUserData, goBack, progressData, scoreTotal }) => {

    const levelCup = {
        "level_1": { "img": level1, "title": "Новичок", "description": "", "active": (scoreTotal >= 0) },
        "level_2": { "img": level2, "title": "Первые шаги", "description": "1 час", "active": (scoreTotal >= 60) },
        "level_3": { "img": level3, "title": "Подающий надежды", "description": "2 часа", "active": (scoreTotal >= 60 * 2) },
        "level_4": { "img": level4, "title": "Любимчик тренера", "description": "3 часа", "active": (scoreTotal >= 60 * 3) },
        "level_5": { "img": level5, "title": "Звезда коврика", "description": "5 часов", "active": (scoreTotal >= 60 * 5) },
        "level_6": { "img": level6, "title": "Без тормозов", "description": "10 часов", "active": (scoreTotal >= 60 * 10) },
        "level_7": { "img": level8, "title": "Энерджайзер", "description": "20 часов", "active": (scoreTotal >= 60 * 20) },
        "level_8": { "img": level7, "title": "Пример для других", "description": "40 часов", "active": (scoreTotal >= 60 * 40) },
        "level_9": { "img": level9, "title": "Чёрный пояс", "description": "80 часов", "active": (scoreTotal >= 60 * 80) },
        "level_10": { "img": level10, "title": "Сенсей", "description": "200 часов", "active": (scoreTotal >= 60 * 200) },
        "level_11": { "img": level11, "title": "Лучший из лучших", "description": "300 часов", "active": (scoreTotal >= 60 * 300) },
        "level_12": { "img": level12, "title": "Камень бесконечности", "description": "400 часов", "active": (scoreTotal >= 60 * 400) },
    }

    return (
        <Panel id="progressPanel" className="p-b-60">
            <PanelHeader separator={false} className="shadow-bottom" left={<PanelHeaderBack onClick={goBack} />} >Уровни</PanelHeader>
            {
                progressData ? (
                    <>
                        <Div className='trackWrap' style={{}}>
                            {
                                Object.entries(levelCup).map(([key, value], i) => {
                                    return (
                                        <div className={'trackItem flexBox'
                                            + (value.active ? '' : ' disabled')
                                            + (levelCup['level_' + (i + 2)] ? (levelCup['level_' + (i + 2)].active ? ' track-active' : '') : '')}
                                            key={i}>
                                            <div className="trackItemImg"> <img src={value.img} alt={value.title} /></div>
                                            <div>
                                                <Title className={value.active ? "" : "greyText"} weight="semibold" level="2">{value.title}</Title>
                                                {value.description ? <Text className="greyText" >{value.description}</Text> : ''}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {/* <Avatar className="avatarOnTrack" src={vkUserData.photo_200} /> */}
                            {/* <img src={progressTrack} width="100%" style={{ width: '100%' }} /> */}


                        </Div>

                        {
                            progressData.next_rank ?
                                <FixedLayout vertical="bottom" className="background-transparent" style={{ paddingLeft: 8, paddingRight: 6, marginBottom: 16, background: document.body.getAttribute('scheme') === 'space_gray' ? '#0a0a0a' : '' }}>
                                    <Group className="customGroup" separator="hide" style={{ marginBottom: 0 }} >
                                        <Div style={{ paddingTop: 6, paddingBottom: 8 }}>
                                            <Text level="1" style={{ color: '#99A2AD', letterSpacing: 0.4 }}>до следующего уровня   </Text>
                                            <Title level="2" weight="semibold" style={{ color: '#9062FF', marginTop: 3 }} > {progressData.next_rank ? timePipe(progressData.next_rank.score - scoreTotal) + ' активности' : 'ещё немого..'} </Title>
                                        </Div>
                                    </Group>
                                </FixedLayout>
                                : ''
                        }
                    </>
                ) : ''
            }
        </Panel>
    )
}
export default withRouter(ProgressPanel);
