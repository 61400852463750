import React, { } from 'react';
import {  Div, Text,
    ModalPage,
    ModalPageHeader,
    PanelHeaderButton,
} from '@vkontakte/vkui';
import {  Icon24Dismiss } from '@vkontakte/icons';

const SimpleModal = ({ title, content, id, closeEvent }) => {
    return (
        <ModalPage
            id={id}
            onClose={closeEvent}
            dynamicContentHeight={true}
            header={
                <ModalPageHeader
                    right={<PanelHeaderButton onClick={closeEvent}><Icon24Dismiss /></PanelHeaderButton>}
                >
                 {title}
                </ModalPageHeader>
            }
        >
            <Div style={{ backgroundColor: 'inherit', paddingTop: 4, position: 'relative' }}>
                <Text className="textGrey scrollWrap h-70">
                    <div className="" dangerouslySetInnerHTML={{ __html: content }}></div>
                </Text>
            </Div>
        </ModalPage>
    );
}


export default SimpleModal;