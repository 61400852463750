import './AppLoading.css';
import React, { Component } from 'react';
import { Spinner } from '@vkontakte/vkui';

class AppLoading extends Component {

  render() {
    return (
      <div className="AppLoading">
        <Spinner size="large"   />
      </div>
    );
  }
}

export default AppLoading;
