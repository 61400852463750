import axiosApi from './axiosApi';
import { apiBaseUri } from '../environment';


export default class TestingService {
	getTest(id) {
		return axiosApi.get(`${apiBaseUri}/test/${id}`)
	}

	sendAnswers(id, data) {
		return axiosApi.post(`${apiBaseUri}/test/${id}`, data)
	}


}
