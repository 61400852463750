import React, { useState, useEffect, useRef } from 'react';
import '@vkontakte/vkui/dist/vkui.css';
import { View, Panel, Group, ActionSheet, Text, Title, Button, Div, PanelHeader, RichCell, PanelHeaderBack, HorizontalScroll, useAppearance, Headline, ScreenSpinner, ModalRoot, ModalPage, ModalPageHeader, FixedLayout, Snackbar } from '@vkontakte/vkui';
import './Testing.css';
import { useLocation, useParams, useRouter, withRouter } from '@happysanta/router';
import { MODAL_INTEST, PAGE_CATALOG, PAGE_CATALOG_ARTICLE, PAGE_TESTING, PAGE_TRAINING, PAGE_TRAINING_MAIN, PANEL_TESTING, PANEL_TESTING_FINISH, PANEL_TESTING_SLIDE, PANEL_TESTING_START, VIEW_CATALOG, VIEW_TESTING, VIEW_TRAINING } from '../../routing/routes';
import TestingService from '../../services/TestingService';
import Skeleton from 'react-loading-skeleton';
import TestingSlide from './TestingSlide';
import { checkLink, getIdFromLink } from '../../components/tools/linkCheck';
import { Icon16ErrorCircleFill } from '@vkontakte/icons';
import { disableSystemSwipeBack } from '../../components/modules/AppModule';


const Testing = ({ viewId, changeMainNav, history, goBack, activePanel, amplitudeEvent, setErrorView, closeApp, onSwipeBack, testingData }) => {
    const router = useRouter();
    const location = useLocation();
    const { testId, slideId } = useParams();
    const [mainColor, setMainColor] = useState(null)
    const [modalData, setModalData] = useState(null)
    const [answerArray, setAnswerArray] = useState({})
    const [popoutParam, setPopoutParam] = useState(null);
    const [secondColor, setSecondColor] = useState(null);
    const [localHistory, setLocalHistory] = useState([testingData.start_screen])
    const [currentSlide, setCurrentSlide] = useState('start')
    const [snackbarState, setSnackbarState] = useState(null)
    const [slideIndex, setSlideIndex] = useState(0)
    const testingService = new TestingService();
    const appearance = useAppearance();
    useEffect(() => {
        amplitudeEvent("test_start", { "test_id": testingData.id })
        setMainColor(appearance === 'light' ? testingData.palette.light.main : testingData.palette.dark.main)
        setSecondColor(appearance === 'light' ? testingData.palette.light.additional : testingData.palette.dark.additional)

    }, [])

    const popout = (() => {
        if (popoutParam === 'loading') return <ScreenSpinner />
    })();

    window.onhashchange = function () {
        if (location.state.history.length !== localHistory.length) {
            console.log('onhashchange')
            setSlideIndex(slideIndex => slideIndex - 1)
        }
    }

    const historyBack = () => {
        let newHistory = localHistory;
        newHistory.pop();
        setLocalHistory(newHistory)
        if (newHistory.length < 1)
            changeMainNav({ view: VIEW_TRAINING })
        else
            router.popPage()
        setSlideIndex(slideIndex => slideIndex - 1)
    }

    const setAnswer = (e) => {
        const answer = e.currentTarget;
        let newAnswer = answerArray;
        newAnswer[answer.name] = +answer.value;
        setAnswerArray({ ...newAnswer })
    }

    const getSlideData = (id) => {
        return testingData['screens'].find(item => item.id === +id)
    }


    const nextSlide = (nextSlideId, isModal) => {
        disableSystemSwipeBack()
        if (nextSlideId === 0 || !nextSlideId) {
            nextSlideId = 'finish';
            setPopoutParam('loading')
            try {
                testingService.sendAnswers(testId, { "answers": answerArray }).then((data) => {
                    console.log('testingService', data)
                    if (data.status === 200) {
                        setTimeout(() => {
                            if (isModal) {
                                router.popPage()
                            }
                            setTimeout(() => {
                                window.scrollTo(0, 0)
                                let newHistory = localHistory;
                                newHistory.push(nextSlideId);
                                setLocalHistory(newHistory)
                                setCurrentSlide(nextSlideId)
                                router.pushPage(PAGE_TESTING, { testId: testId, slideId: nextSlideId.toString() })
                                setSlideIndex(slideIndex => slideIndex + 1)
                            }, isModal ? 100 : 0);
                        }, 200)
                    } else {
                        showError()
                    }
                    setPopoutParam(null)

                }).catch((error) => {
                    setPopoutParam(null)
                    console.log(error)
                    showError()
                })
            } catch {
                setPopoutParam(null)
                showError()
            }
        } else {
            if (isModal) {
                router.popPage()
            }
            setTimeout(() => {
                window.scrollTo(0, 0)
                let newHistory = localHistory;
                newHistory.push(nextSlideId);
                setLocalHistory(newHistory)
                setCurrentSlide(nextSlideId)
                router.pushPage(PAGE_TESTING, { testId: testId, slideId: nextSlideId.toString() })
                setSlideIndex(slideIndex => slideIndex + 1)
            }, isModal ? 100 : 0);
        }
    }

    const finishTest = (prop) => {
        if (prop !== 'exit') {
            const link = checkLink(testingData.final_screen.link)
            amplitudeEvent("test_finished", { "go_to_link": true })
            if (link === 'article') {
                changeMainNav({ view: VIEW_CATALOG, page: PAGE_CATALOG })
                setTimeout(() => {
                    router.replacePage(PAGE_CATALOG_ARTICLE, { id: getIdFromLink(testingData.final_screen.link) })
                }, 200)
            }
            if (link === 'training') {
                changeMainNav({ view: VIEW_TRAINING, page: PAGE_TRAINING })
                setTimeout(() => {
                    router.replacePage(PAGE_TRAINING_MAIN, { id: getIdFromLink(testingData.final_screen.link) })
                }, 200)
            }
        } else {
            amplitudeEvent("test_finished", { "go_to_link": false });
            changeMainNav({ view: VIEW_TRAINING })
        }
    }

    const showError = () => {
        setSnackbarState(
            <Snackbar
                className="snackbar--error"
                onClose={() => setSnackbarState(null)}
                before={<Icon16ErrorCircleFill />}
            > Ошибка сети. Попробуйте снова </Snackbar>
        )
    }

    const modal = (
        <ModalRoot
            activeModal={location.getModalId()}
            onClose={() => { router.popPage() }}
        >
            <ModalPage
                id={MODAL_INTEST}
                onClose={() => router.popPage()}
                settlingHeight={100}
                dynamicContentHeight={true}
                header={
                    <ModalPageHeader
                        left={<PanelHeaderBack onClick={() => router.popPage()} />}
                    >{testingData ? testingData.title : 'Тестирование'}
                    </ModalPageHeader>
                }
            >
                {
                    modalData ? (
                        <>
                            <Div style={{ backgroundColor: 'inherit', paddingTop: 4, position: 'relative' }}>
                                <div className="vertical-center">
                                    <div className="vertical-center-content text-centered">
                                        {modalData.image ? <div style={{ backgroundImage: `url(${modalData.image})` }} className="testing__modal__image"></div> : ''}
                                        <Title level="1" weight="semibold" style={{ marginBottom: 12, marginTop: 20, fontSize: 24 }}>{modalData.title}</Title>
                                        <Text className="text-grey" style={{ marginBottom: 16 }}><div className="testingDescrWrap" dangerouslySetInnerHTML={{ __html: modalData.description }}></div></Text>
                                    </div>
                                </div>
                            </Div>
                            <FixedLayout vertical="bottom" className={"testingFixedLayout"}>
                                <Div>
                                    <Button stretched size="l" style={{ marginBottom: 10, background: mainColor, color: 'white' }} onClick={() => nextSlide(modalData.next, 'modal')} >
                                        Продолжить
                                    </Button>
                                </Div>
                            </FixedLayout>
                        </>
                    ) : ''
                }
                {snackbarState}
            </ModalPage>

        </ModalRoot>
    )


    const openModal = (data) => {
        setModalData(data)
        router.pushModal(MODAL_INTEST);
    }
    const getPanelProps = (panelId) => {
        return {
            id: panelId,
            openModal: openModal,
            goBack: () => historyBack(),
            nextSlide: nextSlide,
            setErrorView: setErrorView,
            history: history,
            amplitudeEvent: amplitudeEvent,
            setAnswer: setAnswer,
            answerArray: answerArray,
            getSlideData: getSlideData,
            slideId: slideId,
            testingData: testingData,
            currentSlide: currentSlide,
            mainColor: mainColor,
            secondColor: secondColor,
            // slideData: getSlideData(3)
        };
    };


    return (
        <View
            history={localHistory}
            id={viewId}
            popout={popout}
            modal={modal}
            activePanel={!location.route.params.slideId ? PANEL_TESTING
                : (location.route.params.slideId === 'start' || location.route.params.slideId === 'finish' ? location.route.params.slideId
                    : +location.route.params.slideId)}
            onSwipeBack={() => historyBack()}>
            <Panel id={PANEL_TESTING} >
                <PanelHeader separator={false} left={<PanelHeaderBack onClick={() => changeMainNav({ view: VIEW_TRAINING })} />}>{testingData ? testingData.title : "Тестирование"}</PanelHeader>
                {
                    (testingData) ?
                        (
                            <div className="vertical-center">
                                <div className="vertical-center-content text-centered">
                                    <Title level="1" weight="semibold" style={{ marginBottom: 14, marginTop: 20, fontSize: 30 }}>{testingData.entry_screen ? testingData.entry_screen.title : testingData.title}</Title>
                                    {testingData.entry_screen ? <Headline weight="regular" className="text-grey" style={{ marginBottom: 10 }}>{testingData.entry_screen.description}</Headline> : ''}
                                    <Button size="l" style={{ marginTop: 10, background: mainColor }}
                                        onClick={() => router.pushPage(PAGE_TESTING, { testId: testId, slideId: testingData.start_screen })} >Пройти тестирование</Button>
                                </div>
                            </div>)
                        : ''

                }
            </Panel>
            {
                testingData && testingData['screens'].length &&
                testingData["screens"].map((item, i) => {
                    return (<TestingSlide key={i} {...getPanelProps(item.id)} slideData={getSlideData(item.id)} slideIndex={slideIndex} />)
                })
            }

            <Panel id={"finish"}>
                <PanelHeader separator={false} left={<PanelHeaderBack onClick={() => historyBack()} />}>{testingData ? testingData.title : "Тестирование"}</PanelHeader>
                {
                    (testingData) ?
                        (
                            <>
                                <Div className="testing__slide__wrap">
                                    <div className="vertical-center">
                                        <div className="vertical-center-content text-centered">
                                            {testingData.final_screen.image ? <div style={{ backgroundImage: `url(${testingData.final_screen.image})` }} className="testing__modal__image"></div> : ''}
                                            <Title level="1" weight="semibold" style={{ marginBottom: 12, marginTop: 20, fontSize: 24 }}>{testingData.final_screen.title}</Title>
                                            <Text className="text-grey" style={{ marginBottom: 16 }}><div className="testingDescrWrap" dangerouslySetInnerHTML={{ __html: testingData.final_screen.description }}></div></Text>
                                        </div>
                                    </div>
                                </Div>
                                <FixedLayout vertical="bottom" className={"testingFixedLayout"}>
                                    <Div>
                                        {testingData.final_screen.link ?
                                            (checkLink(testingData.final_screen.link) === 'external' ?
                                                <a href={testingData.final_screen.link} target="_blank" style={{ textDecoration: 'none' }}>
                                                    <Button stretched size="l" style={{ marginBottom: 10, background: mainColor, color: 'white' }}
                                                        onClick={() => finishTest()} >Перейти на сайт</Button>
                                                </a>
                                                :
                                                <Button stretched size="l" style={{ marginBottom: 10, background: mainColor, color: 'white' }}
                                                    onClick={() => finishTest()} >
                                                    {
                                                        {
                                                            training: `Открыть тренировку`,
                                                            article: `Читать статью`,
                                                        }[checkLink(testingData.final_screen.link)]
                                                    }
                                                </Button>)
                                            : ""
                                        }
                                        <Button stretched mode="tertiary" size="l" style={{ color: mainColor }} onClick={() => finishTest('exit')} >
                                            Завершить тест
                                        </Button>
                                    </Div>
                                </FixedLayout>
                            </>) : ''

                }

                {snackbarState}
            </Panel>

        </View >
    )

}

export default withRouter(Testing);
