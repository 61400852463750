export function checkLink(link) {
    if (link.indexOf('inapp://') !== -1) {
        if (link.indexOf('/article/') !== -1) {
            return 'article'
        }
        if (link.indexOf('/training/') !== -1) {
            return 'training'
        }
    } else return 'external'
}



export function getIdFromLink(link) {
    const PREFIX = '/' + checkLink(link) + '/';
    return link.slice(link.indexOf(PREFIX) + PREFIX.length)
}
