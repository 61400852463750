import React from 'react';
import { Panel, PanelHeaderBack, Title, PanelHeader, Group, Avatar, RichCell, Header, Div, Text, Button } from '@vkontakte/vkui';
import { Icon16ErrorCircleFill, Icon24ChevronCompactRight } from '@vkontakte/icons';
import Skeleton from 'react-loading-skeleton';
import { app_id, isLocalDev } from '../../environment';
import diamondSvg from '../../img/diamond_outline.svg';
import { MODAL_PAYWALL, VIEW_PAYWALL, PAGE_PAYWALL, VIEW_PROFILE, PAGE_PROFILE } from '../../routing/routes';
import { withRouter } from '@happysanta/router';

const SettingsPanel = ({ vkUserData, openPopup, userAccount, slideIndexAge, goBack, openModal, isPremium }) => {
    const gendersType = {
        "woman": "Женский",
        "man": "Мужской"
    }
    const workoutDurationType = {
        "short": "Короткие",
        "long": "Длинные",
        "any": 'Любая'
    }
    const workoutType = {
        "cardio": "кардио",
        "dumbbells": "с гантелями",
        "mobility": "мобилити",
        "strength": "с собственным весом",
        "stretch": "растяжка",
    }

    const getWorkoutArray = (object, lang) => {
        let stringArr = []; 
        if (object) {
            Object.entries(object).forEach(([key, value]) => { if (value !== null) stringArr.push(key) })
            return stringArr.map(item => { return lang ? workoutType[item] : item }).join(', ');
        }
    }

    return (
        <Panel id="settingsPanel">
            <PanelHeader left={<PanelHeaderBack onClick={goBack} data-to="profile" />} >Настройки</PanelHeader>
            <Group className="customGroup" separator="hide"  >
                <RichCell
                    className="profileHeader"
                    disabled
                    multiline
                    before={vkUserData && vkUserData.photo_200 ? <Avatar style={{ padding: 0 }} size={60} src={vkUserData.photo_200} /> : <Skeleton circle={true} width={68} height={68} style={{ margin: '8px 18px 8px 0' }} />}
                >

                    <Title level="3" weight="semibold"  >{vkUserData && vkUserData.first_name ? `${vkUserData.first_name} ${vkUserData.last_name}` : <Skeleton width={200} />}</Title>
                </RichCell>

            </Group>

            <Group className="customGroup" separator="hide"  >
                <RichCell
                    onClick={userAccount.id && openPopup}
                    data-popup="birthyear"
                    data-option={userAccount.id && userAccount.birthyear}
                    data-slide={slideIndexAge}
                    className="settingsCell"
                    caption={userAccount.id ? userAccount.birthyear : <Skeleton width={70} />}
                    multiline
                    after={userAccount.id && <Icon24ChevronCompactRight fill={'#C4C8CC'}></Icon24ChevronCompactRight>}
                > {userAccount.id ? 'Год рождения' : <Skeleton width={120} />}
                </RichCell>
                <RichCell
                    onClick={userAccount.id && openPopup}
                    data-popup="gender"
                    data-option={userAccount.id && userAccount.gender}
                    className="settingsCell"
                    caption={userAccount.id ? gendersType[userAccount.gender] : <Skeleton width={90} />}
                    multiline
                    after={userAccount.id && <Icon24ChevronCompactRight fill={'#C4C8CC'}></Icon24ChevronCompactRight>}
                > {userAccount.id ? 'Ваш пол' : <Skeleton width={100} />}
                </RichCell>
            </Group>


            <Group className="customGroup" separator="hide"  >
                <RichCell
                    onClick={userAccount.id && openPopup}
                    data-popup="workout_parameter"
                    data-option={userAccount.workout_parameter ? getWorkoutArray(userAccount.workout_parameter) : ""}
                    className="settingsCell"
                    caption={userAccount.id ? (getWorkoutArray(userAccount.workout_parameter, 'rus') ? getWorkoutArray(userAccount.workout_parameter, 'rus') :  
                        <span className="error" style={{display: 'flex', color: 'red', fontSize: 11}}><Icon16ErrorCircleFill width={12} style={{marginRight: 4}} /> Не выбрано</span>) : <Skeleton width={200} />}
                    multiline
                    after={userAccount.id && <Icon24ChevronCompactRight fill={'#C4C8CC'}></Icon24ChevronCompactRight>}
                > {userAccount.id ? 'Типы тренировок' : <Skeleton width={130} />}
                </RichCell>
                <RichCell
                    onClick={userAccount.id && openPopup}
                    data-popup="workout_duration_type"
                    data-option={userAccount.workout_duration_type || 'any'}
                    className="settingsCell"
                    caption={userAccount.id ? (workoutDurationType[userAccount.workout_duration_type] ? workoutDurationType[userAccount.workout_duration_type] : 'Любая') : <Skeleton width={90} />}
                    multiline
                    after={userAccount.id && <Icon24ChevronCompactRight fill={'#C4C8CC'}></Icon24ChevronCompactRight>}
                > {userAccount.id ? 'Длительность тренировок' : <Skeleton width={150} />}
                </RichCell>
            </Group>
            {( app_id === 7914181  ?
                <Group className="customGroup" separator="hide" header={<Header style={{ fontSize: 17, fontWeight: 600 }}>Больше возможностей с Premium</Header>}    >
                    <Div style={{ paddingTop: 0 }}>
                        <div style={{ textAlign: 'center' }}>
                            <embed type="image/svg+xml" src={diamondSvg} /> </div>
                        <Text style={{ color: '#99A2AD' }}>Оформите подписку, чтобы получить доступ ко всем тренировкам, составить индивидуальный план занятий и советоваться с тренером</Text>
                        <Button stretched size="l" style={{ marginTop: 15 }} onClick={() => openModal(MODAL_PAYWALL)} data-to={VIEW_PAYWALL} data-page={PAGE_PAYWALL} data-back={VIEW_PROFILE} data-backpage={PAGE_PROFILE}    >Попробовать</Button>
                    </Div>
                </Group>
                : '')}

            <Group className="customGroup no-padding" separator="hide"  >
                <a href="https://vk.com/im?sel=-207125992" style={{ textDecoration: 'none' }}>
                    <RichCell
                        className="settingsCell"
                        after={<Icon24ChevronCompactRight fill={'#C4C8CC'}></Icon24ChevronCompactRight>}
                    > Написать в техподдержку
                    </RichCell>
                </a>
            </Group>

            <Group className="customGroup no-padding" separator="hide"  >
                <a href="https://vk.com/public207167398" style={{ textDecoration: 'none' }}>
                    <RichCell
                        className="settingsCell"
                        after={<Icon24ChevronCompactRight fill={'#C4C8CC'}></Icon24ChevronCompactRight>}
                    > Наше сообщество
                    </RichCell>
                </a>
            </Group>


            {(app_id === 7914181 || app_id === 7902415 || isLocalDev) ?
                <Group className="customGroup no-padding" separator="hide"  >
                    <RichCell
                        caption="1.0.2912 (dev)"
                        className="settingsCell" 
                    > Версия приложения
                    </RichCell>
                </Group>
                : ''}

        </Panel>
    )
}
export default withRouter(SettingsPanel);
