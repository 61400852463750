import { useLocation, useRouter, withRouter } from '@happysanta/router';
import { Button, Div, FixedLayout, Panel, PanelHeader, PanelHeaderBack, Title, Group } from '@vkontakte/vkui';
import React, { useEffect, useState } from 'react';
import ExercisePreview from '../../../components/ExercisePreview/ExercisePreview';
import getErrorStatus from '../../../components/tools/errorStatus';
import TrainingDescription from '../../../components/TrainingDescription/TrainingDescription';
import TrainingPreview from '../../../components/TrainingPreview/TrainingPreview';
import bridge from '@vkontakte/vk-bridge';
import { isLocalDev } from '../../../environment';
import { MODAL_TRAINING_EXAMPLE, MODAL_TRAINING_EXIT, PAGE_PAYWALL, PAGE_TRAINING, PAGE_TRAINING_CATALOG, PAGE_TRAINING_COMPLETE, VIEW_PAYWALL, VIEW_TRAINING } from '../../../routing/routes';
import TrainingService from '../../../services/TrainingService';
import './TrainingMainPanel.css';

const TrainingMainPanel = ({ id, setPanel, openPopout, openEndWorkoutPopout, setTraining, goBack, nextPage, setErrorView, dayTraining, trainingId, amplitudeEvent, finishTraining }) => {
    const location = useLocation();
    const router = useRouter();
    const [currentTraining, setCurrentTraining] = useState(null);
    const [trainingComplete, setTrainingComplete] = useState(false)
    const [videoDutaion, setVideoDuration] = useState(100)
    const [trainingCompleteState, setTrainingCompleteState] = useState(0)
    const [videoState, setVideoState] = useState('onstart');
    const trainingService = new TrainingService();
    const trainingCompleteProcent = isLocalDev ? 1 : 70; // % 

    useEffect(() => {
        setCurrentTraining(null) 
        setTrainingComplete(false)
        console.log('Нужный процент: ', trainingCompleteProcent)
        if (location.getParams().id)
            try {  
                trainingService.getMainTraining(location.getParams().id).then(data => {
                    if (data.status === 401 || data.status === 500) setTimeout(() => router.popPageTo(PAGE_TRAINING), 100)
                    if (data === 'offline') return setErrorView({ errorStatus: 'Network Error' })
                    setCurrentTraining(data.data)
                    setTraining(data.data)
                }).catch((error) => {
                    console.log('getMainTraining TrainingMainPanel', error)
                    setErrorView(getErrorStatus(error));
                })
            } catch (error) {
                console.log('getMainTraining catch', error)
                setErrorView(getErrorStatus(error));
            }
        else router.popPageTo(PAGE_TRAINING)
    }, []) 

    useEffect(() => {
        if (videoState === 'onplay') {
            const interval = setInterval(() => {
                updateTraining()
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [videoState, trainingCompleteState])



    const startTraining = () => {
        var videoElement = document.getElementById('videoFrame');
        videoElement.currentTime = 0;
        videoElement.play();
        if (videoState === 'loaded') {
            openPopout(null, null)
            setVideoState('onplay')
            setTimeout(() => { videoFullScreen(videoElement) }, 50)
            setVideoDuration(videoElement.duration) // get video duration
        } else {
            setVideoState('onloading')
            openPopout(null, 'loading')
            videoElement.onloadeddata = function (e) {
                var videoElement = document.getElementById('videoFrame');
                openPopout(null, null)
                setVideoState('onplay')
                setTimeout(() => { videoFullScreen(videoElement) }, 50)
                setVideoDuration(videoElement.duration) // get video duration
            }
        }
        // analytics
        amplitudeEvent("workout_start", {
            "id": currentTraining.id,
            "type": currentTraining.task.highest_skill_name,
            "source": (currentTraining.id === dayTraining.id ? "main_screen" : "catalog")
        })
        trainingService.startTraining(location.getParams().id)
        bridge.send("VKWebAppSendCustomEvent", {
            "type": "type_action",
            "event": "workout_start",
            "json": JSON.stringify({
                "id": currentTraining.id,
                "source": (currentTraining.id === dayTraining.id ? "daily" : "catalog")
            })
        })
    }



    const videoFullScreen = (element) => {
        if (element.requestFullScreen) element.requestFullScreen();
        if (element.webkitEnterFullscreen) element.webkitEnterFullscreen();
        if (element.webkitRequestFullscreen) element.webkitRequestFullscreen();
        if (element.mozRequestFullScreen) element.mozRequestFullScreen();
        if (element.msRequestFullscreen) element.msRequestFullscreen();
    }
    const exitFullScreen = () => {
        var videoElement = document.getElementById('videoFrame');
        if (document.webkitExitFullscreen)
            document.webkitExitFullscreen()
        else if (document.exitFullscreen)
            document.exitFullscreen();
        else if (document.webkitExitFullScreen)
            document.webkitExitFullScreen();
        else if (document.mozCancelFullScreen)
            document.mozCancelFullScreen();
        else if (document.msExitFullscreen)
            document.msExitFullscreen();
        else if (videoElement.webkitExitFullScreen)
            videoElement.webkitExitFullScreen();
        else if (videoElement.webkitExitFullscreen)
            videoElement.webkitExitFullscreen();
    }

    const pauseVideo = () => {
        if (videoState !== 'onloading')
            setVideoState('onpause');
    }
    const playVideo = () => {
        if (videoState !== 'onloading')
            setVideoState('onplay');
    }
    const waitingVideo = () => {
        if (videoState !== 'onloading')
            setVideoState('waitingVideo')
    }

    const finishVideo = () => {
        exitFullScreen()
        setTimeout(() => {
            trainingComplete ?
                completeTraining()
                : openPopout(MODAL_TRAINING_EXIT, { 'source': currentTraining.id === dayTraining.id ? "main_screen" : "catalog" })
        }, 300)
    }


    const updateTraining = (e) => {
        var videoElement = document.getElementById('videoFrame');
        if (videoDutaion === 100) setVideoDuration(videoElement.duration) // fixed broken duration if it needed
        setTrainingCompleteState(trainingCompleteState => trainingCompleteState + 1) // update training state every sec   
        const currentProcent = trainingCompleteState ? trainingCompleteState * 100 / Math.round(videoDutaion) : 0
        // console.log('Текущий процент: ', currentProcent)
        if (currentProcent > trainingCompleteProcent) {
            setTrainingComplete(completed => {
                if (completed === false) {
                    bridge.send("VKWebAppSendCustomEvent", {
                        "type": "type_action",
                        "event": "workout_complete",
                        "json": JSON.stringify({
                            "id": currentTraining.id,
                            "source": (currentTraining.id === dayTraining.id ? "daily" : "catalog")
                        })
                    })
                    amplitudeEvent("workout_complete", { "viewed": true, "source": (currentTraining.id === dayTraining.id ? "main_screen" : "catalog"), id: dayTraining.id })
                    finishTraining({ "workout_id": currentTraining.id, "duration": trainingCompleteState })
                    setTrainingComplete(true)
                    return true
                }
                return completed
            })
        }
    }

    const completeTraining = () => {
        nextPage(PAGE_TRAINING_COMPLETE, { "ask_raise": currentTraining.ask_raise, "source": (currentTraining.id === dayTraining.id ? "main_screen" : "catalog") })
    }

    // if (currentTraining) {
        return (
            <Panel id={id}>
                <PanelHeader separator={false} left={
                    <PanelHeaderBack
                        onClick={(videoState === 'onstart' || videoState === 'onloading' || videoState === 'loaded') ? goBack
                            : () => openPopout(MODAL_TRAINING_EXIT, {'goBack': true})}
                    />} >Тренировка</PanelHeader>
                <Group className="customGroup" separator="hide" style={{ paddingLeft: 8, paddingRight: 8 }}  >
                    {videoState === 'onstart' || videoState === 'onloading' || videoState === 'loaded' ?
                        <TrainingPreview setPanel={setPanel} data={currentTraining} showButton={false} fullView={false} /> : ''
                    }
                    {currentTraining && currentTraining.video[0] ?
                        <div className={"videoFrame " + (videoState === 'onstart' || videoState === 'onloading' || videoState === 'loaded' ? 'hidden' : '')}  >
                            <video
                                onPause={pauseVideo}
                                controls={videoState !== 'onstart'}
                                onPlaying={playVideo}
                                poster={currentTraining.preview}
                                onWaiting={waitingVideo}
                                onEnded={finishVideo}
                                playsInline
                                onLoadedData={() => setVideoState('loaded')}
                                id="videoFrame"
                                controlsList="nodownload noplaybackrate"
                                width="100%"
                            >
                                <source
                                    // src='https://static.w.f-365.ru/workouts/501_h265.mp4'
                                    src={currentTraining.video[0].url}
                                // type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' 
                                // type={`'video/`+currentTraining.video[0].format+`; codecs='`+currentTraining.video[0].codec+`'`}
                                >
                                </source>
                            </video>
                        </div>

                        : ''}
                    {/* <Plyr source={videoMock} /> */}
                    {/* </Div> */}
                    <TrainingDescription content={currentTraining} />

                </Group>
                <Group className="customGroup" separator="hide"   >
                    <Div style={{ paddingTop: 3 }}>
                        <Title level="3" weight="semibold" style={{ marginBottom: 3 }}>Упражнения из тренировки</Title>
                    </Div>
                    {currentTraining && currentTraining.exercises.length ?
                        currentTraining.exercises.map((item, index) => {
                            return <ExercisePreview key={index} data={item}
                                openPopout={() => openPopout(MODAL_TRAINING_EXAMPLE, { 'title': item.title, 'content': item.animation, 'preview': item.animation })}
                            />
                        })
                        :
                        <>
                            <ExercisePreview skeleton={true} />
                            <ExercisePreview skeleton={true} />
                            <ExercisePreview skeleton={true} />
                            <ExercisePreview skeleton={true} />
                        </>
                    }
                    {currentTraining ?
                        <Div>
                            <Button stretched size="l" mode="secondary" onClick={() => {  nextPage(PAGE_TRAINING_CATALOG); window.scrollTo(0, 0); amplitudeEvent("workout_change") }}  style={{ marginBottom: 50, marginTop: 4 }}  >Сменить тренировку </Button>
                        </Div>
                        : ''}
                </Group>

                {currentTraining ? (
                    <FixedLayout vertical="bottom" style={{ background: 'white' }}>
                        <Div>
                            {trainingComplete ?
                                <Button stretched size="l" style={{ marginBottom: 10 }} onClick={() => completeTraining()} >
                                    Завершить тренировку
                                </Button>
                                :
                                <Button stretched size="l" style={{ marginBottom: 10 }} onClick={videoState === 'onstart' || videoState === 'loaded' ? startTraining : () => openPopout(MODAL_TRAINING_EXIT, { 'source': currentTraining.id === dayTraining.id ? "main_screen" : "catalog" })}   >
                                    {videoState === 'onstart' || videoState === 'loaded' ? "Начать тренировку" : (videoState === 'onloading' ? "Загружаем..." : "Завершить тренировку")}
                                </Button>
                            }
                        </Div>
                    </FixedLayout>
                ) : ''}
            </Panel>
        )
    // } else {
    //     return (
    //         <Panel id={id}>
    //             <PanelHeader separator={false} left={<PanelHeaderBack onClick={() => router.popPageTo(PAGE_TRAINING)} />} >Тренировка</PanelHeader>
    //             <Group className="customGroup" separator="hide" style={{ paddingLeft: 8, paddingRight: 8 }}  >
    //                 <TrainingPreview setPanel={null} setHomePanel={null} data={null} showButton={true} fullView={false} buttonEvent={null} optionEvent={null} />
    //                 <TrainingDescription content={null} contentTitle={false} showDescription={false} />
    //             </Group>
    //             <Group className="customGroup" separator="hide"   >
    //                 <Div style={{ paddingTop: 3 }}>
    //                     <Title level="3" weight="semibold" style={{ marginBottom: 3 }}>Упражнения из тренировки</Title>
    //                 </Div>
    //                 <ExercisePreview skeleton={true} />
    //                 <ExercisePreview skeleton={true} />
    //                 <ExercisePreview skeleton={true} />
    //                 <ExercisePreview skeleton={true} />
    //             </Group>
    //         </Panel>
    //     )
    // }

};
export default TrainingMainPanel;
