import { Router, Route, Location } from '@happysanta/router';
import { systemSwipeBack } from '../components/modules/AppModule';
import {
  routes,
  PAGE_MAIN, 
  PAGE_TRAINING,
  PAGE_PROFILE,
  PAGE_CATALOG,
} from './routes';


export const initHash = (window.location.hash ? (window.location.hash[1] !== '/' ? window.location.hash : null) : null);
// export const initHash = '#breath';
export const initSearch = window.location.search;
// export const initSearch = "?article=32&asdasd=123";
export const router = new Router(routes);
export let initPageId = PAGE_MAIN;
let initPageIdSet = false;

router.on('update', (nextRoute, oldRoute) => {  
  if (!initPageIdSet) {
    initPageIdSet = true;
    initPageId = nextRoute.getPageId();
  }
  const pageId = nextRoute.getPageId(); // /product/:id([0-9]+)  
  // const modalId = nextRoute.getModalId(); // modal_product  
  // manageSystemSwipeBack(pageId, nextRoute); 
    // Чтобы не скроллился контент под открытой модалкой. На iOS может не работать.
    if (nextRoute.isModal()) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  
});

 
function getCurrentEntityId(location: Route | Location) {
  return location.getPopupId() || location.getModalId() || location.getPageId();
}

export function getScreenId(location: Route | null = null) {
  let pageId = getCurrentEntityId(location || router.getCurrentLocation());
  if (pageId) {
    if (pageId === PAGE_MAIN) {
      pageId = PAGE_TRAINING;
    }

    pageId = pageId.replace('/', '');
  }

  return pageId;
}


function manageSystemSwipeBack(pageId: string, route: Route) { 
  // if (pageId === PAGE_MAIN || pageId === PAGE_CATALOG || pageId === PAGE_PROFILE) { 
    if (!route.hasOverlay() && !systemSwipeBack.isEnabled()) {
      systemSwipeBack.enable()
    } else if (route.hasOverlay() && systemSwipeBack.isEnabled()) {
      systemSwipeBack.disable()
    }
  // } else if (systemSwipeBack.isEnabled()) {
  //   systemSwipeBack.disable()
  // }
}

// export function preventBlinkingBySettingScrollRestoration() {
//   let startParams = BridgePlus.getStartParams();

//   if ('scrollRestoration' in window.history && window.history.scrollRestoration === 'auto' && startParams.isMobile()) {
//     window.history.scrollRestoration = 'manual';
//   }
// }

