import React, { } from 'react';
import './ExercisePreview.css';
import { SimpleCell } from '@vkontakte/vkui'; 
import Skeleton from 'react-loading-skeleton';


const ExercisePreview = ({ data, openPopout }) => (
    <SimpleCell
        onClick={openPopout}
        data-title={data && data.title }
        data-content={data && data.animation}
        data-preview={data && data.preview}
        className="exerciseExample small-one"
        multiline 
        before={data ? <div className="exercisePreviewImage" style={{marginRight: 12, backgroundImage: `url(${data.preview})`}}></div> : <div className="exercisePreviewImage" style={{marginRight: 12 }}><Skeleton height={'100%'} /></div> }>
        {data ? data.title: <Skeleton width={140} />}
    </SimpleCell>
)


export default ExercisePreview;