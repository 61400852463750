import React, { } from 'react';
import './EpicNavBar.css';
import {FixedLayout, Tabs, TabsItem } from '@vkontakte/vkui'; 
import { PAGE_CATALOG, PAGE_CHALLENGES, PAGE_PROFILE, PAGE_TRAINING, VIEW_CATALOG, VIEW_CHALLENGES, VIEW_PROFILE, VIEW_TRAINING } from '../../routing/routes';
import Skeleton from 'react-loading-skeleton';

const EpicNavBar = ({ navEvent, currentActive, loading }) => {
    return (
        <FixedLayout vertical="top" className="customFixedLayout">
            <Tabs>
                <TabsItem
                    className={loading ? 'loading' : ''}
                    onClick={() => navEvent({ page: PAGE_TRAINING, view: VIEW_TRAINING, slug: "Training", current: currentActive })}
                    selected={currentActive === PAGE_TRAINING}
                >
                   {loading ? <Skeleton width={80} /> : 'Сегодня'}  
                </TabsItem>
                <TabsItem
                    className={loading ? 'loading' : ''}
                    onClick={() => navEvent({ page: PAGE_CATALOG, view: VIEW_CATALOG, slug: "Catalog", current: currentActive })}
                    selected={currentActive === PAGE_CATALOG}
                >
                     {loading ? <Skeleton width={80} /> : 'Знания'}  
                     
                </TabsItem>
                {/* <TabsItem
                    className={loading ? 'loading' : ''}
                    onClick={() => navEvent({ page: PAGE_CHALLENGES, view: VIEW_CHALLENGES, slug: "Challenges", current: currentActive })}
                    selected={currentActive === PAGE_CHALLENGES}
                >
                     {loading ? <Skeleton width={80} /> : 'Челледж'}  
                    
                </TabsItem> */}
                <TabsItem
                    className={loading ? 'loading' : ''}
                    onClick={() => navEvent({ page: PAGE_PROFILE, view: VIEW_PROFILE, slug: "Profile", current: currentActive })}
                    selected={currentActive === PAGE_PROFILE}
                >
                     {loading ? <Skeleton width={80} /> : 'Профиль'}  
                    
                </TabsItem>
            </Tabs>
        </FixedLayout>
    )
}


export default EpicNavBar;