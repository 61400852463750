import React, { } from 'react';
import './FadeHeader.css';


const FadeHeader = () => (
 <div className="header--fadeHeader"  >

 </div>
)


export default FadeHeader;