import React, { useState, useEffect, useRef } from 'react';
import '@vkontakte/vkui/dist/vkui.css';
import { View, ActionSheet, ActionSheetItem, Title, Button, Div, Text, ModalRoot, ScreenSpinner } from '@vkontakte/vkui';
import TrainingDayPanel from './TrainingDayPanel/TrainingDayPanel';
import TrainingMainPanel from './TrainingMainPanel/TrainingMainPanel';
import TrainingCompletePanel from './TrainingCompletePanel/TrainingCompletePanel';
import TrainingSharingPanel from './TrainingSharingPanel/TrainingSharingPanel';
import TrainingCatalogPanel from './TrainingCatalogPanel/TrainingCatalogPanel';
import TrainingCatalogSingle from './TrainingCatalogPanel/TrainingCatalogSingle';
import TrainingService from '../../services/TrainingService';
import bridge from '@vkontakte/vk-bridge';
import {
    Icon56SettingsOutline,
    Icon56NotebookCheckOutline,
    Icon56ArrowRightDoorOutline
} from '@vkontakte/icons';
import { withRouter, useLocation, useRouter } from '@happysanta/router';
import { MODAL_PAYWALL, MODAL_SUBINFO, MODAL_TRAINING_EXAMPLE, MODAL_TRAINING_EXIT, MODAL_TRAINING_LEVEL, MODAL_TRAINING_LEVEL_SUCCESS, PAGE_TESTING, PAGE_TRAINING, PAGE_TRAINING_COMPLETE, PAGE_TRAINING_MAIN, PANEL_CHALLENGES_WAITING, PANEL_CHALLENGES_OPEN, PANEL_TRAINING_CATALOG, PANEL_TRAINING_CATALOG_SINGLE, PANEL_TRAINING_COMPLETE, PANEL_TRAINING_DAILY, PANEL_TRAINING_MAIN, PANEL_TRAINING_SHARING, VIEW_CHALLENGES, VIEW_TESTING, PAGE_TRAINING_CATALOG_SINGLE, MODAL_PERMISSION } from '../../routing/routes';
import PaywallModal from '../../components/modal/PaywallModal';
import SubInfoModal from '../../components/modal/SubInfoModal';
import Skeleton from 'react-loading-skeleton';
import ProfileService from '../../services/ProfileService';
import TestingService from '../../services/TestingService';
import { disableSystemSwipeBack, enableSystemSwipeBack } from '../../components/modules/AppModule';
import { initHash } from '../../routing/router';
import NotifyPermissionModal from '../../components/modal/notifyPermissionModal/NotifyPermissionModal';
import Cookies from 'js-cookie'
import { BridgePlus } from '@happysanta/bridge-plus';
// const [mainTraining, setMainTraining] = useState(null);
const trainingService = new TrainingService();
const testingService = new TestingService();

const Training = ({ setHomePanel, catalog, dailyTraining, viewId, challengesData, history, vkUserData, isPremium, setPremium, bufferActivePanel, goBack, setTrainingData, onSwipeBack, banner, changeMainNav, activePanel, fetchData, key, amplitudeEvent, setErrorView, closeApp }) => {
    const router = useRouter();
    const location = useLocation();
    const [completeTrainingId, setCompleteTrainingId] = useState(null);
    const [popoutParam, setPopoutParam] = useState(null);
    const [catalogKey, setCatalogKey] = useState(0);
    const [catalogData, setCatalogData] = useState(catalog);
    const [trainingId, setTrainingId] = useState(null);
    const [nextTrainingId, setNextTrainingId] = useState(null);
    const [dayTraining, setDayTraining] = useState(dailyTraining);
    const [currentTrainig, setCurrentTraining] = useState(dailyTraining)
    const [subStatus, setSubStatus] = useState(null);
    const profileService = new ProfileService();
    const baseTargetRef = useRef(null)
    useEffect(() => {
        router.replacePage(PAGE_TRAINING)
        // BridgePlus.api("messages.isMessagesFromGroupAllowed").then(data=>{
        //     console.log(data)
        // });

        // bridge.send("VKWebAppCheckAllowedScopes", {scopes: "group_messages"}).then(data=>{
        //     console.log(data)
        // });
        // setTimeout(() => {
        //    if ( !Cookies.get('NotifyPermissionAsked') )
        //       openModal(MODAL_PERMISSION) 
        // }, 500); 
        // if (bufferActivePanel)
        // nextPage(PAGE_TRAINING_CATALOG_SINGLE)  
    }, [])


    const successEvent = () => {
        // router.popPage(); 
        setPremium(true)
        setTimeout(() => {
            nextPage(PAGE_TRAINING_MAIN, { trainingId: nextTrainingId })
        }, 200);
    }

    const getNotifyPermission = () => {
        bridge.send("VKWebAppAllowMessagesFromGroup", { "group_id": 207167398, "key": vkUserData.id }).then(data => {
            console.log(data)
            Cookies.set('NotifyPermissionAsked', true, { expires: 7 })
            if (!data.result) {
                Cookies.set('NotifyPermissionAsked', true, { expires: 7 })
            }
        });
    }


    const popout = (() => {
        if (location.getPopupId() === MODAL_TRAINING_EXAMPLE ||
            location.getPopupId() === MODAL_TRAINING_EXIT ||
            location.getPopupId() === MODAL_TRAINING_LEVEL ||
            location.getPopupId() === MODAL_TRAINING_LEVEL_SUCCESS
        ) {
            return (
                <ActionSheet
                    onClose={() => location.getPopupId() === MODAL_TRAINING_EXAMPLE ? router.popPageTo(PAGE_TRAINING_MAIN) : router.popPage()}
                    toggleRef={baseTargetRef.current}
                > {
                        location.getPopupId() === MODAL_TRAINING_EXAMPLE && (
                            <>
                                <Div style={{ backgroundColor: 'inherit', paddingBottom: 0 }}>
                                    <Title level="3" weight="semibold" style={{ marginBottom: 13, textAlign: 'center' }}>{popoutParam.title}</Title>
                                    <div className="popupContent">
                                        <div className="exampleImage">
                                            <Skeleton width={'100%'} height={"100%"} style={{ borderRadius: 10 }} />
                                            <picture className="floatImg">
                                                <source type="image/webp" srcSet={popoutParam.preview} style={{ width: '100%', borderRadius: 10 }} />
                                                <source type="image/jpeg" srcSet={popoutParam.preview} style={{ width: '100%', borderRadius: 10 }} />
                                                <img src={popoutParam.preview} style={{ width: '100%', borderRadius: 10 }} alt="" />
                                            </picture>
                                        </div>
                                    </div>
                                </Div>
                                <ActionSheetItem autoclose  >
                                    <Button stretched size="l"  >Понятно</Button>
                                </ActionSheetItem>
                            </>
                        )
                    }
                    {
                        location.getPopupId() === MODAL_TRAINING_EXIT && (
                            <>
                                <Div style={{ backgroundColor: 'inherit', textAlign: 'center' }}>
                                    <Icon56ArrowRightDoorOutline fill="#9062FF" style={{ margin: "0 auto 17px" }} />
                                    <Title level="2" weight="semibold" style={{ marginBottom: 8 }}>Завершить тренировку? </Title>
                                    <Text style={{ color: "#6D7885" }}>Если сейчас остановить тренировку, она не будет засчитана</Text>
                                    <div className="flexBox btnFullWidth">
                                        <ActionSheetItem autoclose style={{ padding: '0 6px', width: '50%' }} >
                                            <Button style={{ width: '39vw' }} mode="secondary" size="l"  >Остаться</Button>
                                        </ActionSheetItem>
                                        <ActionSheetItem autoclose style={{ padding: '0 6px', width: '50%' }}>
                                            <Button style={{ width: '39vw' }} size="l" mode="destructive"
                                                onClick={() => {
                                                    popoutParam.goBack ? router.popPageTo(PAGE_TRAINING) : router.popPage();
                                                    !popoutParam.goBack && setTimeout(() => nextPage(PAGE_TRAINING_COMPLETE, { "amplitudeEvent": "workout_complete", "source": popoutParam, "viewed": false }), 100)
                                                }}  >Завершить</Button>
                                        </ActionSheetItem>

                                    </div>
                                </Div>
                            </>
                        )
                    }
                    {
                        location.getPopupId() === MODAL_TRAINING_LEVEL && (
                            <>
                                <Div style={{ backgroundColor: 'inherit', textAlign: 'center' }}>
                                    <Icon56SettingsOutline fill="#9062FF" style={{ margin: "0 auto 17px" }} />
                                    <Title level="2" weight="semibold" style={{ marginBottom: 8 }}>Усложнить тренировку?</Title>
                                    <Text style={{ color: "#6D7885" }}>Вы можете немного усложнить {
                                        {
                                            strength: `тренировки с собственным весом`,
                                            cardio: `кардио тренировки`,
                                            stretch: `тренировки на растяжку`,
                                            mobility: `тренировки на мобильность `,
                                            dumbbells: `тренировки с гантелями`,
                                        }[popoutParam]
                                    }  или оставить как есть</Text>
                                    <div className="flexBox btnFullWidth">
                                        <ActionSheetItem style={{ padding: '0 6px', width: '50%' }}>
                                            <Button style={{ width: '39vw' }} mode="secondary" size="l" onClick={() => {
                                                updateProfile({ [popoutParam]: 'medium' })
                                                amplitudeEvent('workout_level_raise', {
                                                    "raise_button": true,
                                                    "type": popoutParam
                                                });
                                                router.replacePopup(MODAL_TRAINING_LEVEL_SUCCESS)
                                            }} >Усложнить</Button>
                                        </ActionSheetItem>
                                        <ActionSheetItem autoclose style={{ padding: '0 6px', width: '50%' }} >
                                            <Button style={{ width: '39vw' }} size="l" onClick={() =>
                                                amplitudeEvent('workout_level_raise', {
                                                    "raise_button": false,
                                                    "type": popoutParam
                                                })} >Оставить</Button>
                                        </ActionSheetItem>

                                    </div>
                                </Div>
                            </>
                        )
                    }
                    {
                        location.getPopupId() === MODAL_TRAINING_LEVEL_SUCCESS && (
                            <>
                                <Div style={{ backgroundColor: 'inherit', textAlign: 'center' }}>
                                    <Icon56NotebookCheckOutline fill="#9062FF" style={{ margin: "0 auto 17px" }} />
                                    <Title level="2" weight="semibold" style={{ marginBottom: 8 }}>

                                        {
                                            {
                                                strength: `Тренировки с собственным весом`,
                                                cardio: `Кардио тренировки`,
                                                stretch: `Тренировки на растяжку`,
                                                mobility: `Тренировки на мобильность `,
                                                dumbbells: `Тренировки с гантелями`,
                                            }[popoutParam]
                                        } теперь станут немного сложнее</Title>
                                    {/* <Text style={{ color: "#6D7885" }}>Вы можете немного повысить уровень сложности тренировок такого типа. Или оставить как есть. Решать вам. Никогда ничего не бойтесь. Живите здесь и сейчас</Text> */}

                                    <ActionSheetItem autoclose  >
                                        <Button stretched size="l"  >Отлично</Button>
                                    </ActionSheetItem>
                                </Div>
                            </>
                        )
                    }
                </ActionSheet>
            )
        }
        if (popoutParam === 'loading') return <ScreenSpinner />
    })();

    const nextPage = (nextPage, data) => {
        if (data) {
            disableSystemSwipeBack()
            if (data.keycatalog) {
                setCatalogKey(data.keycatalog)
                localStorage.setItem('catalogkey', data.keycatalog)
            }
            if (data.amplitudeEvent) {
                amplitudeEvent(data.amplitudeEvent, {
                    "id": trainingId,
                    "source": data.source,
                    "viewed": data.viewed,
                    "type": data.type
                })
            }
            if (data.trainingId) { 
                setTrainingId(data.trainingId)
                return router.pushPage(nextPage, { id: (data.trainingId).toString() })
            }
            if (data.ask_raise)
                setTimeout(e => {
                    openPopout(MODAL_TRAINING_LEVEL, currentTrainig.task.parameter)
                }, 800)
        }
        // router.replacePage(nextPage)
        (nextPage === PAGE_TRAINING) ? router.popPageTo(PAGE_TRAINING) : router.pushPage(nextPage)
    }

    const setTraining = (data) => {
        setCurrentTraining(data)
    }


    const modal = (
        <ModalRoot
            activeModal={location.getModalId()}
            onClose={() => { router.pushModal(null); amplitudeEvent("paywall_button", { "value_choose": "close", "source": "change workout" }) }}
        >
            <PaywallModal settlingHeight={100}
                dynamicContentHeight={true}
                source={"change page"}
                successEvent={successEvent}
                amplitudeEvent={amplitudeEvent}
                id={MODAL_PAYWALL}
                closeEvent={() => { router.popPage(); amplitudeEvent("paywall_button", { "value_choose": "close", "source": "change workout" }) }}
                clickEvent={() => openModal(MODAL_SUBINFO)} />
            <SubInfoModal
                settlingHeight={100}
                dynamicContentHeight={true}
                id={MODAL_SUBINFO}
                clickEvent={() => router.popPage()}
                closeEvent={() => router.popPage()} ></SubInfoModal>
            <NotifyPermissionModal
                dynamicContentHeight={true}
                id={MODAL_PERMISSION}
                closeEvent={() => router.popPage()}
                clickEvent={() => { getNotifyPermission(); router.popPage() }} />
        </ModalRoot>
    )

    const openModal = (modal, data) => {
        if (dailyTraining && location.getModalId() !== modal) router.pushModal(modal);
        if (data) {
            if (data.nextPageId) setNextTrainingId(data.nextPageId)
        }
    }

    const openPopout = (popout, e) => {
        setPopoutParam(e)
        if (popout) router.pushPopup(popout);

    }

    const startTesting = (id) => {
        openPopout(null, 'loading')
        testingService.getTest(id).then(data => {
            if (data) {
                setTrainingData(data.data)
                setTimeout(() => {
                    router.replacePage(PAGE_TESTING, { testId: id, slideId: data.data.start_screen })
                    changeMainNav({ view: VIEW_TESTING })
                    openPopout(null, null)
                }, 200)
            }
            else {
                setErrorView(getErrorStatus(error));
                openPopout(null, null)
            }
        }).catch(error => {
            setErrorView(getErrorStatus(error));
            openPopout(null, null)
        })
    }


    const finishTraining = (data) => {
        trainingService.completeTraining(data).then(data => {
            console.log(data)
            setCompleteTrainingId(data.data.id)
        })
    }

    const getPanelProps = (panelId) => {
        return {
            id: panelId,
            goBack: goBack,
            nextPage: nextPage,
            isPremium: isPremium,
            setErrorView: setErrorView,
            openPaywallModal: openModal,
            history: history,
            bufferActivePanel: bufferActivePanel,
            openPopout: openPopout,
            setPanel: () => null,
            setHomePanel: setHomePanel,
            amplitudeEvent: amplitudeEvent,
            catalog: catalog,
            closeApp: closeApp,
            startTesting: startTesting,
            banner: banner,
            training: panelId === PANEL_TRAINING_DAILY ? dayTraining : currentTrainig,
        };
    };

    const updateProfile = (data) => {
        profileService.updateUserWorkoutParams(data)
    }


    return (
        // <Root activeView='Training'>
        <View
            popout={popout}
            modal={modal}
            activePanel={activePanel ? activePanel : PANEL_TRAINING_DAILY}
            // activePanel={ bufferActivePanel ? bufferActivePanel
            //             : (activePanel ? activePanel : PANEL_TRAINING_DAILY)
            //         }
            id={viewId}
            history={history}
            onSwipeBack={onSwipeBack}  >
            <TrainingDayPanel {...getPanelProps(PANEL_TRAINING_DAILY)} changeMainNav={changeMainNav} fetchData={fetchData} challengesData={challengesData} />
            <TrainingMainPanel {...getPanelProps(PANEL_TRAINING_MAIN)} trainingId={trainingId} dayTraining={dayTraining} setTraining={setTraining} currentTrainig={currentTrainig} finishTraining={finishTraining} key={currentTrainig} />
            <TrainingCompletePanel {...getPanelProps(PANEL_TRAINING_COMPLETE)} completeTrainingId={completeTrainingId} />
            <TrainingSharingPanel {...getPanelProps(PANEL_TRAINING_SHARING)} />
            <TrainingCatalogSingle {...getPanelProps(PANEL_TRAINING_CATALOG_SINGLE)} catalogkey={catalogKey} key={catalog} fetchData={fetchData} />
            <TrainingCatalogPanel {...getPanelProps(PANEL_TRAINING_CATALOG)} />
        </View>
        // </Root>
    )

}

export default withRouter(Training);
