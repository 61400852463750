import { BridgePlus } from '@happysanta/bridge-plus';
import bridge from '@vkontakte/vk-bridge';

export const LIGHT_SCHEME_STATUS_BAR_STYLE = 'dark';
export const LIGHT_SCHEME_ACTION_BAR_COLOR = '#FFFFFF';
export const LIGHT_SCHEME_NAVIGATION_BAR_COLOR = '#FFFFFF';
export const DARK_SCHEME_STATUS_BAR_STYLE = 'light';
export const DARK_SCHEME_ACTION_BAR_COLOR = '#19191a';
export const DARK_SCHEME_NAVIGATION_BAR_COLOR = '#19191a';

export const COLOR_SCHEMES = {
  CLIENT_LIGHT: 'client_light',
  BRIGHT_LIGHT: 'bright_light',
  CLIENT_DARK: 'client_dark',
  SPACE_GRAY: 'space_gray',
};


export function enableSystemSwipeBack() {
  bridge.send('VKWebAppEnableSwipeBack')
    .catch((error) => { console.warn(error); });
}

export function disableSystemSwipeBack() {
  bridge.send('VKWebAppDisableSwipeBack')
    .catch((error) => { console.warn(error); });
}

function systemSwipeBackManage() {
  let enabled = true;

  function wrapper() { }

  wrapper.isEnabled = () => enabled;

  wrapper.enable = () => {
    enableSystemSwipeBack();
    enabled = true;
  };

  wrapper.disable = () => {
    disableSystemSwipeBack();
    enabled = false;
  };

  return wrapper;
}

export const systemSwipeBack = systemSwipeBackManage();


const AppModule = (state = 'initState', action) => {
}

export function appInit() {
  BridgePlus.init();
  setAppSettings();
  enableSystemSwipeBack();
}

export function isDarkScheme(scheme = window._appColorScheme) {
  return scheme === COLOR_SCHEMES.CLIENT_DARK || scheme === COLOR_SCHEMES.SPACE_GRAY;
}

export function setAppViewSettings({ statusBarStyle = window._appStatusBarStyle, actionBarColor = window._appActionBarColor, navigationBarColor = window._appNavigationBarColor, needNavigationBar = false }) {
  if (bridge.supports('VKWebAppSetViewSettings')) {
    const params = { 'status_bar_style': statusBarStyle, 'action_bar_color': actionBarColor };

    if (needNavigationBar) {
      params['navigation_bar_color'] = navigationBarColor;
    }

    BridgePlus.send('VKWebAppSetViewSettings', params)
      .catch((error) => {console.warn(error);});
  }
}

export function setAppSettings() {
  window._appStatusBarStyle = LIGHT_SCHEME_STATUS_BAR_STYLE;
  window._appActionBarColor = LIGHT_SCHEME_ACTION_BAR_COLOR;
  window._appNavigationBarColor = LIGHT_SCHEME_NAVIGATION_BAR_COLOR;
  window._appColorScheme = COLOR_SCHEMES.BRIGHT_LIGHT;

  let clb = (e) => {
    let vkEvent = e.detail;

    if (!vkEvent) {
      console.error('invalid event', e);
      return;
    }

    let type = vkEvent['type'];
    let data = vkEvent['data'];

    switch (type) {
      case 'VKWebAppUpdateConfig':
        const oldScheme = document.body.getAttribute('scheme');
        const vkScheme = data.scheme || COLOR_SCHEMES.BRIGHT_LIGHT;

        if (oldScheme !== vkScheme) {
          if (isDarkScheme(vkScheme)) {
            document.body.classList.add("theme--space_gray");
            window._appStatusBarStyle = DARK_SCHEME_STATUS_BAR_STYLE;
            window._appActionBarColor = DARK_SCHEME_ACTION_BAR_COLOR;
            window._appNavigationBarColor = DARK_SCHEME_NAVIGATION_BAR_COLOR;
          } else {
            window._appStatusBarStyle = LIGHT_SCHEME_STATUS_BAR_STYLE;
            window._appActionBarColor = LIGHT_SCHEME_ACTION_BAR_COLOR;
            window._appNavigationBarColor = LIGHT_SCHEME_NAVIGATION_BAR_COLOR;
            document.body.classList.remove("theme--space_gray");
          }

          setAppViewSettings({});
        }

        document.body.setAttribute('scheme', vkScheme);
        if (isDarkScheme(vkScheme)) {
          document.body.classList.add("theme--space_gray");
        }
        window._appColorScheme = vkScheme;

        break;
      // case 'VKWebAppViewHide':
      //   store.dispatch(setAppViewHidden(true));
      //   store.dispatch(setAppViewRestored(false));
      //   Analytics.hide();
      //   break;
      // case 'VKWebAppViewRestore':
      //   store.dispatch(setAppViewHidden(false));
      //   store.dispatch(setAppViewRestored(true));
      //   Analytics.restore();
        // break;
      default:
        break;
    }
  };

  bridge.subscribe(clb);

  if (BridgePlus.getStartParams().platform.indexOf('android') !== -1) {
    window.addEventListener('focus', () => {
      // needNavigationBar: false, так как на android мигает контент при смене темы из-за параметра navigation_bar_color в VKWebAppSetViewSettings
      setAppViewSettings({ needNavigationBar: false });
    });
  }
}

export default AppModule;
