import { Caption, Panel, Title, View, Div, Text, Group, CardScroll, Card, Avatar, Button, CardGrid, Subhead, SimpleCell, IconButton } from '@vkontakte/vkui';
import React, { useState, useEffect, useRef } from 'react';
import './Challenges.css';
import mockHeader from '../../img/mock/challenge-header.png'
import otherFriendImg from '../../img/other-friends.png'
import { Icon28ChevronBack, Icon28Notifications, Icon28DoorArrowRightOutline, Icon24HelpOutline, Icon16Lock, Icon32Discussions } from '@vkontakte/icons';
import Timer from '../../components/Timer/Timer';
import ChallengeHeader from '../../components/ChallengeHeader/ChallengeHeader';
import { MODAL_FRIENDS_INVITE, PAGE_TRAINING, POPOUT_CHALLENGE_SIMPLE, VIEW_TRAINING, MODAL_FRIENDS_ALL, MODAL_CHALLENGE_TASKS, MODAL_CHALLENGE_SIMPLE } from '../../routing/routes';

const ChallengeWaiting = ({ id, openPopout, changeMainNav,challengeData,  mockFriends, openModal, challengeTasks }) => {  
    useEffect(() => {
        console.log(challengeData, 1)
    }, [])
    return (
        <Panel id={id}>
            {/* <ChallengeHeader exitEvent={() => changeMainNav({ page: PAGE_TRAINING, view: VIEW_TRAINING })} additionalEvent={() => openModal(MODAL_CHALLENGE_SIMPLE)} /> */}
            <ChallengeHeader challengeData={challengeData} exitEvent={() => changeMainNav({ view: VIEW_TRAINING, page: PAGE_TRAINING })}  additionalEvent={() => openModal(MODAL_CHALLENGE_SIMPLE)}  />

            <Group className="customGroup challenge--content" separator="hide" style={{ paddingTop: 0 }}>
                <Div style={{ paddingTop: 5 }}>
                    <Title level="3">До старта осталось</Title>
                    {/* <CardGrid size="l"> */}
                    <Card mode="outline" className="customCard">
                        <Timer finishDate={challengeData.starts_at} />
                    </Card>
                    {/* </CardGrid> */}

                    <Title level="3">Пока не участвуют в челлендже  </Title>
                    <Subhead level="1" className="greyText" weight="regular">Вы можете пригласить друзей: вместе интереснее</Subhead>
                </Div>
                <CardScroll size="s">
                    {mockFriends.length ?
                        mockFriends.map((friend, index) => {
                            if (index < 5)
                                return (
                                    <Card mode="outline" className="challenge--card-warp" key={index}>
                                        <Avatar className="centered" shadow={false} style={{ marginTop: 16 }} size={80} src={friend.photo_50} />
                                        <Caption level="1" weight="semibold" style={{ marginTop: 10, height: 35, padding: '0 2%' }}>  {friend.first_name} <br /> {friend.last_name}</Caption>
                                        <Button style={{ marginTop: 7 }}>Пригласить</Button>
                                    </Card>
                                )
                        }) : ''
                    }
                    <Card mode="outline" className="challenge--card-warp other-friend-invite" onClick={() => openModal(MODAL_FRIENDS_INVITE)}>
                        <Avatar className="centered" shadow={false} style={{ marginTop: 16 }} size={80} src={otherFriendImg} />
                        <Caption level="1" weight="semibold" style={{ marginTop: 10, height: 35, padding: '0 16%' }}>Пригласить
                            других друзей</Caption>
                    </Card>
                </CardScroll>
                <Div style={{ paddingTop: 0 }}>
                    <Card mode="outline" className="customCard">
                        <Title level="3" weight='semibold' style={{ marginBottom: 16 }}>Друзья в челлендже <span onClick={() => openModal(MODAL_FRIENDS_ALL)} className="span float">Ещё</span></Title>
                        {mockFriends.length ?
                            mockFriends.map((friend, index) => {
                                if (index < 3)
                                    return (
                                        <SimpleCell
                                            disabled
                                            style={{ paddingLeft: 0, paddingRight: 0 }}
                                            key={index}
                                            before={<Avatar size={48} src={friend.photo_50} />}
                                            after={<span className="bubble div-centered hello-bubble">&#128400;</span>}
                                        >{friend.first_name} {friend.last_name}</SimpleCell>
                                    )
                            }) : ''
                        }
                        <Button stretched size="m" mode="secondary" style={{ marginTop: 23, marginBottom: 2 }}>Чат с друзьями</Button>
                    </Card>

                    <Card mode="outline" className="customCard">
                        <Title level="3" weight='semibold' style={{ marginBottom: 16 }}>Задания челленджа <span className="span float" onClick={() => openModal(MODAL_CHALLENGE_TASKS)}>Ещё</span></Title>

                        {challengeData && challengeData['tasks'] ?
                            challengeData['tasks'].map((task, index) => {
                                return (
                                    <SimpleCell
                                        key={index}
                                        style={{ marginBottom: 7, marginLeft: '-17px', width: '100%' }}
                                        before={<Avatar size={50} style={{ borderRadius: 12 }} src={task.image} className="smallRadius" />}
                                        after={task.closed ? <Icon16Lock fill="#B8C1CC" width={24} height={20} /> : ''}>
                                        <div><Subhead weight="regular" className="greyText">{task.descr}</Subhead>
                                            {task.title}</div>
                                    </SimpleCell>
                                )
                            }) : ''
                        }
                    </Card>


                    {/* <Card mode="outline" className="customCard" style={{ textAlign: 'center' }}>
                        <span className="bubble div-centered bubble-single">  <Icon32Discussions fill="#9062FF" /></span>

                        <Title level="3" style={{ marginBottom: 5, textAlign: 'center', marginTop: 15 }}>Чат челленджа</Title>
                        <Text level="1" weight="regular" className="greyText">Обсуждайте со всеми участниками челленджа свои успехи</Text>
                        <Button stretched size="m" mode="secondary" style={{ marginTop: 16, marginBottom: 2 }}>Открыть чат</Button>
                    </Card> */}
                </Div>
            </Group>
        </Panel >
    )

}

export default ChallengeWaiting;
