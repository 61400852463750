
import { apiBaseUri} from '../environment';
import axiosApi from './axiosApi';

// const axiosApi = new axiosApi();

export default class AuthService {
	
	setToken(token) { 
		axiosApi.interceptors.request.use(config => {
			config.headers.common['Authorization'] = token;
			return config;
		  });
	}


	login(access_token, vk_user_id, gender) {
		return axiosApi.post(`${apiBaseUri}/login/vkma`, {
			access_token,
			vk_user_id,
			gender
		})  
	};
}
