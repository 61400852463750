import React, { Component } from 'react';

export default class AlertIcon extends Component {

  render() {
    return (
      <div className="ErrorImage" style={{textAlign: 'center'}}>
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M13.5607 1.31802C14.4046 0.474108 15.5492 0 16.7426 0H32.2574C33.4508 0 34.5954 0.474102 35.4393 1.31801L47.182 13.0607C48.026 13.9046 48.5001 15.0492 48.5001 16.2426V31.7574C48.5001 32.9508 48.026 34.0954 47.182 34.9393L35.4394 46.682C34.5955 47.5259 33.4509 48 32.2574 48H16.7427C15.5492 48 14.4046 47.5259 13.5607 46.682L1.81803 34.9393C0.97411 34.0954 0.5 32.9508 0.5 31.7574V16.2426C0.5 15.0492 0.974105 13.9046 1.81802 13.0607L13.5607 1.31802ZM16.7426 3C16.3448 3 15.9633 3.15804 15.682 3.43934L3.93934 15.182C3.65804 15.4633 3.5 15.8448 3.5 16.2426V31.7574C3.5 32.1552 3.65804 32.5367 3.93934 32.818L15.682 44.5607C15.9633 44.842 16.3449 45 16.7427 45H32.2574C32.6552 45 33.0368 44.842 33.3181 44.5607L45.0607 32.818C45.342 32.5367 45.5001 32.1552 45.5001 31.7574V16.2426C45.5001 15.8448 45.342 15.4633 45.0607 15.182L33.318 3.43934C33.0367 3.15803 32.6552 3 32.2574 3H16.7426Z" fill="#885EED" />
          <path d="M26.5 32C26.5 30.8954 25.6046 30 24.5 30C23.3954 30 22.5 30.8954 22.5 32C22.5 33.1046 23.3954 34 24.5 34C25.6046 34 26.5 33.1046 26.5 32Z" fill="#885EED" />
          <path d="M26 14C26 13.1716 25.3284 12.5 24.5 12.5C23.7203 12.5 23.0796 13.0949 23.0069 13.8555L23 14V25C23 25.8284 23.6716 26.5 24.5 26.5C25.2797 26.5 25.9204 25.9051 25.9931 25.1445L26 25V14Z" fill="#885EED" />
        </svg>

      </div>
    );
  }
}
