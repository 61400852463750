import React, { useState, useEffect, useRef } from 'react';
import '@vkontakte/vkui/dist/vkui.css';
import { View, Panel, Group, ActionSheet, Text, Title, Button, Div, PanelHeader, RichCell, PanelHeaderBack } from '@vkontakte/vkui';
import trenerAvatar from '../../img/trenerAvatar2.jpg';
import EpicNavBar from '../../components/EpicNavBar/EpicNavBar';
import TrainingCardsBlock from '../../components/TrainingCardsBlock/TrainingCardsBlock';
import { Icon24ChevronCompactRight } from '@vkontakte/icons';
import './Catalog.css';
import ArticlePanel from './ArticlePanel';
import bridge from '@vkontakte/vk-bridge';
import FaqListPanel from './FaqListPanel';
import FaqItemPanel from './FaqItemPanel';
import CatalogService from '../../services/CatalogService';
import { Icon16Cancel } from '@vkontakte/icons';
import { useLocation, useRouter, withRouter } from '@happysanta/router';
import { MODAL_CATALOG_TRENER, PAGE_CATALOG, PAGE_CATALOG_ARTICLE, PAGE_CATALOG_FAQ, PAGE_CATALOG_FAQLIST, PANEL_CATALOG, PANEL_CATALOG_ARTICLE, PANEL_CATALOG_FAQ, PANEL_CATALOG_FAQLIST, VIEW_CATALOG } from '../../routing/routes';
import Skeleton from 'react-loading-skeleton';
import getErrorStatus from '../../components/tools/errorStatus';
import { disableSystemSwipeBack, enableSystemSwipeBack } from '../../components/modules/AppModule';

const catalogService = new CatalogService();

const Catalog = ({ viewId, changeMainNav, history, activePanel, onSwipeBack, isPremium, amplitudeEvent, setErrorView, closeApp }) => {
    // const [activePanel, setActivePanel] = useState('catalog'); 
    const [articleId, setArticleId] = useState(0);
    // const [history, setHistory] = useState([PANEL_CATALOG]);
    const [articleList, setArticleList] = useState([]);
    const [faqList, setFaqList] = useState([]);
    const [faqPopular, setFaqPopular] = useState([]);
    const [dataItem, setDataItem] = useState(null);
    const baseTargetRef = useRef(null)
    const router = useRouter();
    const location = useLocation();

    useEffect(() => {
        router.replacePage(PAGE_CATALOG)
        // router.replacePage(PAGE_CATALOG)
        async function fetchData() {
            try {
                catalogService.getAdvice().then((data) => {
                    if (data === 'offline') return setErrorView(data)
                    setFaqList(data.data.faq_groups)
                    setArticleList(data.data.articles)
                    setFaqPopular(data.data.faq_popular)
                }).catch(error => { console.log('catalogService--error ', error); setErrorView(getErrorStatus(error)); })
            } catch (error) {
                console.log('Catalog', error)
                setErrorView(getErrorStatus(error));
            }
        }
        fetchData();
    }, [])

    const historyBack = () => {
        const localHistory = location.getViewHistory(VIEW_CATALOG);
        localHistory.pop();
        const activePanel = localHistory[localHistory.length - 1];
        if (activePanel === PANEL_CATALOG) {
            bridge.send('VKWebAppEnableSwipeBack');
        }
        router.popPage()
    }



    const setPanel = e => {
        const currentActivePanel = e.currentTarget ? e.currentTarget.dataset.to : e;
        const localHistory = [...history];
        localHistory.push(currentActivePanel);
        if (currentActivePanel === 'catalog') {
            bridge.send('VKWebAppDisableSwipeBack');
        }
        // setHistory(localHistory);
        if (e.currentTarget.dataset.id === 'faq')
            setDataItem(
                {
                    title: e.currentTarget.dataset.title,
                    text: e.currentTarget.dataset.text
                }
            );
        if (e.currentTarget.dataset.id === 'article') {
            setArticleId(e.currentTarget.dataset.article_id)
            const newArray = articleList.map(article => {
                if (article.article.id === +e.currentTarget.dataset.article_id) {
                    return {
                        'article': article.article,
                        'read_count': article.read_count + 1
                    }
                }
                return article;
            })
            setArticleList([...newArray])
        }
        history.push(currentActivePanel)
        // setActivePanel(currentActivePanel)
    };

    const popout = (() => {
        if (location.getPopupId() === MODAL_CATALOG_TRENER) {
            return (
                <ActionSheet
                    onClose={() => router.popPage()}
                    toggleRef={baseTargetRef.current}
                >
                    <span style={{ top: 16, right: 16 }} className="close floatClose" onClick={() => router.popPage()}> <Icon16Cancel fill="#818C99"></Icon16Cancel> </span>
                    <Div className="trener-popout" style={{ textAlign: 'center', padding: 6, paddingBottom: 14 }}>
                        <img src={trenerAvatar} width={72} height={72} style={{ marginTop: 6, marginBottom: 8, borderRadius: '50%' }} />
                        <Title level="2" weight="semibold" style={{ marginBottom: 8 }}>Анастасия Володина</Title>
                        <Text weight="regular" className="greyText" style={{ marginBottom: 15, textAlign: 'left' }}>20 лет опыта <br />
                            Тренер высшей категории <br />
                            Автор тренировочных методик <br />
                            Лектор и презентер международных фитнес-конвенций <br />
                            <strong style={{ fontWeight: 500, display: 'block', marginTop: 16 }}>Как спросить тренера?</strong>
                            Убедитесь, что интересующая вас тема не поднимается в <a style={{ textDecoration: 'underline' }} onClick={() => { nextPage(PAGE_CATALOG_FAQLIST) }} data-to="faqListPanel">“Популярных вопросах”</a>
                            <br /><br />
                            Здорово, если ваш вопрос будет кратким, конкретным и уместится в одном сообщении. Тренер ответит в течение двух дней.</Text>
                        <a onClick={() => { router.popPage(); amplitudeEvent("advice_coach_ask", {}) }} href="https://vk.com/im?sel=-206528584" style={{ textDecoration: 'none' }}><Button stretched size="l" >Задать вопрос</Button></a>
                    </Div>
                </ActionSheet>
            )
        }
    })();
    const openPopout = e => {
        amplitudeEvent("advice_coach_open", {})
        router.pushPopup(MODAL_CATALOG_TRENER);
    }


    const changeFaq = (data) => {
        window.scrollTo(0, 0)
        amplitudeEvent("advice_faq_open", { "faq_id": data.id })
        setDataItem(
            {
                title: data.title,
                text: data.text
            }
        );
    }

    const nextPage = (nextPage, data) => {
        disableSystemSwipeBack()
        router.replacePopup(null)
        if (data) {
            if (data.title && data.text)
                amplitudeEvent("advice_faq_open", { "faq_id": data.id })
            setDataItem(
                {
                    title: data.title,
                    text: data.text
                }
            );
            if (data.type && data.type === 'article') {

                const newArray = articleList.map(article => {
                    if (article.article.id === +data.id) {
                        return {
                            'article': article.article,
                            'is_read': true
                        }
                    }
                    return article;
                })
                setArticleList([...newArray])
            }
            if (data.id) {
                return router.pushPage(nextPage, { id: (data.id).toString() })
            }
        }
        (nextPage === PAGE_CATALOG) ? router.popPageTo(PAGE_CATALOG) : router.pushPage(nextPage)
    }

    const getPanelProps = (panelId) => {
        return {
            id: panelId,
            goBack: historyBack,
            setErrorView: setErrorView,
            nextPage: nextPage,
            history: history,
            amplitudeEvent: amplitudeEvent,
            setPanel: setPanel,
        };
    };



    return (
        // <Root activeView='Catalog'>
        <View
            popout={popout}
            history={location.state.history}
            id={viewId}
            activePanel={activePanel ? activePanel : PANEL_CATALOG}
            onSwipeBack={onSwipeBack}>
            <Panel id={PANEL_CATALOG} >
                <PanelHeader separator={false} left={<PanelHeaderBack onClick={() => closeApp()} />}  >Тренировки </PanelHeader>
                <EpicNavBar navEvent={changeMainNav} currentActive={PAGE_CATALOG}></EpicNavBar>
                <div className="m-t-50">
                    {(articleList && articleList.length) ?
                        <TrainingCardsBlock title="Статьи" setPanel={setPanel} clickEvent={nextPage} panelToSingle={PAGE_CATALOG_ARTICLE} list={articleList}></TrainingCardsBlock>
                        : <TrainingCardsBlock title="Статьи" skeleton={true}></TrainingCardsBlock>
                    }
                    < Group className="customGroup" separator="hide"  >
                        <Div style={{ paddingTop: 4 }}>
                            <Title className="titleFlexBox" level="3" weight="semibold" style={{ marginBottom: 0 }} onClick={() => nextPage(PAGE_CATALOG_FAQLIST)}>Популярные вопросы
                                {faqPopular.length ? <Icon24ChevronCompactRight className='showAllWorkout' fill={'#C4C8CC'} /> : ''}
                            </Title>
                        </Div>
                        {(faqPopular && faqPopular.length) ?
                            faqPopular.map((item, index) => {
                                if (index > 6) return false;
                                return <RichCell
                                    key={index}
                                    onClick={() => nextPage(PAGE_CATALOG_FAQ, { id: item.id, text: item.text, title: item.title, panel: PANEL_CATALOG_FAQ })}
                                    data-id="faq"
                                    data-title={item.title}
                                    data-text={item.text}
                                    data-to="faqItemPanel"
                                    separator="hide"
                                    className="customCell"
                                    multiline
                                    after={<Icon24ChevronCompactRight fill={'#C4C8CC'} />}
                                > {item.title}
                                </RichCell>
                            })
                            :
                            <Div>
                                <Skeleton width={'90%'} /> <br />
                                <Skeleton width={150} /> <br /><br />
                                <Skeleton width={'90%'} /> <br />
                                <Skeleton width={150} /> <br /><br />
                                <Skeleton width={'90%'} /> <br />
                                <Skeleton width={150} /> <br /><br />
                            </Div>
                        }
                    </Group>
                    {/* {(articleList && articleList.length) ?
                        isPremium ? 
                        <Group className="customGroup" separator="hide"  >
                            <Div style={{ textAlign: 'center' }}>
                                <img src={trenerAvatar} width={72} height={72} style={{ borderRadius: '50%' }} />
                                <Title level="2" weight="semibold" style={{ marginBottom: 8 }}>Спросите тренера</Title>
                                <Text weight="regular" style={{ color: '#818C99', marginBottom: 15 }}>Если у вас остались вопросы, вы можете задать их тренеру — Анастасии Володиной</Text>
                                <Button stretched size="l" onClick={openPopout}>Узнать больше</Button>
                            </Div>
                        </Group>    
                        : ''        
                    : ''} */}
                </div>
            </Panel >
            <ArticlePanel {...getPanelProps(PANEL_CATALOG_ARTICLE)} articleId={articleId}></ArticlePanel>
            <FaqListPanel {...getPanelProps(PANEL_CATALOG_FAQLIST)} faqList={faqList}  ></FaqListPanel>
            <FaqItemPanel {...getPanelProps(PANEL_CATALOG_FAQ)} dataItem={dataItem}  changeFaq={changeFaq} faqList={faqList} faqPopular={faqPopular} faqIndex={(dataItem && dataItem.id) || location.getParams().id}></FaqItemPanel>
        </View >
        // </Root>
    )

}

export default withRouter(Catalog);
