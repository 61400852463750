import React, { Component } from 'react';

export default class ImageVk extends Component {

  render() {
    return (
      <div className="ErrorImage">
      <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M37.0867 0C52.7667 0 56.5 3.73333 56.5 19.4133V36.5867C56.5 52.2667 52.7667 56 37.0867 56H19.9133C4.23333 56 0.5 52.2667 0.5 36.5867V19.4133C0.5 3.73333 4.23333 0 19.9133 0H37.0867Z" fill="#2787F5"/>
        <path d="M15.083 17.5H10.9996C9.83296 17.5 9.59961 18.0492 9.59961 18.6548C9.59961 19.7364 10.984 25.1006 16.0453 32.1952C19.4196 37.0403 24.1736 39.6667 28.4996 39.6667C31.0952 39.6667 31.4163 39.0833 31.4163 38.0786V34.4167C31.4163 33.25 31.6622 33.0172 32.4841 33.0172C33.0897 33.0172 34.128 33.32 36.5506 35.656C39.3192 38.4246 39.7756 39.6667 41.333 39.6667H45.4163C46.583 39.6667 47.1663 39.0833 46.8298 37.9322C46.4615 36.7848 45.1397 35.1202 43.3857 33.1469C42.4339 32.0222 41.0064 30.811 40.5737 30.2052C39.9681 29.4266 40.1411 29.0805 40.5737 28.3884L40.6464 28.2853C41.2617 27.4108 45.5858 21.2103 46.0678 19.001C46.3273 18.1358 46.0678 17.5 44.833 17.5H40.7496C39.7114 17.5 39.2327 18.0492 38.9731 18.6548C38.9731 18.6548 36.8966 23.7163 33.9549 27.004C33.0032 27.9557 32.5706 28.2585 32.0515 28.2585C31.7919 28.2585 31.4162 27.9557 31.4162 27.0906V19.001C31.4162 17.9627 31.1149 17.5 30.2496 17.5H23.833C23.1841 17.5 22.7939 17.9819 22.7939 18.4386C22.7939 19.4228 24.2647 19.6498 24.4163 22.4185V28.4316C24.4163 29.75 24.1782 29.989 23.6591 29.989C22.2748 29.989 18.9076 24.905 16.9105 19.0875C16.5191 17.9567 16.1265 17.5 15.083 17.5Z" fill="white"/>
      </svg>
      </div>
    );
  }
}
