import React from 'react';
import { Panel, PanelHeader, PanelHeaderBack  } from '@vkontakte/vkui';
import TrainingCardsBlock from '../../../components/TrainingCardsBlock/TrainingCardsBlock';
import {  PAGE_TRAINING_CATALOG_SINGLE, PAGE_TRAINING_MAIN } from '../../../routing/routes'; 
 
const TrainingCatalogPanel = ({ id, setPanel, catalog, section = false, goBack, nextPage, amplitudeEvent, openPaywallModal, isPremium }) => { 
    return (
        <Panel id={id}>
            <PanelHeader separator={false} left={<PanelHeaderBack onClick={goBack} data-to="trainingDayPanel" />} >Каталог</PanelHeader>

            {catalog && catalog.map((item, index) => (
                <TrainingCardsBlock
                    key={index}
                    keycatalog={item.id}
                    isFree={isPremium || item.is_free}
                    amplitudeEvent={amplitudeEvent}
                    source={"main_screen"}
                    title={item.title}
                    groupId={item.id}
                    clickEvent={nextPage}
                    optionalEvent={openPaywallModal}
                    setPanel={setPanel}
                    panelToFull={PAGE_TRAINING_CATALOG_SINGLE}
                    panelToSingle={PAGE_TRAINING_MAIN}
                    list={item.workouts}
                />

            ))}

        </Panel>
    )
};
export default TrainingCatalogPanel;
