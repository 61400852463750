import React, { useEffect, useState } from 'react';
import { Title, Button, Div, Text, FormLayout, Radio, Counter, Caption, FixedLayout, Snackbar } from '@vkontakte/vkui';
import {
    Icon20Check, Icon16Cancel
} from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge';
import { BridgePlus } from '@happysanta/bridge-plus';
import { Icon16ErrorCircleFill } from '@vkontakte/icons';
import { isLocalDev } from '../../environment';
const store = {
    "product_1": "workouts.1month.premium.subscription",
    "product_2": "workouts.3month.premium.subscription",
    "product_3": "workouts.12month.premium.subscription",
    "product_test": "workouts.1month.premium.subscription.test",
}

const Paywall = ({ closeEvent, clickEvent, isPage = false, amplitudeEvent, source, successEvent }) => {
    const [activeProduct, setActiveProduct] = useState(store["product_2"]);
    const [subFreeStatus, setSubFreeStatus] = useState(false);
    const [snackbarState, setSnackbarState] = useState(null)

    useEffect(() => {
        BridgePlus.subscribe('VKWebAppMakeInAppPurchase', (data) => { console.log('Event purchase subscribe', data) })
        bridge.subscribe('VKWebAppMakeInAppPurchaseResult', (data) => { console.log('VKWebAppMakeInAppPurchaseResult ', data) })
    }, []);

    const payTest = async () => {
        // closeEvent();
        // successEvent();
        amplitudeEvent("paywall_button", { "value_choose": "payload", "source": source })
        const product_name = activeProduct + ((subFreeStatus && activeProduct !== store["product_test"]) ? '.trial' : '')
        if (isLocalDev) return showError() 
        try {
            const data = await bridge.send('VKWebAppMakeInAppPurchase', {
                "merchant_product_id": product_name,
                "purchase_type": "mini_app_subs"
            })
            console.log('success', data)
            if (data) {
                closeEvent();
                successEvent();
            }
        } catch (error) {
            showError()
            console.log('error', error)
        }
    }

    const showError= () => {
        setSnackbarState(
            <Snackbar
                className="snackbar--error"
                onClose={() => setSnackbarState(null)}
                before= {<Icon16ErrorCircleFill />}
            > Произошла неизвестная ошибка </Snackbar>
        )
    }

    return (
        <>
            <Div style={{ backgroundColor: 'inherit', paddingBottom: 90, marginTop: isPage ? 37 : 0 }}>
                <span className={"close floatClose"} onClick={closeEvent} style={{top: 30}} > <Icon16Cancel fill="#818C99"></Icon16Cancel></span>
                <Div className="payWallHeaderWrap">
                    <Title level="2" weight="semibold" style={{ marginBottom: '3.4vh', marginTop: 6, fontSize: 22, textAlign: 'center', paddingRight: 9 }}>Больше возможностей в подписке Premium </Title>
                    <Text className="flexBox flexStart flexContentCenter" style={{ fontSize: 15, color: '#818C99', paddingRight: '9%', marginBottom: '2vh' }}>
                        <Icon20Check height="35" width="26" fill="#9062FF" style={{ marginRight: 10 }} />
                        1 300 упражнений и сотни тренировок
                    </Text>
                    <Text className="flexBox flexStart flexContentCenter" style={{ fontSize: 15, color: '#818C99', paddingRight: '9%', marginBottom: '2vh' }}>
                        <Icon20Check height="35" width="26" fill="#9062FF" style={{ marginRight: 10 }} />
                        Индивидуальная программа тренировок
                    </Text>
                    <Text className="flexBox flexStart flexContentCenter" style={{ fontSize: 15, color: '#818C99', paddingRight: '9%', marginBottom: '2vh' }}>
                        <Icon20Check height="35" width="26" fill="#9062FF" style={{ marginRight: 10 }} />
                        Обратная связь от тренера
                    </Text>
                </Div>
                <FormLayout style={{ width: '100%', marginTop: 10, paddingTop: 10, borderTop: 'thin solid rgb(166 166 166 / 37%)' }}>
                    <div className="flexBox flexContentCenter">
                        <Text weight="semibold">Сомневаетесь?  <br />
                            Попробуйте бесплатно</Text>
                        <label className="custom-thumbler">
                            <input className="thumbler-input" type="checkbox" name="free" onClick={() => { setSubFreeStatus(!subFreeStatus); amplitudeEvent("paywall_button", { "value_choose": "trial change", "source": source }) }} />
                            <span className="thumbler-content"> </span>
                        </label>
                    </div>
                </FormLayout>
                <FormLayout className="modalOfferForm " style={{ marginTop: '2vh' }}>
                    <Radio defaultChecked name="offer" className="custom-radio offer-radio" value={store["product_test"]} onChange={() => setActiveProduct(store["product_test"])} >
                        <div className="offer-radio-content">
                            <Caption level="1" weight="bold" style={{ marginBottom: 3 }}>ТЕСТ</Caption>
                            <Text style={{ letterSpacing: 0.6 }}>1₽ <br /> в месяц</Text>
                        </div>
                    </Radio>
                    <Radio name="offer" className="custom-radio offer-radio" value={store["product_1"]} onChange={() => setActiveProduct(store["product_1"])} >
                        <div className="offer-radio-content">
                            <Caption level="1" weight="bold" style={{ marginBottom: 3 }}>1 МЕСЯЦ</Caption>
                            <Text style={{ letterSpacing: 0.6 }}>379₽ <br /> в месяц</Text>
                        </div>
                    </Radio>
                    <Radio name="offer" defaultChecked className="custom-radio offer-radio" value={store["product_2"]} onChange={() => setActiveProduct(store["product_2"])} >
                        <div className="offer-radio-content">
                            <span className="offer-label">ПОПУЛЯРНО</span>
                            <Caption className={subFreeStatus ? '' : 'hide'} level="3" weight="bold" style={{ color: '#FF0084', marginBottom: 8, marginTop: 9 }}>30 ДНЕЙ БЕСПЛАТНО</Caption>
                            <Caption level="1" weight="bold" style={{ marginBottom: 3 }}>3 МЕСЯЦА</Caption>
                            <Text style={{ letterSpacing: 0.6 }}>649₽</Text>
                            <Caption level="1" weight="regular" style={{ marginTop: 6 }}>216₽ в месяц</Caption>
                            <Counter className="customCounter" style={{ backgroundColor: '#FF0084', margin: '16px 0 3px 0', height: 19 }}>СКИДКА 43%</Counter>
                        </div>
                    </Radio>
                    <Radio name="offer" className="custom-radio offer-radio" value={store["product_3"]} onChange={() => setActiveProduct(store["product_3"])} >
                        <div className="offer-radio-content">
                            <Caption className={subFreeStatus ? '' : 'hide'} level="3" weight="bold" style={{ color: '#FF0084', marginBottom: 8, marginTop: 9 }}>30 ДНЕЙ БЕСПЛАТНО</Caption>
                            <Caption level="1" weight="bold" style={{ marginBottom: 3 }}>ГОД</Caption>
                            <Text style={{ letterSpacing: 0.6 }}>1 390₽    </Text>
                            <Caption level="1" weight="regular" style={{ marginTop: 6 }}>116₽ в месяц</Caption>
                            <Counter className="customCounter" style={{ backgroundColor: '#FF0084', margin: '16px 0 3px 0', height: 19 }}>СКИДКА 70%</Counter>
                        </div>
                    </Radio>
                </FormLayout>
                {/* <Title level="3" weight="regular" style={{ textAlign: "center", color: "#9062FF", marginTop: 20 }} >Восстановить покупки</Title> */}
                <Caption level="3" style={{ color: "#818C99", marginTop: 23, textAlign: 'center' }}>Нажимая кнопку «Попробовать», вы соглашаетесь
                    <a href="https://doc.f-365.ru/trenirovki-vk/rules.pdf" rel="noreferrer" target="_blank" style={{ color: "#9062FF" }}> с Правилами использования Сервиса</a> и <a href="https://doc.f-365.ru/trenirovki-vk/policy.pdf" rel="noreferrer" target="_blank" style={{ color: "#9062FF" }}>Правилами защиты информации о Пользователях Сервиса</a> </Caption>
                <Caption level="3" style={{ color: "#9062FF", marginTop: 17, textAlign: 'center' }} onClick={clickEvent}>Узнать об автопродлении подписки    </Caption>
            </Div>

            <FixedLayout vertical="bottom" style={{ boxShadow: '0px -0.5px 0px rgba(0, 0, 0, 0.12)', backgroundColor: 'white' }}>
                <Div>
                    <Button onClick={payTest} data-to="Training" stretched size="l">Попробовать {(subFreeStatus && activeProduct > 1) ? 'бесплатно' : ''}</Button>
                </Div>
            </FixedLayout>

            {snackbarState}
        </>
    );
}


export default Paywall;