import React, { useState, useEffect, useRef } from 'react';
import './Challenges.css';
import { Cell, Group, Title, Div, UsersStack, Subhead, Button, Header, SimpleCell, Text, Panel, Caption, Avatar } from '@vkontakte/vkui';
import { Icon24CheckCircleOn, Icon28FireOutline, Icon24ClockOutline, Icon28ArticleOutline } from '@vkontakte/icons';
import ChallengeHeader from '../../components/ChallengeHeader/ChallengeHeader';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { MODAL_CHALLENGE_TASKS, PAGE_TRAINING, VIEW_TRAINING } from '../../routing/routes';
const ChallengeStart = ({ id, openPopout, openModal, changeMainNav, challengeTasks, challengeData }) => {
    return (
        <Panel id={id} className="safety-padding-bottom">
            <Group className={"customGroup padding--top--0 challengeStarted " + challengeData.banner_theme} separator="hide" >
                <ChallengeHeader challengeData={challengeData} type={"challengeStart"} exitEvent={() => changeMainNav({ view: VIEW_TRAINING, page: PAGE_TRAINING })} />

                <Div separator='hide' className='no-separator' style={{ marginBottom: 5 }} >
                    <Title style={{ margintTop: 6, marginBottom: 5 }} level='2' weight='semibold'>Задачи на сегодня  <span className='greyText'>1/3</span></Title>
                    <Text weight='regular' className='greyText'>Поздравляю! Уже половина челленджа позади, вы с нами, а это значит, что кто-то у нас молодец!  💪💪</Text>
                    <div className='friends--bubble small'>
                        <UsersStack photos={['', '', '']}> <Subhead weight='regular'> уже выполнили 34 536 человек</Subhead></UsersStack>
                    </div>
                </Div>
                <Caption level='2' weight='semibold' style={{ paddingLeft: 16 }} className='greyText text--uppercase letter-spacing'>Шаги</Caption>
                <Cell style={{ marginBottom: 11 }} after={<Icon24CheckCircleOn fill="#7C46FF" />}
                    before={<Avatar size={56} style={{ borderRadius: 12 }} src={challengeData.preview} className="smallRadius content-width-100" />}>
                    <div style={{ width: '100%' }}>
                        <Text weight='regular' style={{ fontSize: 16 }} >Пройдите 7000 шагов</Text>
                        <ProgressBar clickEvent={() => { }} progress={70} height={4} />
                        <Subhead weight='regular' className='greyText'>5 634 из 7000 </Subhead>
                    </div>
                </Cell>
                <Caption level='2' weight='semibold' style={{ paddingLeft: 16 }} className='greyText text--uppercase letter-spacing'>Тренировка</Caption>
                <Cell style={{ marginBottom: 11 }} after={<Icon24CheckCircleOn fill="#7C46FF" />}
                    before={<Avatar size={56} style={{ borderRadius: 12 }} src={challengeData.preview} className="smallRadius content-width-100" />}>
                    <div style={{ width: '100%' }}>
                        <Text weight='regular' style={{ fontSize: 16 }} >Осторожно, мокрый пол </Text>
                        <Subhead weight='regular' className='greyText flexBox flex-inline' style={{ marginRight: 10, marginTop: 4, marginLeft: '-2px' }}>
                            <Icon24ClockOutline fill='#99A2AD' style={{ marginRight: 3 }} height={20} weight={20} />
                            20 минут
                        </Subhead>
                        <Subhead weight='regular' className='greyText flexBox flex-inline' style={{ marginRight: 10, marginTop: 4, marginLeft: '-2px' }}>
                            <Icon28FireOutline fill='#99A2AD' style={{ marginRight: 3 }} height={20} weight={20} />
                            260 Ккал
                        </Subhead>
                    </div>
                </Cell>
                <Caption level='2' weight='semibold' style={{ paddingLeft: 16 }} className='greyText text--uppercase letter-spacing'>Тест</Caption>
                <Cell style={{ marginBottom: 11 }} after={<Icon24CheckCircleOn fill="#7C46FF" />}
                    before={<Avatar size={56} style={{ borderRadius: 12 }} src={challengeData.preview} className="smallRadius content-width-100" />}>
                    <div style={{ width: '100%' }}>
                        <Text weight='regular' style={{ fontSize: 16 }} >Осторожно, мокрый пол </Text>
                        <Subhead weight='regular' className='greyText flexBox flex-inline' style={{ marginRight: 10, marginTop: 4, marginLeft: '-2px' }}>
                            <Icon24ClockOutline fill='#99A2AD' style={{ marginRight: 3 }} height={20} weight={20} />
                            10 минут
                        </Subhead>
                        <Subhead weight='regular' className='greyText flexBox flex-inline' style={{ marginRight: 10, marginTop: 4, marginLeft: '-2px' }}>
                            <Icon28ArticleOutline fill='#99A2AD' style={{ marginRight: 3 }} height={20} weight={20} />
                            12 вопросов
                        </Subhead>
                    </div>
                </Cell>

            </Group>
            <Group className={"customGroup "} separator="hide" header={<Header className='letter-spacing'   >Чат челленджа</Header>}   >
                <Div style={{ paddingTop: 0 }}>
                    <Text weight='regular'>Обсуждайте со всеми участниками  челленджа свои успехи</Text>
                    <Button size="m" mode="secondary" className="semibold" style={{ marginTop: 16 }} >Открыть чат</Button>
                </Div>
            </Group>

            <Group className={"customGroup "} separator="hide" header={
                <Header className='letter-spacing'
                    aside={<Text weight='regular' style={{color: '#9062FF'}}  onClick={()  => openModal(MODAL_CHALLENGE_TASKS)}>Ещё</Text>} >
                    Задания челленджа</Header
                >}   >
                <SimpleCell
                    expandable
                    style={{ }}
                    before={<Avatar size={64} style={{ borderRadius: 12 }} src={""} className="smallRadius" />}
                >
                    <div>
                        <Subhead weight="regular" className="greyText">День 3</Subhead>
                        Название задания
                        <Caption level='2' className='letter-spacing' weight='medium' style={{ marginTop: 4, color: '#4BB34B' }}>Выполнено</Caption>
                    </div>
                </SimpleCell>
                <SimpleCell
                    expandable
                    style={{ }}
                    before={<Avatar size={64} style={{ borderRadius: 12 }} src={""} className="smallRadius" />}
                >
                    <div>
                        <Subhead weight="regular" className="greyText">День 3</Subhead>
                        Название задания
                        <Caption level='2' className='letter-spacing' weight='medium' style={{ marginTop: 4, color: '#FFB946' }}>В процессе</Caption>
                    </div>
                </SimpleCell>
                <SimpleCell
                    expandable
                    style={{ }}
                    before={<Avatar size={64} style={{ borderRadius: 12 }} src={""} className="smallRadius" />}
                >
                    <div>
                        <Subhead weight="regular" className="greyText">День 3</Subhead>
                        Название задания
                        <Caption level='2' className='letter-spacing' weight='medium' style={{ marginTop: 4, color: '#818C99' }}>Доступен предпросмотр</Caption>
                    </div>
                </SimpleCell>
            </Group>

        </Panel>
    )

}

export default ChallengeStart;
