import React, { useState } from 'react';
import { Panel, PanelHeader, Title, Div, PanelHeaderBack, RichCell, Group, Search, Footer } from '@vkontakte/vkui';
import { Icon24ChevronCompactRight } from '@vkontakte/icons';
import { withRouter } from '@happysanta/router';
import { PAGE_CATALOG, PAGE_CATALOG_FAQ } from '../../routing/routes';


const FaqListPanel = ({ setPanel, faqList, id, goBack, nextPage }) => {

    const [faqArray, setFaqArray] = useState(faqList ? faqList : []);
    const [searchState, setSearchState] = useState('');
    const searchChange = e => {  
        setSearchState(e.target.value)  
        const search = e.target.value.toLowerCase();
        let newArray = [];
        faqList.forEach(data => {
            let newItem = [] 
            data.faqs.map(item => {
                if ( item['title'].toLowerCase().indexOf(search) > -1 )  newItem.push(item);
            })
            if (newItem.length) newArray.push({'faqs': newItem, 'id': data.id, 'title': data.title})
            // console.log(newItem)
        })
        setFaqArray(newArray) 
    }

    return (
        <Panel id={id}>
            <PanelHeader separator={false} left={<PanelHeaderBack onClick={()=>nextPage(PAGE_CATALOG)}  data-to="catalog" />} >Популярные вопросы</PanelHeader>
            <Search value={searchState} onChange={searchChange} after={null} maxLength={30} />
            {faqArray.length ? (
                faqArray.map((item, index) => {
                    return <Group key={index}>
                        <Div>
                            <Title level="3" weight="semibold" style={{ fontSize: 17 }}  >{item.title}</Title>
                        </Div>
                        {
                            item['faqs'].map((item, index) => {
                                return <RichCell
                                    key={index}
                                    onClick={() => nextPage(PAGE_CATALOG_FAQ, { id: item.id, text: item.text, title: item.title })}
                                    data-id="faq"
                                    data-title={item.title}
                                    data-text={item.text}
                                    data-to="faqItemPanel"
                                    separator="hide"
                                    className="customCell"
                                    multiline
                                    after={<Icon24ChevronCompactRight fill={'#C4C8CC'}></Icon24ChevronCompactRight>}
                                > {item.title}
                                </RichCell>
                            })
                        }
                    </Group>
                })
            ) : <Footer>Ничего не найдено</Footer>}

        </Panel>
    )
}


export default withRouter(FaqListPanel);