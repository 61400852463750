import React, { useEffect } from 'react';
import { Panel, PanelHeader, Group, PanelHeaderBack } from '@vkontakte/vkui';
import TrainingPreview from '../../../components/TrainingPreview/TrainingPreview';
import TrainingDescription from '../../../components/TrainingDescription/TrainingDescription';
import TrainingCardsBlock from '../../../components/TrainingCardsBlock/TrainingCardsBlock';
import './TrainingDayPanel.css';
import EpicNavBar from '../../../components/EpicNavBar/EpicNavBar';
import { MODAL_PERMISSION, PAGE_CHALLENGES, PAGE_TRAINING, PAGE_TRAINING_CATALOG, PAGE_TRAINING_CATALOG_SINGLE, PAGE_TRAINING_MAIN, VIEW_CHALLENGES, VIEW_TRAINING } from '../../../routing/routes';
import AdvBanner from '../../../components/Banners/advert/AdvBanner';
import { useLocation, useRouter } from '@happysanta/router';
import { enableSystemSwipeBack } from '../../../components/modules/AppModule';
import { mockChallenges } from '../../../components/Mocks/mockChallenges';
import ChallengeBanner from '../../../components/Banners/challenges/ChallengeBanner';
import { initHash } from '../../../routing/router';
const TrainingDayPanel = ({ id, setPanel, setHomePanel, bufferActivePanel, startTesting, banner, fetchChallengesData, challengesData, isPremium, training, catalog, nextPage, changeMainNav, openPaywallModal, amplitudeEvent, fetchData, closeApp }) => {
    const router = useRouter();
    useEffect(() => {
        if (!training || training === null) {
            fetchData() 
        }
        // setTimeout(() => {
        //     openPaywallModal(MODAL_PERMISSION) 
        // }, 500);
        if (bufferActivePanel)
            setTimeout(() => {
                nextPage(PAGE_TRAINING_CATALOG_SINGLE)
            }, 200);

    }, [training])

    const getArrayFromObject = (object) => {
        let newArr = []
        Object.keys(object).map(array => {
            object[array].map(item => {
                item['challengeType'] = array;
                newArr.push(item)
            })
        })
        return newArr;
    }


    return (
        <Panel id={id} className="p-b-60 " >
            <PanelHeader separator={false} left={<PanelHeaderBack onClick={() => closeApp()} />}    >Тренировки</PanelHeader>
            <EpicNavBar navEvent={changeMainNav} currentActive={PAGE_TRAINING} {...(!training && { loading: 'true' })}></EpicNavBar>
            <div className="m-t-50 flex-collumn">
                <Group className="customGroup" separator="hide" style={{ paddingLeft: 8, paddingRight: 8 }}  >
                    <TrainingPreview setPanel={setPanel} setHomePanel={setHomePanel} data={training} showButton={true} fullView={false} buttonEvent={nextPage} optionEvent={() => {amplitudeEvent("workout_change");nextPage(PAGE_TRAINING_CATALOG)}} />
                    <TrainingDescription content={training} contentTitle={false} showDescription={false} />
                </Group>
                {/* {challengesData ?
                    getArrayFromObject(challengesData).map((challenge, index) => {
                        // return <ChallengeBanner challengeData={challenge} key={index} clickEvent={() => {router.pushPage(PAGE_CHALLENGES,{id:  +challenge.challenge_id}) ; changeMainNav({view: VIEW_CHALLENGES})}} />
                        return <ChallengeBanner challengeData={challenge} key={index} changeMainNav={changeMainNav} />
                    })
                    : ''
                } */}
                {banner ? <AdvBanner data={banner} changeMainNav={changeMainNav} startTesting={startTesting} amplitudeEvent={amplitudeEvent} /> : ''}
                {catalog && catalog.length ?
                    catalog.map((item, index) => (
                        <TrainingCardsBlock
                            key={index}
                            keycatalog={item.id}
                            isFree={item.is_free || isPremium}
                            amplitudeEvent={amplitudeEvent}
                            source={"main_screen"}
                            title={item.title}
                            groupId={item.id}
                            clickEvent={nextPage}
                            optionalEvent={openPaywallModal}
                            setPanel={setPanel}
                            panelToFull={PAGE_TRAINING_CATALOG_SINGLE}
                            panelToSingle={PAGE_TRAINING_MAIN}
                            list={item.workouts}
                        />
                    )) :
                    <TrainingCardsBlock skeleton={true} />
                }
            </div>
        </Panel>

    )
}

export default TrainingDayPanel;
