import  React, { useState } from 'react';
import { Panel, PanelHeader, Button, Caption, Group, Div, Title, Headline } from '@vkontakte/vkui';
import TrainingPreview from '../../../components/TrainingPreview/TrainingPreview';
import './TrainingCompletePanel.css';
import rateBad from '../../../img/smile-rate-3.svg'
import rateNormal from '../../../img/smile-rate-2.svg'
import rateSuper from '../../../img/smile-rate-1.svg'
import { PAGE_TRAINING } from '../../../routing/routes';
import TrainingService from '../../../services/TrainingService';
const trainingService = new TrainingService();



const TrainingCompletePanel = ({ id, training, nextPage, amplitudeEvent, completeTrainingId }) => {
    const [like, setLike] = useState(null);

    const trainingRate = (rate) => {
        setLike(rate)
        if (completeTrainingId) trainingService.updateTraining({ "complete_id": completeTrainingId, "rate": rate })
        amplitudeEvent("workout_reaction", {
            "reaction_id": rate.toString()
        })

    }
    return (
        <Panel id={id}>
            <PanelHeader separator={false}  >Тренировка</PanelHeader>
            <TrainingPreview data={training} />
            < Div >
                <Group separator="hide">
                    <Caption level="1" weight="semibold" className="greyText" caps style={{ marginBottom: 3, textAlign: 'center', }}>КАК ВАМ БЫЛА ЭТА ТРЕНИРОВКА?</Caption>
                    <div className="flexBox" style={{ marginTop: 22, justifyContent: 'center' }}>
                        <div className={"trainingRate " + (like === 'easy' ? 'active' : '')} onClick={() => trainingRate('easy')}>
                            <div className="rateImgWrap">
                                <div className="rateImgWrapInner">
                                    <embed type="image/svg+xml" src={rateSuper}   width="32" height="32" />
                                </div>
                            </div>
                            <Caption level="2" weight="regular">Легко</Caption>
                        </div>
                        <div className={"trainingRate " + (like === 'normal' ? 'active' : '')} onClick={() => trainingRate('normal')}>
                            <div className="rateImgWrap">
                                <div className="rateImgWrapInner">
                                    <embed type="image/svg+xml" src={rateNormal}  width="32" height="32" />
                                </div>
                            </div>
                            <Caption level="2" weight="regular">Нормально</Caption>
                        </div>
                        <div className={"trainingRate " + (like === 'hard' ? 'active' : '')} onClick={() => trainingRate('hard')}>
                            <div className="rateImgWrap">
                                <div className="rateImgWrapInner">
                                    <embed type="image/svg+xml" src={rateBad}   width="32" height="32" />
                                </div>
                            </div>
                            <Caption level="2" weight="regular">Трудно</Caption>
                        </div>
                    </div>
                </Group>
                {completeTrainingId ?
                    training.task.score && (
                        <Group separator="show " style={{ marginTop: 25, borderTop: "thin solid #D7D8D9", paddingTop: 4 }}>
                            <Title level="3" weight="semibold" style={{ marginBottom: 21 }}>НАВЫКИ</Title>
                            <div className="flexBox flexBox-flexWrap" style={{ padding: 0 }}> 
                                    <div className="resultItem">
                                        <Caption level="2" weight="semibold" className="greyText" style={{ marginBottom: 5 }}>МОБИЛЬНОСТЬ</Caption >

                                        <p><span className="bigOne" style={{ color: '#59C7FF' }}>{training.task.score.mobility || 0}</span> мин</p>
                                    </div>  
                                    <div className="resultItem">
                                        <Caption level="2" weight="semibold" className="greyText" style={{ marginBottom: 5 }}>КАРДИО</Caption >
                                        <p><span className="bigOne" style={{ color: '#AB4BF5' }}>{training.task.score.cardio || 0}</span> мин</p>
                                    </div> 
                                    <div className="resultItem">
                                        <Caption level="2" weight="semibold" className="greyText" style={{ marginBottom: 5 }}>ГИБКОСТЬ</Caption >
                                        <p><span className="bigOne" style={{ color: '#FF6D96' }}>{training.task.score.flexibility || 0}</span> мин</p>
                                    </div> 
                                    <div className="resultItem">
                                        <Caption level="2" weight="semibold" className="greyText" style={{ marginBottom: 5 }}>СИЛА</Caption >
                                        <p><span className="bigOne" style={{ color: '#84C658' }}>{training.task.score.strength || 0}</span> мин</p>
                                    </div> 
                            </div>
                        </Group>
                    ) :
                    <Group separator="show " style={{ marginTop: 25, paddingTop: 4, marginBottom: 20 }}> 
                            <Headline className="text-grey" style={{textAlign: 'center'}} weight="medium">В следующий раз постарайтесь выполнить тренировку полностью</Headline> 
                    </Group>}


                {/* <Button stretched size="l" style={{ marginBottom: 10 }} onClick={() => nextPage(PAGE_TRAINING_SHARING)} data-to="trainingSharingPanel"  > Поделиться</Button> */}
                <Button stretched size="l"   style={{ marginBottom: 10 }} onClick={() => nextPage(PAGE_TRAINING)} data-to="trainingDayPanel" >Завершить тренировку </Button>
            </Div>
        </Panel>
    )
};
export default TrainingCompletePanel;
