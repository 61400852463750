import React, { } from 'react';
import './ProgressBar.css';


const ProgressBar = ({ clickEvent, progress, height }) => {
    return (
        <div className='progressBar' onClick={clickEvent} style={{ height: height }} >
            <div className="progressBar-inner" style={{ width: progress + `%` }}></div>
        </div>
    )
}

export default ProgressBar;