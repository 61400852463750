import React, { useState, useEffect } from 'react';
import { View, AppRoot, Root, Panel, useAppearance } from '@vkontakte/vkui';
import { withRouter, useLocation, useRouter } from '@happysanta/router';
import "@vkontakte/vkui/dist/vkui.css";
import AuthService from './services/AuthService';
import Onboarding from './containters/Onboarding/Onboarding';
import Training from './containters/Training/Training';
import Profile from './containters/Profile/Profile';
import './styles.css';
import { access_token_test, app_id, isLocalDev } from './environment';
import Catalog from './containters/Catalog/Catalog';
import PaywallPanel from './containters/Paywall/PaywallPanel';
import SystemPanelError from './components/SystemPanelError/SystemPanelError';
import { PAGE_CATALOG, PAGE_CATALOG_FAQLIST, PAGE_CHALLENGES, PAGE_ONBOARDING, PAGE_PAYWALL, PAGE_PROFILE, PAGE_TESTING, PAGE_TRAINING, PAGE_TRAINING_CATALOG_SINGLE, PANEL_CATALOG_FAQ, PANEL_TRAINING_CATALOG_SINGLE, PANEL_TRAINING_DAILY, VIEW_CATALOG, VIEW_CHALLENGES, VIEW_ONBOARDING, VIEW_PAYWALL, VIEW_PROFILE, VIEW_TESTING, VIEW_TRAINING } from './routing/routes';
import { initHash, initSearch } from './routing/router';
import amplitude from 'amplitude-js';
import bridge from '@vkontakte/vk-bridge';
import { BridgePlus, isUserError } from '@happysanta/bridge-plus';
import getErrorStatus from './components/tools/errorStatus';
import Testing from './containters/Testing/Testing';
import { COLOR_SCHEMES, disableSystemSwipeBack, enableSystemSwipeBack, systemSwipeBack } from './components/modules/AppModule';
import Challenges from './containters/Challenges/Challenges';
import paramsLinkParseAndRedirect from './components/tools/paramsLinkParseAndRedirect';
import { compareDates } from './components/tools/compareDates';

const App = () => {
	const authService = new AuthService();
	const [fetchedUser, setUser] = useState(null);
	const [error, setError] = useState(null)
	const [dayTraining, setDayTraining] = useState(null);
	const [banner, setBanner] = useState(null);
	const [vkData, setVkData] = useState(null);
	const [bufferActivePanel, setBufferActivePanel] = useState(null);
	const [isPremium, setPremium] = useState(false);
	const [activeView, setActiveView] = useState(VIEW_TRAINING);
	const [activePage, setActivePage] = useState(PAGE_TRAINING);
	const [testingData, setTestingData] = useState(null);
	const [challengesData, setChallengesData] = useState(null);
	const [backPanel, setBackPanel] = useState(VIEW_TRAINING);
	const [backPage, setBackPage] = useState(PAGE_TRAINING);
	const [catalog, setCatalog] = useState([]);
	const location = useLocation();
	const router = useRouter(); 

	useEffect(() => {
		// document.body.setAttribute('scheme', COLOR_SCHEMES.SPACE_GRAY);
		// document.body.classList.add("theme--space_gray");
		// window._appColorScheme = COLOR_SCHEMES.SPACE_GRAY;
		amplitudeEvent('app_open')
		BridgePlus.subscribe('VKWebAppViewRestore', (data) => {
			if (!dayTraining) {
				fetchData()
			} 
			// if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
			// 	document.body.setAttribute('scheme', COLOR_SCHEMES.SPACE_GRAY);
			// 	document.body.classList.add("theme--space_gray");
			// 	window._appColorScheme = COLOR_SCHEMES.SPACE_GRAY;
			// }
			console.log('VKWebAppViewRestore')
		})
	}, []);

	const closeApp = () => {
		BridgePlus.close('')
			.catch((error) => { console.warn(error); });
	}

	const AllowMessagesFromGroup = async (id) => {
		const currentTime = new Date(); 
		const storageData = await bridge.send("VKWebAppStorageGet", {"keys": ["NotifyPermissionAskTime"]})  
		const lastAskTime = (storageData && storageData.keys[0].value ) ? storageData.keys[0].value : 
							(localStorage.getItem("NotifyPermissionAskTime") ? localStorage.getItem("NotifyPermissionAskTime") : null ); 
		if (!lastAskTime
			|| (lastAskTime && compareDates.days(currentTime, new Date(lastAskTime)) >= 7)) { 
			bridge.send("VKWebAppAllowMessagesFromGroup", { "group_id": 207167398, "key": id });
			localStorage.setItem('NotifyPermissionAskTime', currentTime)
			bridge.send("VKWebAppStorageSet", {
				key: "NotifyPermissionAskTime",
				value: currentTime.toString()
			});
		}  
	}

	const fetchData = async () => {
		if (!isLocalDev)
			try {
				const user = await BridgePlus.send('VKWebAppGetUserInfo');  // Get User from VK bridge       
				setUser(user);
				const vkAccessToken = await BridgePlus.send("VKWebAppGetAuthToken", {
					"app_id": app_id,
					"scope": ""
				}); // get vkAccessToken  
				AllowMessagesFromGroup(user.id);
				if (!user || !vkAccessToken) return setErrorView({ errorText: 'VK Bridge request failed' })
				amplitude.getInstance().setUserId(user.id)
				setVkData({ id: user.id, vkToken: vkAccessToken.access_token })
				authService.login(vkAccessToken.access_token, user.id, 'man').then(
					(data) => {
						if (data === 'offline') return setErrorView({ errorStatus: 'Network Error' })
						authService.setToken(data.data.token)
						setPremium(true) //data.data.premium
						setBanner(data.data.banner ? { ...data.data.banner } : null)
						setChallengesData(data.data.shared_challenges ? { ...data.data.shared_challenges } : null)
						setCatalog(data.data.catalog ? [...data.data.catalog] : null)
						setDayTraining(data.data.daily ? { ...data.data.daily } : null)
						if (data.data.onboarding) {
							setActiveView(VIEW_ONBOARDING)
							router.replacePage(PAGE_ONBOARDING)
						} else {
							if (initHash) setTimeout(() => {
								router.pushPage(PAGE_TRAINING_CATALOG_SINGLE)
							}, 200);
							if (initSearch) setTimeout(() => {
								paramsLinkParseAndRedirect(initSearch, changeMainNav)
							}, 200);
						}
					}
				).catch(error => { console.log('authService--error ', error); setErrorView(getErrorStatus(error)); })  // Send vkAccessToken to auth and get daily training/catalog 

			} catch (error) {
				console.log('fetchData--error ', error)
				if (isUserError(error) || error.type === 'access_error' || error.type === 'api_error') changeMainNav({ page: PAGE_TRAINING, view: VIEW_TRAINING, error: true })//setErrorView({ errorStatus: 'not_permissions' })
				else {
					if (getErrorStatus(error)) { setErrorView(getErrorStatus(error)) }
					else if (!fetchedUser) { setErrorView({ errorStatus: 'Network Error' }) }
					else if (isUserError(error)) closeApp()
					else setErrorView(error)
				}
			}
		else
			try {
				// for local test   
				setVkData({ id: 15106634, vkToken: access_token_test })
				AllowMessagesFromGroup(15106634);
				authService.login(access_token_test, 15106634, 'man')
					.then((data) => {
						amplitude.getInstance().setUserId(15106634)
						if (data === 'offline') return setErrorView({ errorStatus: 'Network Error' })
						authService.setToken(data.data.token)
						// setPremium(data.data.premium)
						setPremium(true)
						setBanner(data.data.banner ? { ...data.data.banner } : null)
						setChallengesData(data.data.shared_challenges ? { ...data.data.shared_challenges } : null)
						setCatalog(data.data.catalog ? [...data.data.catalog] : null)
						setDayTraining(data.data.daily ? { ...data.data.daily } : null)
						if (data.data.onboarding) {
							setTimeout(() => {
								setActiveView(VIEW_ONBOARDING)
								router.replacePage(PAGE_ONBOARDING)
							}, 100);
						} else {
							if (initHash) setTimeout(() => {
								router.pushPage(PAGE_TRAINING_CATALOG_SINGLE)
							}, 100);
							if (initSearch) setTimeout(() => {
								paramsLinkParseAndRedirect(initSearch, changeMainNav)
							}, 100);
						}
						setUser({
							photo_200: '',
							first_name: 'Саша',
							last_name: 'Бортич'
						})
					}).catch((error) => {
						setErrorView(getErrorStatus(error))
					})
			} catch (error) {
				setErrorView(getErrorStatus(error));
			}
	}

	const amplitudeEvent = (name, data) => {
		// if (app_id === 7942588)
		amplitude.getInstance().logEvent(name, data ? data : {})
	}

	const changeMainNav = (data) => {
		enableSystemSwipeBack();
		if (!data) return goBack();
		if (data.page && data.current)
			amplitudeEvent("tab_navigation", { "to_tab": data.page, "from_tab": data.current })
		if (data.page) setActivePage(data.page)
		if (data.view) setActiveView(data.view)
		if (data.panel) {
			setBufferActivePanel(data.panel);
			setTimeout(() => {
				setBufferActivePanel(null)
			}, 100);
		}
		if (data.pushPage)
			setTimeout(() => {
				router.pushPage(data.pushPage, data.pageId ? { id: data.pageId } : null)
			}, 100)
		if (!data.noScrollTop) window.scrollTo(0, 0)
		// setTimeout(() => {
		// 	window.scrollTo(0, 0)
		// }, 100)
		if (data.error) {
			fetchData()
			setDayTraining(null);
			setCatalog([]);
		}
	}

	const setView = e => {
		if (e.currentTarget) {
			setActiveView(e.currentTarget.dataset.to);
			if (e.currentTarget.dataset.back) setBackPanel(e.currentTarget.dataset.back);
			if (e.currentTarget.dataset.backpage) setBackPage(e.currentTarget.dataset.backpage);
			if (e.currentTarget.dataset.page) {
				router.replacePage(e.currentTarget.dataset.page)
				if (e.currentTarget.dataset.page !== PAGE_PAYWALL) setActivePage(e.currentTarget.dataset.page)
			}
		} else if (e.page) {
			setBackPanel(e.back);
			setBackPage(e.backpage);
			if (e.page !== PAGE_PAYWALL) setActivePage(e.page)
			setActiveView(e.to)
		} else setActiveView(e)
	};

	const setErrorView = (data) => {
		if (data)
			setError({ ...data })
		setActiveView("Error")
	}

	const goBack = (initPage) => {
		router.popPage();
		(location.state.history.length <= 2 || location.getLastPanelInView(VIEW_TRAINING) === PANEL_TRAINING_DAILY) ? enableSystemSwipeBack() : disableSystemSwipeBack();
	};

	const setTrainingData = (data) => {
		setTestingData(data)
	}


	const getViewProps = (viewId) => {
		return {
			id: viewId,
			changeMainNav: changeMainNav,
			activePanel: location.getViewActivePanel(viewId),
			history: location.state.history,
			onSwipeBack: () => goBack(),
			setErrorView: setErrorView,
			setTrainingData: setTrainingData,
			goBack: goBack,
			bufferActivePanel: bufferActivePanel,
			setPremium: setPremium,
			isPremium: isPremium,
			vkUserData: fetchedUser,
			setHomePanel: setView,
			amplitudeEvent: amplitudeEvent,
			closeApp: closeApp,
			banner: banner
		};
	};


	return (
		<AppRoot>
			{
				activePage === PAGE_PROFILE && (
					<>
						<Root activeView={activeView} >
							<Profile {...getViewProps(VIEW_PROFILE)} />
							<SystemPanelError id="Error" changeMainNav={changeMainNav} error={error} closeApp={closeApp} fetchData={fetchData} />
							<Testing {...getViewProps(VIEW_TESTING)} testingData={testingData} />
						</Root>
					</>
				)
			}
			{
				(activePage === PAGE_TRAINING || activePage === PAGE_CHALLENGES) && (
					<>
						<Root activeView={activeView} >
							<Onboarding  {...getViewProps(VIEW_ONBOARDING)} />
							<PaywallPanel {...getViewProps(VIEW_PAYWALL)} backPanel={backPanel} backPage={backPage} />
							<Training  {...getViewProps(VIEW_TRAINING)} challengesData={challengesData} dailyTraining={dayTraining} banner={banner} catalog={catalog} fetchData={fetchData} key={dayTraining} />
							<SystemPanelError id="Error" changeMainNav={changeMainNav} error={error} closeApp={closeApp} fetchData={fetchData} />
							<Testing {...getViewProps(VIEW_TESTING)} testingData={testingData} />
							<Challenges  {...getViewProps(VIEW_CHALLENGES)} challengesData={challengesData} vkData={vkData} />
						</Root>
					</>
				)
			}
			{
				activePage === PAGE_CATALOG && (
					<>
						<Root activeView={activeView} >
							<Catalog   {...getViewProps(VIEW_CATALOG)} />
							<SystemPanelError id="Error" changeMainNav={changeMainNav} error={error} closeApp={closeApp} fetchData={fetchData} />
							<Testing {...getViewProps(VIEW_TESTING)} testingData={testingData} />
						</Root>
					</>
				)
			}
			{/* {
				activePage === PAGE_CHALLENGES && (
					<>
						<Root activeView={activeView} >
							<Challenges  {...getViewProps(VIEW_CHALLENGES)}   /> 
							<SystemPanelError id="Error" changeMainNav={changeMainNav} error={error} closeApp={closeApp} fetchData={fetchData} />
							<Testing {...getViewProps(VIEW_TESTING)} testingData={testingData} />
						</Root>
					</>
				)
			} */}

		</AppRoot>
	);
}

// export default App
export default withRouter(App);
