import React, { } from 'react';
import {
    Div, Text,
    ModalPage,
    ModalPageHeader,
    PanelHeaderButton,
    ActionSheet,
    Title,
    ActionSheetItem,
    Button,
    Subhead,
} from '@vkontakte/vkui';
import { Icon16Cancel } from '@vkontakte/icons';
import AlertIcon from '../../../components/tools/alertIcon';

const ChallengeSimplePopout = ({ data, closeEvent, toggleRef, buttonEvent }) => {
    return (
        <ActionSheet
            onClose={closeEvent}
            toggleRef={toggleRef}
        >
            <ActionSheetItem autoclose className="custom__ActionSheetItem exitIcon close floatClose"  >
                <Icon16Cancel fill="#818C99"></Icon16Cancel>
            </ActionSheetItem>
            <Div style={{ backgroundColor: 'inherit', paddingBottom: 0 }}>
                {data && data.type === 'alert' ? <AlertIcon /> : ''}
                <Title level="2" weight="semibold" style={{ marginBottom: 13, textAlign: 'center' }}>{data ? data.title : ''}</Title>
                <div className="popupContent" style={{ textAlign: 'center', marginBottom: 26 }}>
                    <Subhead weight="regular" className="greyText">{data ? data.content : ''}</Subhead>
                </div>
            </Div>
            {buttonEvent ? (
                <Button stretched size="l" onClick={buttonEvent} >{data ? data.button : 'Продолжить'}</Button>
            ) : (
                <ActionSheetItem autoclose className="custom__ActionSheetItem  "  >
                    <Button stretched size="l" >{data ? data.button : 'Продолжить'}</Button>
                </ActionSheetItem>
            )}
        </ActionSheet>
    );
}


export default ChallengeSimplePopout;