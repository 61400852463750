import React, { useEffect, useState } from 'react';
import {
    Div, Text,
    ModalPage,
    ModalPageHeader,
    PanelHeaderButton,
    Search,
    Title,
    FixedLayout,
    Cell,
    Button,
    Avatar,
    withModalRootContext,
    Caption,
} from '@vkontakte/vkui';
import { Icon24Dismiss } from '@vkontakte/icons';
import Skeleton from 'react-loading-skeleton';
import declensionPipe from '../../../components/pipes/declensionPipe'
const InviteFriendsModal = ({ title, content, id, closeEvent, settlingHeight, friendsData, selectFriend, selectedFriends = [],buttonEvent,nextEvent }) => {
    const [allFriendsSelect, setAllFriendsSelect] = useState(false)
    const [friendArray, setFriendArray] = useState(friendsData || [])
    const [searchState, setSearchState] = useState('');

    const setAllFriends = () => {
        if (allFriendsSelect || selectedFriends.length) {
            selectFriend(null)
            setAllFriendsSelect(false)
        } else {
            friendArray.map((friend, index) => {
                selectFriend(friend)
            })
            setAllFriendsSelect(true)
        }
        setFriendArray([...friendsData])
    }

    const searchChange = e => {
        console.log()
        setSearchState(e.target.value)
        const search = e.target.value.toLowerCase();
        console.log(search)
        let newArray = [];
        friendsData.map(friend => {
            if (friend['first_name'].toLowerCase().indexOf(search) > -1 || friend['last_name'].toLowerCase().indexOf(search) > -1) newArray.push(friend);
        })
        setFriendArray([...newArray])
    }

    return (
        <ModalPage
            id={id}
            onClose={closeEvent}
            settlingHeight={settlingHeight}
            header={
                <ModalPageHeader
                    right={<PanelHeaderButton onClick={closeEvent}><Icon24Dismiss /></PanelHeaderButton>}
                >
                    Пригласить друзей
                </ModalPageHeader>
            }
        >
            <Div style={{ backgroundColor: 'inherit', paddingTop: 4, position: 'relative', paddingBottom: 8 }}>
                <Text className="greyText">С друзьями эффективнее! Кого бы ты хотел видеть вместе с собой в челлендже?</Text>
                <Search value={searchState} after={null} onChange={searchChange} maxLength={30} style={{ padding: 0, marginTop: 20 }} />
                {friendArray && friendArray.length ?
                    <div className="flexBox" style={{ paddingTop: 6, marginTop: 12}}>
                        <Title level="3" weight="semibold">Все друзья</Title>
                        {
                            searchState ? '' :
                                <Text weight="regular" style={{ color: '#6160AD', cursor: 'pointer' }}
                                    onClick={() => setAllFriends()}>{(allFriendsSelect || selectedFriends.length) ? 'Снять выделение' : 'Выбрать всех'}</Text>
                        }
                    </div> : ''}
            </Div>
            <div className="friends-wrap">
                {
                    (friendsData && friendsData.length) ?
                        (friendArray.length ?
                            friendArray.map((friend, index) => {
                                return <Cell
                                    mode="selectable"
                                    key={index}
                                    checked={(selectedFriends.find(item => item.id === friend.id)  && true) || false}
                                    onChange={() => selectFriend(friend)}
                                    before={<Avatar src={friend.photo_50} size={40} />}>
                                    {friend.first_name} {friend.last_name}</Cell>
                            }) : <Text weight='regular' className='greyText' style={{ textAlign: 'center', paddingRight: 16, paddingLeft: 16, marginTop: 40 }}>Ничего не найдено. <br /> Измените параметры поиска</Text>
                        )
                        : (
                            <>
                                <Cell before={<Skeleton style={{ borderRadius: 50, marginRight: 12 }} width={40} height={40} />}><Skeleton width={150} /></Cell>
                                <Cell before={<Skeleton style={{ borderRadius: 50, marginRight: 12 }} width={40} height={40} />}><Skeleton width={200} /></Cell>
                                <Cell before={<Skeleton style={{ borderRadius: 50, marginRight: 12 }} width={40} height={40} />}><Skeleton width={170} /></Cell>
                                <Cell before={<Skeleton style={{ borderRadius: 50, marginRight: 12 }} width={40} height={40} />}><Skeleton width={190} /></Cell>
                            </>
                        )
                }
            </div>

            <FixedLayout vertical="bottom" className={"testingFixedLayout"}>
                <Div>
                    {selectedFriends.length ?
                        <Button stretched size="l" style={{ marginBottom: 10 }} onClick={nextEvent} >
                            Пригласить {declensionPipe(selectedFriends.length, ['друга', 'друзей', 'друзей'])}
                        </Button> : ''
                    }
                    <Button stretched mode="secondary" size="l" onClick={nextEvent}   >
                        Участвовать одному
                    </Button>
                </Div>

            </FixedLayout>

        </ModalPage>
    );
}


export default withModalRootContext(InviteFriendsModal);