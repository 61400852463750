import React, { } from 'react';
import {
    PanelHeaderBack,   Div, Text, 
    ModalPage,
    ModalPageHeader, 
} from '@vkontakte/vkui'; 
import { BridgePlus } from '@happysanta/bridge-plus';

const SubInfoModal = ({ clickEvent, id, closeEvent }) => {
    return (
        <ModalPage
            dynamicContentHeight
            id={id}
            onClose={closeEvent}
            header={
                <ModalPageHeader
                    left={<PanelHeaderBack label="Назад" onClick={clickEvent} />}
                >
                    Подписка
                </ModalPageHeader>
            }
        >
            <Div style={{ backgroundColor: 'inherit', paddingTop: 14, position: 'relative' }}>
                <Text className="textGrey scrollWrap h-70">
                После подтверждения покупки оплата происходит автоматически с карты, привязанной к вашей учётной записи.
                    <br /><br />
                    Подписка продлевается автоматически. Отменить её можно за 24 часа до окончания оплаченного периода или раньше. После отмены подписки её действие сохранится до конца оплаченного периода. Возврат платежа за текущую подписку невозможен.
                    <br /><br />
                    Удаление приложения не прекращает действие подписки.
                    <br /><br />
                    Периоды подписки и их стоимость:
                    <li>1 месяц - 379 рублей;</li>
                    <li>3 месяца - 649 рублей;</li>
                    <li>12 месяцев - 1 390 рублей;</li>
                    <li>3 месяца с бесплатным периодом 30 дней - 649 рублей;</li>
                    <li>12 месяцев с бесплатным периодом 30 дней - 1 390 рублей.</li>
                    <br /> 
                    { (BridgePlus.getStartParams().platform.indexOf('android') !== -1) ?
                    'Вы можете управлять подпиской или отключить автопродление в настройках своего профиля Google.' : 
                    'Вы можете управлять подпиской или отключить автопродление в настройках своей учётной записи Apple ID.'}
                </Text >
            </Div >
        </ModalPage >
    );
}


export default SubInfoModal;