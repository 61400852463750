import React, { } from 'react';
import { Title, Card, Subhead, Caption } from '@vkontakte/vkui'; 
import './TrainingCard.css';
import { Icon16Lock } from '@vkontakte/icons';
import Skeleton from 'react-loading-skeleton'; 
import defaultTimePipe from '../pipes/defaultTimePipe';

const compls = {
    hard: 'Сложный уровень',
    medium: 'Средний уровень',
    easy: 'Легкий уровень',
}

const TrainingCard = ({ setPanel, panelToSingle, data, clickEvent, isFree, skeleton = false, source }) => {


    return (
        <Card style={{ marginBottom: 12 }} className={data && data.is_read ? 'readed' : ''}>
            {skeleton ?
                <>
                    <div className="trainingCardItem--Preview" style={{ border: 'none', overflow: 'hidden' }}>
                        <Skeleton height={'100%'} />
                    </div>
                    <Subhead weight="regular"> <Skeleton width={150} /> <br /> <Skeleton width={70} /> </Subhead>
                </>
                :
                <div className={"trainingCardItem " + ((data.complexity && !isFree) && "closed")}
                    onClick={
                        () =>
                            clickEvent(panelToSingle,
                                (data.complexity ?
                                    { "trainingId": data.id}
                                    : data.article && { id: data.article.id, type: 'article' }))
                    }
                    data-to={panelToSingle}
                    data-id={data.article && 'article'}
                    data-title={data.article && data.article.title}
                    data-preview={data.article && data.article.preview}
                    data-training_id={data.complexity && data.id}
                    data-article_id={data.article && data.article.id}
                >
                    <div className="trainingCardItem--Preview"
                        style={{ backgroundImage: `url(${(data.article ? data.article.preview : data.preview)})` }}>
                        {data.article &&
                            <div className="float flexBox counterBox" style={{ top: 7 }}>
                                <span className="previewCounter">{data.is_read ? 'Прочитано' : 'Новая'}</span>
                            </div>
                        }
                        {data.duration &&
                            <>
                                <Title level="3" weight="semibold" className="workoutTitle" style={{ color: 'white' }}>{data.title}</Title>
                                {/* <img src={vkLogo} className="vkLogo-float" /> */}
                            </>
                        }
                    </div>
                    {(data.complexity && !isFree) &&
                        <div className="lockWrap">  <Icon16Lock fill="white" width="14" /> </div>
                    }
                    <Subhead weight="regular">{data.article ? data.article.title : data.title}</Subhead>
                    {data.duration && (<Caption level="1" weight="regular">{ defaultTimePipe(data.duration)}</Caption>)}
                    {data.complexity && (<Caption level="1" weight="regular">{compls[data.complexity]}</Caption>)}
                </div>
            }
        </Card>
    )
}


export default TrainingCard;