import React from 'react';
import { Panel, PanelHeader, Text, Title, Div, PanelHeaderBack, RichCell, Group } from '@vkontakte/vkui';
import { Icon24ChevronCompactRight } from '@vkontakte/icons';
import { PAGE_CATALOG_FAQ, PAGE_CATALOG_FAQLIST } from '../../routing/routes';
import { withRouter } from '@happysanta/router';


const FaqItemPanel = ({ faqList, nextPage, dataItem, faqIndex, changeFaq }) => {
    const findText = () => {
        console.log(faqIndex, dataItem)
        window.scrollTo(0, 0)
        let text;
        faqList.forEach(array => {
            array['faqs'].forEach(item => {
                if (item.id === +faqIndex) text = item.text
            })
        });
        return text;
    }
    const randomizeArray = (array) => {
        let newArray = [];
        array.forEach((item) => {
            newArray = newArray.concat(item['faqs'])
        })
        return newArray.sort(() => Math.random() - 0.5)
    }
    return (
        <Panel id="faqItemPanel">
            <PanelHeader separator={false} left={<PanelHeaderBack onClick={() => nextPage(PAGE_CATALOG_FAQLIST)} data-to="catalog" />} >Популярные вопросы</PanelHeader>
            {dataItem && (
                <Group className="customGroup" separator="hide"  >
                    <Div style={{ paddingTop: 8 }}>
                        <Title level="1" weight="semibold" style={{ marginBottom: 17 }} >
                            {dataItem.title}
                            {/* {
                                faqList.map(array => {
                                    return (
                                        array['faqs'].map(item => {
                                            return (item.id === +faqIndex ? item.title : '')
                                        })
                                    )
                                })
                            } */}
                        </Title>
                        <Text className="text-grey">
                            <div dangerouslySetInnerHTML={{ __html: dataItem.text }}></div>
                        </Text>
                    </Div>
                </Group>
            )}
            <Group className="customGroup" separator="hide"  >
                <Div>
                    <Title level="3" weight="semibold" style={{ fontSize: 17 }}  >Другие вопросы</Title>
                </Div>
                {faqList && (randomizeArray(faqList).map((item, index) => {
                    return item.id !== +faqIndex && (
                        <RichCell
                            key={index}
                            onClick={() => changeFaq({ id: item.id, text: item.text, title: item.title })}
                            data-id="faq"
                            data-test={item.id}
                            data-title={item.title}
                            data-text={item.text}
                            data-to="faqItemPanel"
                            separator="hide"
                            className="customCell"
                            multiline
                            after={<Icon24ChevronCompactRight fill={'#C4C8CC'}></Icon24ChevronCompactRight>}
                        > {item.title}
                        </RichCell>
                    )
                })
                )}
            </Group>
        </Panel>)
}


export default withRouter(FaqItemPanel);