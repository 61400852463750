import React, { useState, useEffect, useRef } from 'react';
import '@vkontakte/vkui/dist/vkui.css';
import { View, Panel, Group, ActionSheet, Text, Title, Button, Div, PanelHeader, Radio, PanelHeaderBack, HorizontalCell, HorizontalScroll, useAppearance, FormLayout, Caption, FixedLayout, Subhead } from '@vkontakte/vkui';
import './Testing.css';
import { useLocation, useParams, useRouter, withRouter } from '@happysanta/router';


const TestingSlide = ({ id, slideIndex, goBack, amplitudeEvent, openModal, testingData, secondColor, mainColor, getSlideData, slideId, nextSlide, slideData, answerArray, setAnswer }) => {

    const [modalData, setModalData] = useState(null)
    const scrollRef = React.useRef(null);
    const elemRef =  React.useRef(null);
    const createAlphaIndex = (num) => {
        const base = 26;
        let str = '';
        do {
            const mod = num % base;
            num = num / base | 0;
            str = (mod ? String.fromCharCode('A'.charCodeAt(0) + mod - 1) : (--num, 'Z')) + str;
        } while (num);
        return str;
    }

    useEffect(() => { 
        const elemScroll = elemRef.current   
        if (elemScroll) {
            elemScroll.scrollIntoView({block: 'nearest', inline: 'center'})
        }
       
 

    }, [])


    return (
        <Panel id={id} className="p-b-60 safety-padding-bottom"   >
            <PanelHeader separator={false} left={<PanelHeaderBack onClick={() => goBack()} />}>{testingData.title}</PanelHeader>
                
            <div style={{position: 'relative', zIndex: 0}}>
                <HorizontalScroll    getRef={scrollRef} draggable={true} getScrollToLeft={i => i - 39} getScrollToRight={i => i + 39} style={{ paddingTop: 8, paddingBottom: 8 }} >
                    <div style={{ display: 'flex' }}>
                        {
                            testingData["screens"].map((item, i) => {
                                return (
                                    <HorizontalCell
                                        getRef={i === slideIndex && elemRef}
                                        key={item.id}>
                                        <span
                                            className="testSlideNum"
                                            key={i}
                                            style={(i === slideIndex ? { background: mainColor, color: 'white' } : (i < slideIndex ? { background: secondColor, color: mainColor } : {}))} >
                                            <Text weight="medium">{i + 1}</Text>
                                        </span>
                                    </HorizontalCell>
                                )
                            })
                        }
                    </div>
                </HorizontalScroll>
            <Div className="testing__slide__wrap">
                <Title level="1" style={{ marginBottom: 6, marginTop: 4 }}>{slideData.title} </Title>
                {/* {slideData.image ? <div className={slideData.options.length > 2 ? "titleImage" : "titleImage bigImage"} style={{ backgroundImage: `url(${slideData.image})` }} ></div> : ''} */}
                <Text className="text-grey" style={{ marginBottom: 16 }}><div className="testingDescrWrap" dangerouslySetInnerHTML={{ __html: slideData.description }}></div></Text>

                <FormLayout style={{ width: '100%' }}>
                    {slideData['options'] ? slideData['options'].map((item, i) => {
                        return (
                            <Radio name={slideData.id}
                                key={i}

                                style={(answerArray[slideData.id] === item.id ? { background: secondColor } : {})}
                                className={"custom-radio testing-mode" + (item.description ? "" : " big-mode") + (answerArray[slideData.id] === item.id ? ' checked' : '')}
                                defaultChecked={answerArray[slideData.id] === item.id ? true : false}
                                onChange={(e) => { setModalData(item.modal ? { ...item.modal, ...{ "next": (item.next ? item.next : slideData.next) } } : null); setAnswer(e) }}
                                value={item.id}
                            >
                                <span className="alpha__index float" style={{ color: mainColor }} >{createAlphaIndex(i + 1)}.</span>
                                <Text weight="medium" style={{ fontSize: (item.description ? 17 : 15), marginBottom: 2 }} >{item.title}</Text>
                                {item.description ? <Text weight="regular" className="text-grey" style={{ fontSize: 14 }} >{item.description}</Text > : ''}
                            </Radio>
                        )
                    }) : ''}
                </FormLayout>
            </Div>



            <FixedLayout vertical="bottom" className={"testingFixedLayout " + (slideData['options'] ? (answerArray[slideData.id] ? "" : "disable") : '')}>
                <Div>
                    <Button stretched size="l" style={{ marginBottom: 10, background: mainColor, color: 'white' }}
                        onClick={() => {
                            amplitudeEvent("test_answer_touch", { "question_number": slideIndex + 1, "value_answer": answerArray[slideData.id], "question_id": slideData.id });
                            (modalData ? openModal(modalData) : nextSlide(slideData.next))
                        }
                        } >
                        Ответить
                    </Button>
                </Div>
            </FixedLayout>

            </div>
        </Panel>
    )

}

export default withRouter(TestingSlide);
