import React, { useEffect, useState } from 'react';
import {
    ModalPage,
    ModalPageHeader,
    Avatar,
    withModalRootContext,
    PanelHeaderBack,
    SimpleCell,
    Div,
    Subhead,
    PanelHeaderButton,
    Text,
} from '@vkontakte/vkui';
import { Icon16Lock, Icon24Dismiss } from '@vkontakte/icons';
import Skeleton from 'react-loading-skeleton';
import declensionPipe from '../../../components/pipes/declensionPipe';
const ChallengeSimpleModal = ({ title, content, id, closeEvent, settlingHeight }) => { 
    return (
        <ModalPage 
            id={id}
            onClose={closeEvent}
            dynamicContentHeight
            settlingHeight={settlingHeight}
            header={
                <ModalPageHeader
                    right={<PanelHeaderButton onClick={closeEvent}><Icon24Dismiss /></PanelHeaderButton>}
                >
                   {title}
                </ModalPageHeader>
            }
        >
            <Div>
                <Text className="greyText">{content}</Text>
            </Div>

        </ModalPage>
    );
}


export default withModalRootContext(ChallengeSimpleModal);