import { Title, Div, Text, PanelHeader, PanelHeaderBack } from '@vkontakte/vkui';
import './ChallengeHeader.css';
import mockHeader from '../../img/mock/challenge-header.png'
import { Icon28ChevronBack, Icon28Notifications, Icon28DoorArrowRightOutline, Icon24HelpOutline, Icon28MoreHorizontal } from '@vkontakte/icons';
import datePipe from '../pipes/datePipe';
const ChallengeHeader = ({ data, type, additionalEvent, exitEvent, challengeData }) => (
    <div className={"challenge--header--wrap " + (type === 'small' ? 'small-header' : '')} style={{ backgroundImage: `url(${(mockHeader)})` }}>
        <PanelHeader separator={false}
            className='header__with_background '
            left={type === 'small' ?
                <span className="bubble div-centered bg-blured" onClick={exitEvent} style={{ marginLeft: 2 }}>
                    <Icon28ChevronBack fill="white" />
                </span> : <Icon28ChevronBack style={{ marginLeft: 4 }} fill="#ffffff" onClick={exitEvent} />}
            right={type === 'small' ? '' : <Icon28MoreHorizontal width={25} style={{ marginRight: 10 }} fill="#ffffff" />}    ></PanelHeader>
        <Div  >
            {type !== 'small' ?
                <div className="challege--header--title">
                    <Title level='2' weight='regular' style={{ marginBottom: 10, opacity: '0.8' }}>
                        {challengeData.challengeType === 'waiting' ? (
                            'С ' + datePipe(challengeData.starts_at)
                        ): ` <strong>7</strong> из 14 дней`} 
                        </Title>
                    <Title level='1' weight='semibold'>{challengeData ? challengeData.title : ''}</Title>
                    {/* <Text>С 11 по 26 ноября</Text>
                <Title weight="semibold" level="1" style={{ marginTop: 7, paddingRight: 34, position: 'relative' }}>Прокачай руки за 14 дней
                    {type === "small" ? '' : <Icon24HelpOutline fill="white" className="helpIcon float" width={20} onClick={additionalEvent} />}</Title> */}
                </div>
                : ''
            }
        </Div>
    </div>

)


export default ChallengeHeader;