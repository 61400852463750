import React, { useState, useEffect, useRef } from 'react';
import '@vkontakte/vkui/dist/vkui.css';
import {
    View, Panel, ActionSheet, ActionSheetItem, Text, Title, Button, Div, PanelHeader, Group, Avatar, RichCell, NativeSelect, Header, Caption, FormLayout, FormItem,
    ModalRoot,
    PanelHeaderBack,
    Snackbar
} from '@vkontakte/vkui';
import { Icon12Fire, Icon16Cancel, Icon12Clock, Icon12OnlineVkmobile, Icon24GearOutline, Icon24HelpOutline, Icon16ErrorCircleFill } from '@vkontakte/icons';
import './Profile.css';
import ProfileService from '../../services/ProfileService';
import SettingsPanel from './SettingsPanel';
import timePipe from '../../components/pipes/timePipe';
import bridge from '@vkontakte/vk-bridge';
import EpicNavBar from '../../components/EpicNavBar/EpicNavBar';
import timeSvg from '../../img/timeSvg.svg';

import level1 from '../../img/levels/level1.svg';
import level2 from '../../img/levels/level2.svg';
import level3 from '../../img/levels/level3.svg';
import level4 from '../../img/levels/level4.svg';
import level5 from '../../img/levels/level5.svg';
import level6 from '../../img/levels/level6.svg';
import level7 from '../../img/levels/level7.svg';
import level8 from '../../img/levels/level8.svg';
import level9 from '../../img/levels/level9.svg';
import level10 from '../../img/levels/level10.svg';
import level11 from '../../img/levels/level11.svg';
import level12 from '../../img/levels/level12.svg';
import ProgressPanel from './ProgressPanel';
import { Icon24CheckCircleOn, Icon24CheckCircleOff, Icon28FireOutline, Icon24ClockOutline } from '@vkontakte/icons';
import { useLocation, useRouter, withRouter } from '@happysanta/router';
import { MODAL_PAYWALL, MODAL_PROFILE_SETTINGS, MODAL_PROFILE_TEXT, MODAL_SUBINFO, PAGE_PROFILE, PAGE_PROFILE_PROGRESS, PAGE_PROFILE_SETTINGS, PANEL_PROFILE, PANEL_PROFILE_PROGRESS, PANEL_PROFILE_SETTINGS, PANEL_TRAINING_DAILY, VIEW_PROFILE } from '../../routing/routes';
import PaywallModal from '../../components/modal/PaywallModal';
import SimpleModal from '../../components/modal/SimpleModal';
import SubInfoModal from '../../components/modal/SubInfoModal';
import Skeleton from 'react-loading-skeleton';
import getErrorStatus from '../../components/tools/errorStatus';
import getMonth from '../../components/tools/month';
import { disableSystemSwipeBack, enableSystemSwipeBack } from '../../components/modules/AppModule';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const Profile = ({ setHomePanel, vkUserData, viewId, changeMainNav, history, onSwipeBack, isPremium, setPremium, activePanel, amplitudeEvent, setErrorView, closeApp }) => {
    const router = useRouter();
    const location = useLocation();
    const profileService = new ProfileService();
    const [answerArray, setAnswerArray] = useState({ "workout_parameter": {} });
    const [settingParam, setSettingParam] = useState(null);
    const [userData, setUserData] = useState(null);
    const [userAccount, setUserAccount] = useState({ 'user': {} });
    const [slideIndexAge, setSlideIndexAge] = useState(75);
    const ageArray = []; for (let i = 1920; i <= 2020; i++)  ageArray.push(i);
    const heightArray = []; for (let i = 140; i <= 200; i++)  heightArray.push(i);
    const weightArray = []; for (let i = 30; i <= 100; i++)  weightArray.push(i);
    const baseTargetRef = useRef(null)
    const [snackbarState, setSnackbarState] = useState(null)

    const levelCup = {
        "level_1": { "img": level1, "title": "Новичок", "description": "", "score": 0 },
        "level_2": { "img": level2, "title": "Первые шаги", "description": "1 час", "score": 60 },
        "level_3": { "img": level3, "title": "Подающий надежды", "description": "2 часа", "score": 60 * 2 },
        "level_4": { "img": level4, "title": "Любимчик тренера", "description": "3 часа", "score": 60 * 3 },
        "level_5": { "img": level5, "title": "Звезда коврика", "description": "5 часов", "score": 60 * 5 },
        "level_6": { "img": level6, "title": "Без тормозов", "description": "10 часов", "score": 60 * 10 },
        "level_7": { "img": level8, "title": "Энерджайзер", "description": "20 часов", "score": 60 * 20 },
        "level_8": { "img": level7, "title": "Пример для других", "description": "40 часов", "score": 60 * 40 },
        "level_9": { "img": level9, "title": "Чёрный пояс", "description": "80 часов", "score": 60 * 80 },
        "level_10": { "img": level10, "title": "Сенсей", "description": "200 часов", "score": 60 * 200 },
        "level_11": { "img": level11, "title": "Лучший из лучших", "description": "300 часов", "score": 60 * 300 },
        "level_12": { "img": level12, "title": "Камень бесконечности", "description": "400 часов", "score": 60 * 400 },
    }

    const content = `Каждое упражнение помогает в той или иной степени прокачивать один из четырёх навыков.</br></br>
             <li> Сила. Выполнение упражнений за счёт мышечных усилий. </li> 
             <li> Кардио. Адаптация сердечно-сосудистой системы к интенсивным нагрузкам.   </li> 
             <li> Гибкость. Возможность свободно и комфортно выполнять разнообразные движения.  </li> 
             <li> Мобильность. Амплитудные движения связок и суставов.  </li>   </br>
             Сумма навыков не равна времени тренировки: у каждого упражнения есть коэффициент развития каждого навыка, на него умножается время тренировки, потраченное на его освоение.`
    useEffect(() => {
        router.replacePage(PAGE_PROFILE)
        async function fetchData() {
            try {
                profileService.getUserProfile().then(data => {
                    if (data === 'offline') return setErrorView(data)
                    setUserData(data.data);
                    profileService.getUserData().then(data => {
                        if (data.data) {
                            setUserAccount(data.data)
                            const newArr = {
                                "workout_duration_type": data.data.user["workout_duration_type"],
                                "gender": data.data.user["gender"],
                                "birthyear": data.data.user["birthyear"],
                                "workout_parameter": data.data.user["workout_parameter"],
                            }
                            setAnswerArray({ ...newArr })
                        }
                    }).catch(error => { console.log('profileService--error ', error); setErrorView(getErrorStatus(error)); })
                }).catch(error => { console.log('profileService--error ', error); setErrorView(getErrorStatus(error)); })
            } catch (error) {
                console.log('profileService--error ', error);
                setErrorView(getErrorStatus(error));
            }
        }
        fetchData();
    }, []);

    const setPanel = e => {
        const localHistory = [...history];
        localHistory.push(settingParam.to);
        if (settingParam.to === 'profile') {
            bridge.send('VKWebAppDisableSwipeBack');
        }
        // setActivePanel(settingParam.to)
    };
    const declensionPipeOnlyText = (count, titles) => {
        const cases = [2, 0, 1, 1, 1, 2];
        return titles[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]];
    }

    const setAnswer = (e) => {
        setSnackbarState(null)
        const answer = e.currentTarget;
        let newAnswer = answerArray;
        if (answer.name === 'workout_parameter') {
            newAnswer["workout_parameter"][answer.value] ?
                newAnswer["workout_parameter"][answer.value] = null
                : newAnswer["workout_parameter"][answer.value] = "easy";
            // let paramsArray = [];
            // Object.keys(newAnswer["workout_parameter"]).map(key => {
            //     if (newAnswer['workout_parameter'][key] && newAnswer['workout_parameter'][key] !== null) paramsArray.push(key);
            // })
            // console.log(paramsArray.length)
            // if (paramsArray.length <= 1) return false 
        } else newAnswer[answer.name] = answer.value;
        setAnswerArray({ ...newAnswer })
    }

    const timePipeHtml = (mins, color) => {
        const hours = (mins >= 60 ? Math.trunc(mins / 60) : 0);
        let minutes = mins % 60;
        return (hours ? (`<span class="bigOne" style="color: ${color}">${hours}</span> ч `) : '') + (`<span class="bigOne" style="color: ${color}">${minutes}</span> мин`);
    }

    const saveParams = () => {
        const newData = Object.assign(userAccount.user, answerArray);
        setUserAccount({ user: newData })
        const newArr = {}
        Object.keys(answerArray).map(item => {
            if (item !== "workout_parameter") {
                newArr[item] = answerArray[item];
            }
        })
        let paramsArray = [];
        Object.keys(newData["workout_parameter"]).map(key => {
            if (newData['workout_parameter'][key] && newData['workout_parameter'][key] !== null) paramsArray.push(key);
        })
        if (!paramsArray.length) {
            setSnackbarState(
                <Snackbar
                    className="snackbar--error"
                    duration={2000}
                    onActionClick={() => setSnackbarState(null)}
                    onClose={() => setSnackbarState(null)}
                    before={<Icon16ErrorCircleFill />}
                > Необходимо выбрать хотя бы один тип</Snackbar>
            )
            return false
        }
        router.popPage()
        profileService.updateUserData(newArr)
        profileService.updateUserWorkoutParams(answerArray["workout_parameter"])

    }

    const historyBack = () => {
        const localHistory = location.getViewHistory(VIEW_PROFILE);
        localHistory.pop();
        router.popPage()
    }

    const getLevelImage = (score) => {
        let imgIndex;
        Object.entries(levelCup).forEach(([key, value], index) => {
            if (value.score <= score) imgIndex = key
        })
        return (imgIndex ? levelCup[imgIndex]['img'] : levelCup["level_1"]['img'])
    }

    const getProgressProcent = (data) => {
        if (!data.rank_progress.next_rank) return 100;
        const current_rank = +data.rank_progress.current_rank.score;
        const next_rank = +data.rank_progress.next_rank.score;
        const score = +data.score_total;
        const procent = ((score - current_rank) || (next_rank - current_rank)) ? ((score - current_rank) / (next_rank - current_rank) * 100) : 0;
        return procent;
    }

    const popout = ((e) => {
        if (location.getPopupId() === MODAL_PROFILE_SETTINGS) {
            return (
                <>
                    <ActionSheet
                        onClose={() => router.popPage()}
                        toggleRef={baseTargetRef.current}
                    >
                        <Div style={{ position: 'relative', paddingTop: 4 }}>
                            <span className="close floatClose" onClick={() => router.popPage()}> <Icon16Cancel fill="#818C99"></Icon16Cancel></span>
                            <Title level="2" weight="semibold" style={{ marginBottom: 7, borderBottom: 'thin solid #D7D8D9', paddingBottom: 14 }}>
                                {
                                    {
                                        birthyear: `Год рождения`,
                                        workout_parameter: `Типы тренировок`,
                                        workout_duration_type: `Длительность`,
                                        gender: `Ваш пол`,
                                    }[settingParam.popup]
                                }
                            </Title>
                        </Div>
                        <div className="popupContent">
                            {settingParam.popup === 'birthyear' && (
                                <FormItem top="Выберите год рождения">
                                    <NativeSelect defaultValue={settingParam.option} onChange={setAnswer} name="birthyear" data-key={settingParam.option}>
                                        {ageArray.map((value, index) => {
                                            return <option key={index} value={value}  >{value}</option>
                                        })}
                                    </NativeSelect>
                                </FormItem>
                            )}
                            {settingParam.popup === 'workout_parameter' && (
                                <FormLayout style={{ width: '100%' }}>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="workout_parameter"
                                            defaultChecked={settingParam.option.indexOf("strength") !== -1} onClick={setAnswer} className="custom-radio-inline" value="strength" />
                                        <span>
                                            Тренировки с собственным весом
                                            <Icon24CheckCircleOn className="checkOn" fill="#7C46FF" />
                                            <Icon24CheckCircleOff className="checkOff" fill="#B8C1CC" />
                                        </span>
                                    </label>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="workout_parameter"
                                            defaultChecked={settingParam.option.indexOf("cardio") !== -1} onClick={setAnswer} className="custom-radio-inline" value="cardio" />
                                        <span>
                                            Кардио
                                            <Icon24CheckCircleOn className="checkOn" fill="#7C46FF" />
                                            <Icon24CheckCircleOff className="checkOff" fill="#B8C1CC" />
                                        </span>
                                    </label>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="workout_parameter"
                                            defaultChecked={settingParam.option.indexOf("stretch") !== -1} onClick={setAnswer} className="custom-radio-inline" value="stretch" />
                                        <span>
                                            Растяжка
                                            <Icon24CheckCircleOn className="checkOn" fill="#7C46FF" />
                                            <Icon24CheckCircleOff className="checkOff" fill="#B8C1CC" />
                                        </span>
                                    </label>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="workout_parameter"
                                            defaultChecked={settingParam.option.indexOf("mobility") !== -1} onClick={setAnswer} className="custom-radio-inline" value="mobility" />
                                        <span>
                                            На мобильность
                                            <Icon24CheckCircleOn className="checkOn" fill="#7C46FF" />
                                            <Icon24CheckCircleOff className="checkOff" fill="#B8C1CC" />
                                        </span>
                                    </label>
                                    <label className="checkbox-label">
                                        <input type="checkbox" name="workout_parameter"
                                            defaultChecked={settingParam.option.indexOf("dumbbells") !== -1} onClick={setAnswer} className="custom-radio-inline" value="dumbbells" />
                                        <span>
                                            С гантелями
                                            <Icon24CheckCircleOn className="checkOn" fill="#7C46FF" />
                                            <Icon24CheckCircleOff className="checkOff" fill="#B8C1CC" />
                                        </span>
                                    </label>
                                </FormLayout>

                            )}
                            {settingParam.popup === 'workout_duration_type' && (
                                <FormLayout style={{ width: '100%' }}>
                                    <label className="checkbox-label">
                                        <input type="radio" name="workout_duration_type" defaultChecked={settingParam.option === "short" ? true : false} className="custom-radio-inline" onClick={setAnswer} value="short" />
                                        <span>
                                            20–30 минут
                                            <Icon24CheckCircleOn className="checkOn" fill="#7C46FF" />
                                        </span>
                                    </label>
                                    <label className="checkbox-label">
                                        <input type="radio" name="workout_duration_type" defaultChecked={settingParam.option === "long" ? true : false} className="custom-radio-inline" onClick={setAnswer} value="long" />
                                        <span>
                                            30–45 минут
                                            <Icon24CheckCircleOn className="checkOn" fill="#7C46FF" />
                                        </span>
                                    </label>
                                    <label className="checkbox-label">
                                        <input type="radio" name="workout_duration_type" defaultChecked={settingParam.option === "any" ? true : false} className="custom-radio-inline" onClick={setAnswer} value="any" />
                                        <span>
                                            Любая
                                            <Icon24CheckCircleOn className="checkOn" fill="#7C46FF" />
                                        </span>
                                    </label>
                                </FormLayout>
                            )}
                            {settingParam.popup === 'gender' && (
                                <FormLayout style={{ width: '100%' }}>
                                    <label className="checkbox-label">
                                        <input type="radio" onClick={setAnswer} name="gender" defaultChecked={settingParam.option === "man"} className="custom-radio-inline" value="man" />
                                        <span>
                                            Мужской
                                            <Icon24CheckCircleOn className="checkOn" fill="#7C46FF" />
                                        </span>
                                    </label>
                                    <label className="checkbox-label">
                                        <input type="radio" onClick={setAnswer} name="gender" defaultChecked={settingParam.option === "woman"} className="custom-radio-inline" value="woman" />
                                        <span>
                                            Женский
                                            <Icon24CheckCircleOn className="checkOn" fill="#7C46FF" />
                                        </span>
                                    </label>
                                </FormLayout>
                            )}

                            {(settingParam.popup === 'workout_duration_type' || settingParam.popup === 'workout_parameter') && (
                                <Div>
                                    <Text className="greyText" style={{ fontSize: 14, borderTop: 'thin solid #D7D8D9', paddingTop: 13, marginTop: 7 }}>Если вы внесёте изменения, то они применятся завтра в тренировке дня</Text>
                                </Div>
                            )}
                        </div>
                        {/* <ActionSheetItem autoclose  > */}
                        <Button stretched size="l" onClick={saveParams} data-option={settingParam.popup}   >Сохранить</Button>
                        {/* </ActionSheetItem>  */}
                    </ActionSheet >
                    {snackbarState}
                </>
            );
        }
    })();

    const successEvent = () => {
        // router.popPage(); 
        setPremium(true)
        // setTimeout(() => {
        //     nextPage(PAGE_TRAINING_MAIN, { trainingId: nextTrainingId  })
        // }, 200);
    }

    const infoModal = (
        <ModalRoot
            activeModal={location.getModalId()}
            onClose={() => { router.popPage() }}
        >
            <SimpleModal dynamicContentHeight id={MODAL_PROFILE_TEXT} closeEvent={() => { router.popPage() }} title={"Что такое навыки?"} content={content} />
            <PaywallModal settlingHeight={100} successEvent={successEvent} dynamicContentHeight={true} source={"settings page"} amplitudeEvent={amplitudeEvent} id={MODAL_PAYWALL} closeEvent={() => { router.popPage(); amplitudeEvent("paywall_button", { "value_choose": "close", "source": "settings page" }) }} clickEvent={() => openModal(MODAL_SUBINFO)} />
            <SubInfoModal settlingHeight={100} dynamicContentHeight={true} id={MODAL_SUBINFO} clickEvent={() => router.popPage()} closeEvent={() => router.popPage()} ></SubInfoModal>
        </ModalRoot>
    )
    const getPanelProps = (panelId) => {
        return {
            id: panelId,
            goBack: historyBack,
            nextPage: nextPage,
            isPremium: isPremium,
            setPanel: setPanel,
            setHomePanel: setHomePanel,
            vkUserData: vkUserData,
            openModal: openModal
        };
    };

    const nextPage = (nextPage, data) => {
        disableSystemSwipeBack();
        (nextPage === PAGE_PROFILE) ? router.popPageTo(PAGE_PROFILE) : router.pushPage(nextPage);
    }

    const openModal = (modal) => {
        router.pushModal(modal);
    }
    const openPopout = (e) => {
        setSettingParam(e.currentTarget.dataset)
        router.pushPopup(MODAL_PROFILE_SETTINGS);
    }




    return (
        // <Root activeView='Profile'>
        <View popout={popout}
            id={viewId}
            activePanel={activePanel ? activePanel : PANEL_PROFILE}
            onSwipeBack={onSwipeBack}
            history={location.state.history}
            modal={infoModal}>
            <Panel id={PANEL_PROFILE} >
                <PanelHeader separator={false} left={<PanelHeaderBack onClick={() => closeApp()} />}   >Тренировки</PanelHeader>
                <EpicNavBar navEvent={changeMainNav} currentActive={PAGE_PROFILE}></EpicNavBar>

                <Group className="customGroup m-t-50" separator="hide"   >
                    <RichCell
                        className="profileHeader"
                        disabled
                        multiline
                        before={vkUserData && vkUserData.photo_200 ?
                            <Avatar shadow={false} style={{ paddingTop: 0, paddingBottom: 0 }} size={96} src={vkUserData.photo_200} />
                            : <Skeleton circle={true} width={104} height={104} style={{ margin: '8px 18px 8px 0' }} />}
                        caption={userData && userData.rank_progress.current_rank ?
                            <span onClick={() => nextPage(PAGE_PROFILE_PROGRESS)}>{userData.rank_progress.current_rank.name}</span>
                            : <Skeleton width={120} />}
                        bottom={
                            <>
                                {userData ?
                                    <ProgressBar height={6} clickEvent={() => nextPage(PAGE_PROFILE_PROGRESS)} progress={getProgressProcent(userData)} /> : ''}
                                <Text weight="regular" className="greyText" style={{ fontSize: 14 }}> {
                                    userData ? timePipe(userData.score_total) + ' активности'
                                        : <Skeleton width={150} />} </Text>
                            </>
                        }>
                        <Title level="1" weight="semibold" style={{ fontSize: 25 }} >{vkUserData && vkUserData.first_name ? `${vkUserData.first_name} ${vkUserData.last_name}` : <Skeleton width={200} />}</Title>
                        {userData && userData.rank_progress ?
                            <div className="floatLevelImg">
                                <embed type="image/svg+xml" src={getLevelImage(userData.score_total)} fill="#99A2AD" />
                            </div> : ''}
                    </RichCell>
                    {
                        userData && (
                            <Div style={{ paddingTop: 0, paddingBottom: 7, marginTop: 12 }}>
                                <div className="flexBox">
                                    <Button stretched size="m" mode="secondary" onClick={() => { amplitudeEvent("aboutme_button", { "button_id": "levels" }); nextPage(PAGE_PROFILE_PROGRESS) }}  >Открыть список уровней</Button>
                                    <Button size="m" mode="secondary" className="previewBtn" onClick={() => { amplitudeEvent("aboutme_button", { "button_id": "settings" }); nextPage(PAGE_PROFILE_SETTINGS) }} style={{ marginLeft: 8, paddingLeft: 0, paddingRight: 0, width: 'auto', minWidth: 44 }}> <Icon24GearOutline fill={'#9062FF'} /> </Button>
                                </div>
                            </Div>
                        )
                    }
                </Group>

                <Group className="customGroup" separator="hide"
                    header={<Header style={{ position: 'relative' }} aside={<Icon24HelpOutline fill="#9062FF" style={{ zIndex: 1, top: 5 }} onClick={() => { amplitudeEvent("aboutme_button", { "button_id": "skills" }); openModal(MODAL_PROFILE_TEXT) }} />} >Навыки</Header>} >
                    <Div style={{ paddingBottom: 0 }}>

                        <div className="flexBox flexBox-flexWrap" style={{ marginTop: 0, padding: 0 }}>
                            <div className="resultItem">
                                <Caption level="2" weight="semibold" style={{ marginBottom: 1 }}>{userData ? 'МОБИЛЬНОСТЬ' : <Skeleton width={90} />}</Caption >
                                {userData ?
                                    <p dangerouslySetInnerHTML={{ __html: timePipeHtml(userData.score.mobility, '#59C7FF') }}></p>
                                    : <p> <Skeleton width={100} /> </p>
                                }
                            </div>
                            <div className="resultItem">
                                <Caption level="2" weight="semibold" style={{ marginBottom: 1 }}>{userData ? 'КАРДИО' : <Skeleton width={90} />}</Caption >
                                {userData ?
                                    <p dangerouslySetInnerHTML={{ __html: timePipeHtml(userData.score.cardio, '#AB4BF5') }}></p>
                                    : <p> <Skeleton width={100} /> </p>
                                }
                            </div>
                            <div className="resultItem">
                                <Caption level="2" weight="semibold" style={{ marginBottom: 1 }}>{userData ? 'ГИБКОСТЬ' : <Skeleton width={90} />}</Caption >
                                {userData ?
                                    <p dangerouslySetInnerHTML={{ __html: timePipeHtml(userData.score.flexibility, '#FF6D96') }}></p>
                                    : <p> <Skeleton width={100} /> </p>
                                }
                            </div>
                            <div className="resultItem">
                                <Caption level="2" weight="semibold" style={{ marginBottom: 1 }}>{userData ? 'СИЛА' : <Skeleton width={90} />}</Caption >
                                {userData ?
                                    <p dangerouslySetInnerHTML={{ __html: timePipeHtml(userData.score.strength, '#84C658') }}></p>
                                    : <p> <Skeleton width={100} /> </p>
                                }
                            </div>
                        </div>
                    </Div>
                </Group>

                {userData ? (
                    <>
                        {
                            (userData.statistics).map((item, i) => {
                                if (item.measure_scale === 'score') return false;
                                return (
                                    <Group className="customGroup" key={i.toString()} separator="hide" header={
                                        <Header className="statisticsHeader">{{
                                            workout: 'Тренировок в ' + getMonth(),
                                            second: 'Часов в ' + getMonth(),
                                            calorie: 'Калории в ' + getMonth()
                                        }[item.measure_scale]}</Header>
                                    } >
                                        {item.value ? (
                                            <>
                                                {{
                                                    second: <Icon12Clock className="groupIcon-abs" fill="#7C46FF" width={15} height={15}></Icon12Clock>,
                                                    workout: <Icon12OnlineVkmobile className="groupIcon-abs" fill="#7C46FF" width={17} height={17}></Icon12OnlineVkmobile>,
                                                    calorie: <Icon12Fire className="groupIcon-abs" fill="#7C46FF" width={16} height={16}></Icon12Fire>
                                                }[item.measure_scale]}
                                                <Div style={{ paddingBottom: 6, paddingTop: 6 }}>
                                                    <span style={{ color: '#99A2AD', fontSize: 14 }}>
                                                        <span className="bigOne" >{{
                                                            workout: item.value,
                                                            second: Math.floor(item.value / (60 * 60)),
                                                            calorie: item.value
                                                        }[item.measure_scale]}
                                                        </span> {{
                                                            workout: declensionPipeOnlyText(item.value, ['тренировка', 'тренировки', 'тренировок']),
                                                            second: Math.floor(item.value / (60 * 60)) <= 0 ? `часов ` + Math.floor(item.value / 60) + ` ` + declensionPipeOnlyText(Math.floor(item.value / 60), ['минута', 'минуты', 'минут']) : declensionPipeOnlyText(Math.floor(item.value / (60 * 60)), ['час', 'часа', 'часов']),
                                                            calorie: declensionPipeOnlyText(item.value, ['калория', 'калории', 'калорий'])
                                                        }[item.measure_scale]}
                                                    </span>
                                                </Div>
                                            </>
                                        ) : (
                                            <>
                                                <Div style={{ textAlign: 'center' }}>
                                                    {{
                                                        workout: <embed style={{ margin: ' 0 auto 10px' }} type="image/svg+xml" src={timeSvg} fill="#99A2AD" />,
                                                        second: <Icon24ClockOutline style={{ margin: ' 0 auto 10px' }} fill="#99A2AD" width={28}  ></Icon24ClockOutline>,
                                                        calorie: <Icon28FireOutline style={{ margin: ' 0 auto 10px' }} fill="#99A2AD" width={24}  ></Icon28FireOutline>
                                                    }[item.measure_scale]}
                                                    <Text weight="regular" className="greyText">Вы пока не тренировались</Text>
                                                </Div>
                                            </>
                                        )}
                                    </Group>
                                )
                            })
                        }

                    </>
                ) : (
                    <>
                        <Group separator="hide" className="customGroup" header={<Header className="statisticsHeader"><Skeleton width={180} />  </Header>} >
                            <Div style={{ paddingBottom: 6, paddingTop: 6 }}>
                                <Skeleton width={90} />
                            </Div>
                        </Group>
                        <Group separator="hide" className="customGroup" header={<Header className="statisticsHeader"><Skeleton width={180} />  </Header>} >
                            <Div style={{ paddingBottom: 6, paddingTop: 6 }}>
                                <Skeleton width={90} />
                            </Div>
                        </Group>
                        <Group separator="hide" className="customGroup" header={<Header className="statisticsHeader"><Skeleton width={180} />  </Header>} >
                            <Div style={{ paddingBottom: 6, paddingTop: 6 }}>
                                <Skeleton width={90} />
                            </Div>
                        </Group>
                    </>
                )}


            </Panel>
            <SettingsPanel
                {...getPanelProps(PANEL_PROFILE_SETTINGS)}
                openPopup={openPopout}
                key={userAccount}
                setPremium={setPremium}
                userAccount={userAccount ? userAccount.user : ''}
                slideIndex={slideIndexAge} />
            <ProgressPanel
                {...getPanelProps(PANEL_PROFILE_PROGRESS)}
                scoreTotal={userData ? userData.score_total : null}
                progressData={userData ? userData.rank_progress : null} />
        </View>
        // </Root>
    )

}

export default withRouter(Profile);
