import React, { useEffect } from 'react';
import './Timer.css';


const Timer = ({ finishDate, theme }) => {
    useEffect(() => {
        initTimer()
    }, [])


    const DateDiff = {
        days: function (d1, d2) {
            var t2 = d2.getTime();
            var t1 = d1.getTime();

            return parseInt((t2 - t1) / (24 * 3600 * 1000));
        },
        hours: function (d1, d2) {
            var t2 = d2.getTime();
            var t1 = d1.getTime();
            return parseInt((t2 - t1) / (3600 * 1000));
        },
        minutes: function (d1, d2) {
            var t2 = d2.getTime();
            var t1 = d1.getTime();
            return parseInt((t2 - t1) / (600 * 1000));
        },
        seconds: function (d1, d2) {
            var t2 = d2.getTime();
            var t1 = d1.getTime();
            return parseInt((t2 - t1) / (60 * 1000));
        },
    }

    const initTimer = () => {
        const currentDate = new Date;
        const endDate = new Date(finishDate)  
        console.log(DateDiff.days(currentDate, endDate),
            DateDiff.hours(currentDate, endDate),
            DateDiff.minutes(currentDate, endDate),
            DateDiff.seconds(currentDate, endDate))
    }
    return (
        <div className={"timer--wrapper theme__" + theme}>
            <div className="timer--item">
                <div className="timer--clock--wrap">
                    <span>0</span>
                    <span>2</span>
                </div>
                <div className="timer--text">Дней</div>
            </div>
            <div className="timer--separator">:</div>
            <div className="timer--item">
                <div className="timer--clock--wrap">
                    <span>0</span>
                    <span>6</span>
                </div>
                <div className="timer--text">Часов</div>
            </div>
            <div className="timer--separator">:</div>
            <div className="timer--item">
                <div className="timer--clock--wrap">
                    <span>3</span>
                    <span>7</span>
                </div>
                <div className="timer--text">Минут</div>
            </div>
        </div>
    )
}

export default Timer;