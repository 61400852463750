import axiosApi from './axiosApi';
import { apiBaseUri } from '../environment';  
 

export default class ProfileService  { 
	

	getUserProfile() { 
		return  axiosApi.get(`${apiBaseUri}/user/profile`)
	}
	getUserData(){
		return  axiosApi.get(`${apiBaseUri}/user/account`)
	}
	updateUserData(data) {
		return axiosApi.put(`${apiBaseUri}/user/`, data)
	}

	updateUserWorkoutParams(data) {
		return axiosApi.put(`${apiBaseUri}/user/updateWorkoutParams`, data)
	}

	finishOnboarding(){
		return axiosApi.put(`${apiBaseUri}/user/doneOnboarding`)
	}
 
}
