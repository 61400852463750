import React, { useEffect, useState } from 'react';
import {
    ModalPage,
    ModalPageHeader,
    Avatar,
    withModalRootContext,
    PanelHeaderBack,
    SimpleCell,
    Div,
} from '@vkontakte/vkui';
import { Icon24Dismiss } from '@vkontakte/icons';
import Skeleton from 'react-loading-skeleton';
import declensionPipe from '../../../components/pipes/declensionPipe'
const AllFriendsModal = ({ title, content, id, closeEvent, settlingHeight, friendsData }) => {
    const [allFriendsSelect, setAllFriendsSelect] = useState(false)
    return (
        <ModalPage
            className="modal--border_0"
            id={id}
            onClose={closeEvent}
            settlingHeight={settlingHeight}
            header={
                <ModalPageHeader
                    left={<PanelHeaderBack onClick={closeEvent}></PanelHeaderBack>}
                >
                    Все друзья
                </ModalPageHeader>
            }
        >
            <Div>
                {
                    (friendsData && friendsData.length) ?
                        friendsData.map((friend, index) => {
                            return (
                                <SimpleCell
                                    disabled
                                    style={{ paddingLeft: 0, paddingRight: 0 }}
                                    key={index}
                                    before={<Avatar size={48} src={friend.photo_50} />}
                                    after={<span className="bubble div-centered hello-bubble">&#128400;</span>}
                                >{friend.first_name} {friend.last_name}</SimpleCell>
                            )
                        })
                        : ''
                }
            </Div>

        </ModalPage>
    );
}


export default withModalRootContext(AllFriendsModal);