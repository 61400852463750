import React from 'react';
import { Panel, PanelHeader, Button, Div, FixedLayout, Caption, Title } from '@vkontakte/vkui'; 
import { Icon24MoreHorizontal } from '@vkontakte/icons';
import './TrainingSharingPanel.css';
import bridge from '@vkontakte/vk-bridge';
import domtoimage from 'dom-to-image';
import { apiBaseUri, app_id } from '../../../environment';
import { PAGE_TRAINING } from '../../../routing/routes';
import axiosApi from "../../../services/axiosApi";
import { saveAs } from 'file-saver';
import defaultTimePipe from '../../../components/pipes/defaultTimePipe';

const TrainingSharingPanel = ({ id, setPanel, training, goBack, nextPage, amplitudeEvent }) => {
    const shareToWall = () => {
        amplitudeEvent('workout_finished_share', {
            "share_id": "wall"
        });
        (async function () {
            try {
                const vkAccessToken = await bridge.send("VKWebAppGetAuthToken", {
                    "app_id": app_id,
                    "scope": "photos"
                });
                const wallServer = await bridge.send('VKWebAppCallAPIMethod', {
                    method: 'photos.getWallUploadServer',
                    params: {
                        access_token: vkAccessToken.access_token,
                        v: "5.131"
                    }
                });
                const photo = await axiosApi.post(`${apiBaseUri}/user/uploadPhoto`, {
                    "photo_url": training.preview,
                    "upload_url": wallServer.response.upload_url,
                });
                const wall = await bridge.send('VKWebAppCallAPIMethod', {
                    method: 'photos.saveWallPhoto',
                    params: {
                        user_id: wallServer.response.user_id,
                        server: photo.data.server,
                        photo: photo.data.photo,
                        hash: photo.data.hash,
                        access_token: vkAccessToken.access_token,
                        v: "5.131"
                    }
                })
                if (wall.response.length > 0) {
                    let photoId = wall.response[0].id
                    let ownerId = wall.response[0].owner_id
                    await bridge.send("VKWebAppShowWallPostBox", {
                        "message": "Тренировка выполнена! https://vk.com/app" + app_id,
                        "attachments": `photo${ownerId}_${photoId}`
                    });
                }
            } catch (e) {
                console.log(e);
            }
        })()
    }
 


    const shareToHistory = () => {
        amplitudeEvent('workout_finished_share', {
            "share_id": "history"
        });
        var stickerNode = document.getElementById('bigPreview')
        domtoimage.toBlob(stickerNode).then(function (blob) {
            var image = new Image();
            image.setAttribute('crossOrigin', 'anonymous');
            image.onload = function () {
                // const canvas = document.createElement('canvas');
                // canvas.width = image.width;
                // canvas.height = image.height;
                // const ctx = canvas.getContext('2d');
                // roundedImage(ctx, 0, 0, image.width, image.height, 60);
                // ctx.clip();
                // ctx.drawImage(image, 0, 0, image.width, image.height);
                // ctx.restore();

                // var base64dataT = canvas.toDataURL('image/png');
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    console.log(base64data);
                    bridge.send('VKWebAppShowStoryBox', {
                        background_type: 'image',
                        blob: base64data,
                        attachment: {
                            type: 'url',
                            url: 'https://vk.com/app' + app_id,
                            text: 'Я выполнил тренировку в приложении Тренировки! ',
                        },
                        locked: true,
                    });
                };
            };
            // image.src = training.preview;
        });
    };


    const shareNavigator = () => {
        amplitudeEvent('workout_finished_share', {
            "share_id": "navigator"
        })
        var stickerNode = document.getElementById('bigPreview')
        domtoimage.toBlob(stickerNode).then(function (blobStart) {
            var reader = new FileReader();
            reader.readAsDataURL(blobStart);
            reader.onloadend = function () {
                const base64data = reader.result;
                const share = async (base64data) => {
                    const blob = await fetch(base64data).then(r => r.blob())
                    const file = new File([blob], "image.png", { type: blob.type });
                    const filesArray = [file];
                    const data = {
                        title: 'VK Тренировки',
                        files: filesArray,
                        text: 'Я выполнил тренировку в приложении Тренировки! ',
                        url: 'https://vk.com/app' + app_id,
                    };
                    setTimeout(() => {
                        saveAs(blob, "vk-trainings.png");
                    }, 500)
                    if (navigator.share && navigator.canShare({ files: filesArray })) {
                        await navigator.share(data);
                    } else {
                        domtoimage.toBlob(document.getElementById('bigPreview'))
                            .then(function (blob) {
                                setTimeout(() => {
                                    saveAs(blob, 'vk-trainings.png');
                                }, 500)
                            });
                    }
                };
                share(base64data) 
            };
        })
    }
    return (
        <Panel id={id} className="p-b-60" >
            <PanelHeader separator={false}   >Тренировка</PanelHeader>

            <Div   style={{ paddingTop: 0 }}>
                <div id="bigPreview" className={"trainingImageWrap sharingFrame"} >
                    <div className="trainingImage" >
                        <img src={training.preview} className="trainingImage--inner" />
                        <div className="trainingImageShadow"></div>
                    </div>
                    <div className="float flexBox counterBox">
                        {(training.task.duration > 0 ) && (
                            <span className="previewCounter">{defaultTimePipe(training.task.duration)}</span>
                        )}
                        {training.calories > 0 && (
                            <span className="previewCounter">{training.calories} Ккал</span>
                        )}
                    </div>
                    <div className="trainingWrapBottom">
                        <Title level="1" weight="semibold" style={{ color: 'white' }}>{training.title}</Title> 
                    </div>
                </div>
            </Div>
            {/* <TrainingPreview data={training} fullView={false} sharingFrame={true} /> */}
            <Div>
                <Caption level="1" weight="semibold" style={{
                    marginBottom: 16,
                    marginTop: 10,
                    textAlign: 'center',
                    color: '#818C99'
                }}>ПОДЕЛИТЬСЯ</Caption>
                <div className="flexBox" style={{ paddingLeft: "14%", paddingRight: "14%", justifyContent: 'center' }}>
                    {/* <div className="trainingRate" onClick={shareToWall}> 
                        <Icon24WriteOutline fill="#99A2AD" width={28} height={28} fill="#7C46FF"/>
                        <Caption level="2" weight="regular">Поделиться <br/> на стене</Caption>

                    </div>
                    <div className="trainingRate" onClick={shareToHistory}>
                        <Icon24StoryOutline fill="#99A2AD" width={28} height={28} fill="#7C46FF"/> 
                        <Caption level="2" weight="regular">Добавить <br /> в историю</Caption>
                    </div> */}
                    <div className="trainingRate" onClick={shareNavigator}>
                        <Icon24MoreHorizontal fill="#99A2AD" width={28} height={28} fill="#7C46FF" />
                        <Caption level="2" weight="regular">Сохранить <br /> себе</Caption>
                    </div>
                </div>
            </Div>
            <FixedLayout vertical="bottom" style={{ background: 'white' }}>
                <Div>
                    <Button stretched size="l" onClick={() => nextPage(PAGE_TRAINING)} data-to="trainingDayPanel">Завершить тренировку </Button>
                </Div>
            </FixedLayout>
            {/* <canvas id="canvas"> </canvas> */}
        </Panel>
    )
}
export default TrainingSharingPanel;
