import axiosApi from './axiosApi';
import { apiBaseUri } from '../environment';


export default class TrainingService {
    getMainTraining(id) {
        return axiosApi.get(`${apiBaseUri}/workout/show/${id}`)
    }
    getMain(gender) {
        return axiosApi.get(`${apiBaseUri}?gender=${gender}`)
    }

    completeTraining(data) {
        return axiosApi.post(`${apiBaseUri}/workout/complete`, data)
    }
    updateTraining(data) {
        return axiosApi.put(`${apiBaseUri}/workout/updateComplete`, data)
    }
    
    startTraining(id){
        return axiosApi.post(`${apiBaseUri}/workout/start/${id}`)
    }


}
