import React, { useEffect, useState } from 'react';
import {
    ModalPage,
    ModalPageHeader,
    Avatar,
    withModalRootContext,
    PanelHeaderBack,
    SimpleCell,
    Div,
    Subhead,
} from '@vkontakte/vkui';
import { Icon16Lock, Icon24Dismiss } from '@vkontakte/icons';
import Skeleton from 'react-loading-skeleton';
import declensionPipe from '../../../components/pipes/declensionPipe'
const ChallengeTasksModal = ({ title, content, id, closeEvent, settlingHeight, challengeTasks }) => {
    const [allFriendsSelect, setAllFriendsSelect] = useState(false)
    return (
        <ModalPage
            className="modal--border_0"
            id={id}
            onClose={closeEvent}
            settlingHeight={settlingHeight}
            header={
                <ModalPageHeader
                    left={<PanelHeaderBack onClick={closeEvent}></PanelHeaderBack>}
                >
                    Список заданий
                </ModalPageHeader>
            }
        >
            <Div>
                {
                    (challengeTasks && challengeTasks.length) ?
                        challengeTasks.map((task, index) => {
                            return (
                                <SimpleCell
                                    key={index}
                                    style={{ marginBottom: 7, marginLeft: '-17px', width: '100%' }}
                                    before={<Avatar size={50} style={{ borderRadius: 12 }} src={task.image} className="smallRadius" />}
                                    after={task.closed ? <Icon16Lock fill="#B8C1CC" width={24} height={20} /> : ''}>
                                    <div><Subhead weight="regular" className="greyText">{task.descr}</Subhead>
                                        {task.title}</div>
                                </SimpleCell>
                            )
                        })
                        : ''
                }
            </Div>

        </ModalPage>
    );
}


export default withModalRootContext(ChallengeTasksModal);